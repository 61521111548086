import { uid } from "uid";
import { ReactiveObject } from "./ReactiveObject";


export interface IChat {
	id: string;
	title: string;
	avatar: string;
	description: string;
	createdOn: Date;
	updatedOn: Date;
	users: IUser[];
	children: IChat[]; // Add this line
}


export type UserType = 'user' | 'bot' | 'admin';

export interface IUser {
	id: string;
	name: string;
	avatar: string;
	userType: UserType;
}


export interface IChats {
	chats: IChat[];
	user: IUser
}

export class Chats extends ReactiveObject<IChats>{

	constructor() {
		super({
			chats: [],
			user: {
				id: uid(),
				name: '',
				avatar: '',
				userType: 'user'
			}
		})

		try {
			const chats = localStorage?.getItem('chats');
			if (chats) {
				this.update((state) => {
					// debugger
					const _state = JSON.parse(chats);
					if (_state.chats) {
						state.chats = _state.chats;
					}
					return state;
				})
			}
		} catch (err) {
			console.error(err);
		}

		this.subscribe((state) => {
			try {
				localStorage?.setItem('chats', JSON.stringify(state));
			} catch (err) {
				console.error(err);
			}
		})
	}

	public addChat(chat: IChat) {

		this.update((state) => {
			state.chats.push(chat);
			return state;
		})
	}

	public newChat(title: string) {
		const chat: IChat = {
			id: uid(),
			title,
			avatar: '',
			description: '',
			createdOn: new Date(),
			updatedOn: new Date(),
			users: [],
			children: []
		}

		this.addChat(chat);
		return chat;
	}

	public addUserToChat(chatId: string, user: IUser) {

		this.update((state) => {
			const chat = state.chats.find(chat => chat.id === chatId);
			if (chat) {
				chat.users.push(user);
			}
			return state;
		})
	}

	public removeUserFromChat(chatId: string, userId: string) {

		this.update((state) => {
			const chat = state.chats.find(chat => chat.id === chatId);
			if (chat) {
				chat.users = chat.users.filter(user => user.id !== userId);
			}
			return state;
		})
	}

	removeChat(chatId: string) {
		this.update((state) => {
			state.chats = state.chats.filter(chat => chat.id !== chatId);
			return state;
		})
	}

	// Add a chat to another chat as a child
	public addChildChat(parentChatId: string, childChat: IChat) {
		this.update((state) => {
			const parentChat = this.findChatById(state.chats, parentChatId);
			if (parentChat) {
				parentChat.children.push(childChat);
			}
			return state;
		})
	}

	// Remove a child chat from a parent chat
	public removeChildChat(parentChatId: string, childChatId: string) {
		this.update((state) => {
			const parentChat = this.findChatById(state.chats, parentChatId);
			if (parentChat) {
				parentChat.children = this.removeChatFromList(parentChat.children, childChatId);
			}
			return state;
		})
	}

	// Find a child chat by its ID
	public findChildChat(parentChatId: string, childChatId: string) {
		let foundChat: IChat | undefined;

		const state = this.get()


		const parentChat = this.findChatById(state.chats, parentChatId);
		if (parentChat) {
			foundChat = this.findChatById(parentChat.children, childChatId);
		}


		return foundChat;
	}

	// Recursive function to find a chat by its ID
	private findChatById(chats: IChat[], chatId: string): IChat | undefined {
		for (const chat of chats) {
			if (chat.id === chatId) {
				return chat;
			}
			const foundChat = this.findChatById(chat.children, chatId);
			if (foundChat) {
				return foundChat;
			}
		}
		return undefined;
	}

	// Recursive function to remove a chat by its ID
	private removeChatFromList(chats: IChat[], chatId: string): IChat[] {
		return chats.filter(chat => {
			if (chat.id === chatId) {
				return false;
			}
			chat.children = this.removeChatFromList(chat.children, chatId);
			return true;
		})
	}

	public getBreadcrumb(chatId: string): IChat[] {
		let breadcrumb: IChat[] = [];

		const state = this.get()
		const path = this.findChatPath(state.chats, chatId);
		if (path) {
			breadcrumb = path;
		}
		return breadcrumb;
	}

	// Recursive function to find a path to a chat by its ID
	private findChatPath(chats: IChat[], chatId: string): IChat[] | null {
		for (const chat of chats) {
			if (chat.id === chatId) {
				return [chat];
			}
			const foundPath = this.findChatPath(chat.children, chatId);
			if (foundPath) {
				return [chat, ...foundPath];
			}
		}
		return null;
	}
}