<script lang="ts">
  import LazyLoad from "@components/LazyLoad/LazyLoad.svelte";
  import RoomViewPlaceholder from "@components/RoomView/Placeholder/RoomViewPlaceholder.svelte";
  import { onMount, type SvelteComponentTyped } from "svelte";
  import { Route, Router } from "svelte-navigator";
  import NotFound from "./NotFound.svelte";
  import RouteGuard from "./RouteGuard.svelte";
  import { authStore } from "@common/uiStores";
  import Redirect from "./Redirect.svelte";
  import { exportToCookie, isAdmin, isLoggedIn, pb } from "@common/pocketbase";
  import AssistiveTouch from "@components/MobileView/AssistiveTouch.svelte";

  interface IRoute {
    path: string;
    component: () => Promise<any>;
    guard?: (url: URL) => Promise<boolean> | boolean;
    fallbackRedirect?: string;
    routeProps?: {
      [key: string]: any;
    };
    componentProps?: {
      [key: string]: any;
    };
    condition?: (url: URL) => Promise<boolean> | boolean;
    containerComponent?: () => Promise<any>;
    containerComponentProps?: {
      [key: string]: any;
    };
    blockedViewComponent?: typeof SvelteComponentTyped<any, any, any>;
    loaderComponent?: typeof SvelteComponentTyped<any, any, any>;
  }

  const checkValidRoomId = (url: URL) => {
    const roomId = url.pathname.replace("/", "");
    const regex = /^[a-z0-9A-Z]{3}-[a-z0-9A-Z]{3}-[a-z0-9A-Z]{3}$/;
    const result = regex.test(roomId);
    return result;
  };

  onMount(async () => {
    try {
      if (pb.authStore.isValid) {
        await exportToCookie(pb);
      }
    } catch (error) {
      console.error(error);
    }
  });

  const routes: IRoute[] = [
    {
      path: "/",
      component: () => import("./RandomRoom.svelte"),
      loaderComponent: RoomViewPlaceholder,
    },

    {
      path: "/welcome",
      component: () => import("./Welcome.svelte"),
      loaderComponent: RoomViewPlaceholder,
    },
    {
      path: "/mobile",
      component: () => import("./Mobile.svelte"),
      guard: async () => {
        return false;
      },
      fallbackRedirect: isLoggedIn(pb) ? "/mobile/feeds" : "/welcome",
    },

    {
      path: "/mobile/feeds",
      component: () => import("./MobileFeeds.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/tasks",
      component: () => import("./MobileTasks.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/chats",
      component: () => import("./MobileChats.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },

    {
      path: "/mobile/glance",
      component: () => import("./MobileGlance.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/contacts",
      component: () => import("./MobileContacts.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/contacts/invite",
      component: () => import("./RequestInvite.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/contacts/create",
      component: () => import("./MobileCreateContact.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/contacts/:contactId",
      component: () => import("./MobileContactsEdit.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/select-users",
      component: () =>
        import("./../components/MobileView/MobileSelectAdminUser.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/outlines/new",
      component: () => import("./MobileOutlinesNew.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/outlines",
      component: () => import("./MobileOutlines.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/import-vcf",
      component: () => import("./MobileImportVfc.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },

    {
      path: "/mobile/chat/:chatId",
      component: () => import("./MobileChat.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/tags",
      component: () => import("./MobileTags.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/settings",
      component: () => import("./MobileSettings.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/outlines-glance",
      component: () => import("./MobileOutlinesGlance.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/events/*",
      component: () => import("./MobileEvents.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/mobile/outline/:id",
      component: () => import("./MobileOutline.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/pusher",
      component: () => import("./Pusher.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/search",
      component: () => import("./Search.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/meet/:id",
      component: () => import("./Meet.svelte"),
      guard: async () => {
        return isLoggedIn(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/user/login",
      component: () => import("./Login.svelte"),
    },
    {
      path: "/help",
      component: () => import("./ContactForm.svelte"),
    },
    {
      path: "/help/success",
      component: () => import("./ContactFormSuccess.svelte"),
    },
    {
      path: "/admin/login",
      component: () => import("./AdminLogin.svelte"),
    },
    {
      path: "/admin/*",
      component: () => import("./Dashboard.svelte"),
      guard: async () => {
        return isAdmin(pb);
      },
      fallbackRedirect: "/welcome",
    },
    {
      path: "/scan",
      component: () => import("./Scan.svelte"),
    },
    {
      path: "/qrcode",
      component: () => import("./AdminAddDevice.svelte"),
    },
    {
      path: "/add-device",
      component: () => import("./AddDevice.svelte"),
    },
    {
      path: "*",
      component: () => import("./NotFound.svelte"),
    },
  ];
</script>

<Router>
  {#each routes as route}
    <Route
      let:params
      let:location
      let:navigate
      path={route.path}
      {...route.routeProps ?? {}}
    >
      <RouteGuard
        condition={route.guard}
        BlockedViewComponent={route.blockedViewComponent}
      >
        <LazyLoad
          let:component
          loader={route.component}
          loaderComponent={route.loaderComponent}
        >
          <AssistiveTouch>
            <svelte:component
              this={component}
              {params}
              {location}
              {navigate}
              {...route.componentProps ?? {}}
            />
          </AssistiveTouch>
        </LazyLoad>

        <svelte:fragment slot="blocked">
          {#if route.fallbackRedirect}
            <Redirect to={route.fallbackRedirect} />
          {:else}
            <NotFound />
          {/if}
        </svelte:fragment>
      </RouteGuard>
    </Route>
  {/each}
</Router>
