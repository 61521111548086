import { isMobile } from "mobile-device-detect";
import { currentUser } from "./basicStores";
import type { ICalendarEvent, IEvent, IEventChange } from "./calendar";
import { getEventChanges, getLocalCalendarEvents } from "./calendarUtils";
import { getCalendarEventsStore } from "./getCalendarStore";
import { ReactiveObject } from "./ReactiveObject";
import type { IMessage, RoomStore } from "./roomStore";
import type { ISharedMethods } from "./sharedLogic";



export interface IRemoteControllerServer{
	roomStore: RoomStore;
	grantedPermissions: PermissionTypes[];
}

export interface IRemoteControllerClient{
	roomStore: RoomStore
}

export enum PermissionTypes{
	"PLAYLIST_TEMP_ACCESS" = "PLAYLIST_TEMP_ACCESS",
	"ACCOUNT_SYNC" = "ACCOUNT_SYNC",
	"PLAYLIST_ACCESS" = "PLAYLIST_ACCESS",
}

export enum IPermissionStatus{
	"GRANTED" = "GRANTED",
	"DENIED" = "DENIED",
	"PENDING" = "PENDING",
}

export interface IPermissionRequest{
	type: PermissionTypes
	data: any;
	status: IPermissionStatus;
}


export enum DataRequestType{
	"PLAYLIST_REQUEST" = "PLAYLIST_REQUEST",
}

export interface IDataRequest{
	requiredPermission: PermissionTypes;
	type: DataRequestType;
}

export interface IAccountData {
	userId: string;
	device: {
		type: string;
		userAgent: string;
	};
}


export interface IRemoteServerMethods extends ISharedMethods{
	getCalendarEvents: () => Promise<ICalendarEvent[] | undefined>;
	getEvent: (opts: {eventId: string}) => Promise<ICalendarEvent | undefined>;
	getSubEvent: (opts: {eventId: string, subEventId: string}) => Promise<IEvent | undefined>;
	getChanges: (opts?: {eventId: string, subEventId: string}) => Promise<IEventChange[] | undefined>;
	getEventIdList: () => Promise<string[]>;
	getChangesIdList: () => Promise<string[]>;
	getChangesById: (opts: {idList: string[]}) => Promise<IEventChange[]>;
	getEventList: (opts: {idList: string[]} ) => Promise<ICalendarEvent[]>;
	getAccountData: () => Promise<IAccountData>;
	setAccountData: (opts: {userId: string}) => Promise<boolean>;
	setChange: (opts: {change: IEventChange}) => Promise<boolean>;
	setChanges: (opts: {changes: IEventChange[]}) => Promise<boolean>;
	setEvent: (opts: {event: ICalendarEvent}) => Promise<boolean>;
}



export interface IRequestCalendarDataTransferStats{
	eventCount: number;
	currentEventCount: number;
	changeCount: number;
	currentChangeCount: number;
}
export interface IRequestCalendarDataOptions{
	onProgress?: (stats: IRequestCalendarDataTransferStats) => void;
	onComplete?: () => void;
	filterList?: (listId: string) => boolean;
	getCustomListOptions?: IGetCustomListOptions;
}
export interface IGetCustomListOptions {
	
}

export type IRemoteClientMethods = IRemoteServerMethods


export const getAccountData = (): IAccountData => {
	return {
		userId: currentUser.get()?.id || "",
		device: {
			type: isMobile ? "mobile" : "desktop",
			userAgent: navigator.userAgent
		}
	}
}



export class RemoteController extends ReactiveObject<IRemoteControllerServer>{
	public rpc: RoomStore<IRemoteServerMethods,IRemoteClientMethods>["rpc"]
	constructor(public roomStore: RoomStore<IRemoteServerMethods,IRemoteClientMethods>){
		super({
			roomStore,
			grantedPermissions: [],
		})
		this.rpc = roomStore.rpc;

		roomStore.rpc.setRpcHandlerMethods({
			getCalendarEvents: async () => {
				const events = await getLocalCalendarEvents();
				return events ? events : undefined;
			},
			getEvent: async (opts: {eventId: string}) => {
				const events = await getLocalCalendarEvents();
				if(!events) return;
				const event = events?.find(e => e.id === opts.eventId);
				return event;
			},
			getSubEvent: async (opts: {eventId: string, subEventId: string}) => {
				const {eventId, subEventId} = opts;
				const events = await getLocalCalendarEvents();
				if(!events) return;
				const event = events.find(e => e.id === subEventId);
				if(!event) return;
				const subEvent = event.events.find(e => e.id === subEventId);
				return subEvent;
			},

			getChanges: async (opts?: {eventId: string, subEventId: string}) => {
				const changes = await getEventChanges();
				if(changes){
					if(!opts){
						return changes;
					}else{
						const {eventId, subEventId} = opts;
						const filteredChanges = changes.filter(c => c.eventId === eventId && c.subEventId === subEventId);
						return filteredChanges;
					}
				}
			},
			getEventIdList: async () => {
				const events = await getLocalCalendarEvents();
				if(!events) return [];
				return events.map(e => e.id);
			},
			getChangesIdList: async () => {
				const changes = await getEventChanges();
				if(!changes) return [];
				return changes.map(c => c.id);
			},
			getChangesById: async (opts: {idList: string[]}) => {
				const changes = await getEventChanges();
				if(!changes) return [];
				const filteredChanges = changes.filter(c => opts.idList.includes(c.id));
				return filteredChanges;
			},
			getEventList: async (opts: {idList: string[]}) => {
				const events = await getLocalCalendarEvents();
				if(!events) return [];
				const filteredEvents = events.filter(e => opts.idList.includes(e.id));
				return filteredEvents;
			},
			getAccountData: async () => {
				const accountData = await getAccountData();
				return accountData;
			},
			setAccountData: async (opts: {userId: string}) => {
				const {userId} = opts;
				if(userId){
					return await new Promise<boolean>((res) => {
						const sure = window.confirm(`Continue with remote account ${userId}?`);
						if(sure){
							currentUser.set({id: userId});
							res(true)
						}else{
							res(false)
						}
					})
				}
				return false
			},
			setChange: async (opts: {change: IEventChange}) => {
				const {change} = opts;
				if(change){
					const calendarEventsStore = await getCalendarEventsStore()
					calendarEventsStore.update(s => {
						const changeIndex = s.changes.findIndex(c => c.id === change.id);
						if(changeIndex !== -1){
							s.changes[changeIndex] = change;
						}else{
							s.changes.push(change);
						}
						return s;
					})
					await calendarEventsStore.save()
					return true;
				}
				return false;
			},
			setChanges: async (opts: {changes: IEventChange[]}) => {
				const {changes} = opts;
				if(changes){
					const calendarEventsStore = await getCalendarEventsStore()
					calendarEventsStore.update(s => {
						s.changes = changes;
						return s;
					})
					await calendarEventsStore.save()
					return true;
				}
				return false;
			},
			setEvent: async (opts: {event: ICalendarEvent}) => {
				const {event} = opts;
				debugger
				if(event){
					const calendarEventsStore = await getCalendarEventsStore()
					calendarEventsStore.update(s => {
						const eventIndex = s.events.findIndex(e => e.id === event.id);
						if(eventIndex !== -1){
							s.events[eventIndex] = event;
						}else{
							s.events.push(event);
						}
						return s;
					})
					await calendarEventsStore.save()
					return true;
				}
				return false;
			}
		})

		const unsub = roomStore.messagesStore.subscribe(state => {
			this.handleMessages(state.messages)
		})


		roomStore.disposables.push({
			dispose: unsub,
		})
	}


	handleMessages(messages: IMessage[]){
		const message = messages[messages.length - 1];
	}

	grantPermission(permission: PermissionTypes){
		this.update(state => {
			state.grantedPermissions.push(permission);
			return state
		});
	}


	requestPermission(permission: PermissionTypes, confirmationRequired: boolean = true){
		const confirmation = confirmationRequired 
			? window.confirm(`Do you want to grant ${permission} permission?`)
			: true;
		
		if(confirmation){
			this.grantPermission(permission);
		}
	}

	async requestCalendarData(opts?: IRequestCalendarDataOptions){
		const eventIds = await this.rpc.call("getEventIdList", undefined);
		const changesIds = await this.rpc.call("getChangesIdList", undefined);

		const chunkedEventIds = createChunkedArr(eventIds || [], 3);
		const chunkedChangesIds = createChunkedArr(changesIds || [], 10);

		const events: ICalendarEvent[] = [];
		const changes: IEventChange[] = [];

		for(let i = 0; i < chunkedEventIds.length; i++){
			const chunk = chunkedEventIds[i];
			const chunkEvents = await this.rpc.call("getEventList", {idList: chunk});
			events.push(...chunkEvents);
			if(opts?.onProgress){
				opts.onProgress({
					changeCount: changesIds.length,
					currentChangeCount: 0,
					eventCount: eventIds.length,
					currentEventCount: i
				})
			}
		}

		for(let i = 0; i < chunkedChangesIds.length; i++){
			const chunk = chunkedChangesIds[i];
			const chunkChanges = await this.rpc.call("getChangesById", {idList: chunk});
			changes.push(...chunkChanges);
			if(opts?.onProgress){
				opts.onProgress({
					changeCount: changesIds.length,
					currentChangeCount: i,
					eventCount: eventIds.length,
					currentEventCount: 0
				})
			}
		}

		return {
			events,
			changes,
		}

	}


}


export const createChunkedArr = <T>(arr: T[], chunkSize: number): T[][] => {

	const chunkedArr: T[][] = [];
	let i,j,tempArr;

	for(i=0,j=arr.length; i<j; i+=chunkSize){
		tempArr = arr.slice(i,i+chunkSize);
		chunkedArr.push(tempArr);
	}

	return chunkedArr;

}