<svg
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
  role="img"
  class="w-full h-full"
  preserveAspectRatio="xMidYMid meet"
  viewBox="0 0 24 24"
  ><g fill="none" stroke="currentColor" stroke-width="2"
    ><circle cx="12" cy="7" r="5" /><path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M17 22H5.266a2 2 0 0 1-1.985-2.248l.39-3.124A3 3 0 0 1 6.649 14H7m9 2l2.25 2L22 14"
    /></g
  ></svg
>
