import { firebaseApp, NOTIFICATIONS_API, vapidKey } from './firebaseApp';
import { getToken, getMessaging, onMessage, isSupported } from 'firebase/messaging';
import { swRegistration } from './basicStores';


const _getMessaging = () => {
	try {
		const messaging = getMessaging(firebaseApp)
		return messaging
	} catch (err) {
		console.error(err)
		return null
	}
}

export const messaging = _getMessaging();

export const getFirebaseMessagingToken = async (
	{ vapidKey, serviceWorkerRegistration }: { vapidKey: string; serviceWorkerRegistration: ServiceWorkerRegistration }
): Promise<string> => {
	
	if (messaging) {
		const token = await getToken(messaging, {
			vapidKey,
			serviceWorkerRegistration,	
		})
		return token
	}
	return ""
}


export const setFirebaseMessagingToken = async ({
	token,
	wid
}: {
	token: string,
	wid: string
}) => {
	const res = await fetch(
		`${NOTIFICATIONS_API}/register?${new URLSearchParams({
			user_id: wid,
			token_id: token,
		})}`
	).then(res => res.json())

	return res
}


export const initFirebaseNotificationService = async (serviceWorkerRegistration: ServiceWorkerRegistration) => {
	const token = await getFirebaseMessagingToken({
		vapidKey,
		serviceWorkerRegistration
	})

	return {
		token
	}
}

export const initializeFirebaseForegroundListener = (callback: () => void) => {
	messaging && onMessage(messaging, callback)
}

export const listenFirebaseMessage = (callback: Parameters<typeof onMessage>["1"]) => {
	return messaging && onMessage(messaging, callback)
}


export const isFirebaseMessagingSupported = () => {
	return !!messaging
}