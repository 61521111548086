<script>
	
  
	let linkHints = null;

	const isInputFocused = () => {
	  const activeElement = document.activeElement;
	  if (activeElement instanceof HTMLInputElement) return true;
	  if (activeElement instanceof HTMLTextAreaElement) return true;
	  
	  // is contenteditable
	  if (activeElement.isContentEditable) return true;
	  return false;
	}
  
	const loadAndActivateLinkHints = async () => {
	  const { LinkHints, activateLinkHints } = await import("linkhintsjs");
	  const _linkHints = new LinkHints([
		{
		  key: "f",
		  callback: (e) => {},
		  modeIndex: 3,
		},
	  ]);
	  linkHints = _linkHints;
	  activateLinkHints();
	};
  
	const handleKeydown = (event) => {
	  if (isInputFocused()) return;
	  if(linkHints !== null) return;
  
	  if (event.key === "f") {
		loadAndActivateLinkHints();
	  }
	};
  </script>
  
  <svelte:window on:keydown={handleKeydown} />