<script lang="ts">
  import type { ICustomButton } from "@common/button";
  import { doubletap } from "@common/longpress";
  import { createInviteRequest, onboardNewUser, pb } from "@common/pocketbase";
  import {
    dictateModeActive,
    glanceSwiperInstance,
    glanceViewInputVisible,
    sectionsSwiperInstance,
    taskViewActive,
    type MobileSections,
    activeContacts,
    recentInviteId,
    currentChatMethods,
  } from "@common/uiStores";
  import TippySvelte from "@components/TippySvelte/TippySvelte.svelte";
  import Chat from "@icons/Chat.svelte";
  import Contact from "@icons/Contact.svelte";
  import Fire from "@icons/Fire.svelte";
  import List from "@icons/List.svelte";
  import Mic from "@icons/Mic.svelte";
  import Reply from "@icons/Reply.svelte";
  import Send from "@icons/Send.svelte";
  import { navigate, useLocation, globalHistory } from "svelte-navigator";
  import type { Instance } from "tippy.js";
  import { swipe } from "svelte-gestures";
  import {
    openConnectionManagerModal,
    openSelectContactModal,
    openSelectEventModal,
  } from "@components/Modals/controllers";
  import { getContactsDatabase } from "@common/contacts";
  import { importVcf } from "@common/vcf";
  import { ArrowLeft, ArrowRight, Contact2, ContactIcon, QrCodeIcon } from "lucide-svelte";
  import MultiDevice from "@icons/MultiDevice.svelte";
  import Feeds from "@icons/Feeds.svelte";
  import TreeStructure from "@icons/TreeStructure.svelte";
  import TodoList from "@icons/TodoList.svelte";
  import Events from "@icons/Events.svelte";
  import { INDEXEDDB, createInstance } from "localforage";
  import { MessageTypes, type IMessage } from "@common/roomStore";
  import { uid } from "uid";
  import PersonAdd from "@icons/PersonAdd.svelte";
  import PersonCheck from "@icons/PersonCheck.svelte";
  import Plus from "@icons/Plus.svelte";

  const storage = createInstance({
    driver: INDEXEDDB,
    name: "babelbab",
  });

  interface IContextMenuButtons {
    left: ICustomButton[];
    right: ICustomButton[];
  }

  type MobileContextMenuConfig = Partial<
    Record<MobileSections, IContextMenuButtons>
  >;

  let active: boolean = false;
  let location = useLocation();

  let activeMobileSection: MobileSections;
  let requestingInvite: boolean = false;

  const getActiveMobileSection = (path: string) => {
    const pathSplit = path.split("/");
    const firstPath = pathSplit[1];
    const lastPath = pathSplit[2];
    if (firstPath === "mobile" && lastPath !== "") {
      return lastPath as MobileSections;
    } else {
      return undefined;
    }
  };

  function longpress(node: HTMLElement, threshold = 500) {
    const handle_mousedown = () => {
      let start = Date.now();

      const timeout = setTimeout(() => {
        const inputElementFocused =
          document.activeElement?.tagName === "INPUT" ||
          document.activeElement?.tagName === "TEXTAREA";
        const contentEditableFocused =
          document.activeElement?.getAttribute("contenteditable") === "true";

        if (inputElementFocused || contentEditableFocused) {
          return;
        }

        node.dispatchEvent(new CustomEvent("longpress"));
      }, threshold);

      const cancel = () => {
        clearTimeout(timeout);
        node.removeEventListener("mousemove", cancel);
        node.removeEventListener("mouseup", cancel);
        node.removeEventListener("mouseout", cancel);

        // touch
        node.removeEventListener("touchmove", cancel);
        node.removeEventListener("touchend", cancel);
        node.removeEventListener("touchcancel", cancel);
      };

      node.addEventListener("mousemove", cancel);
      node.addEventListener("mouseup", cancel);
      node.addEventListener("mouseout", cancel);

      // touch
      node.addEventListener("touchmove", cancel);
      node.addEventListener("touchend", cancel);
      node.addEventListener("touchcancel", cancel);
    };

    node.addEventListener("mousedown", handle_mousedown);

    // touch
    node.addEventListener("touchstart", handle_mousedown);

    return {
      destroy() {
        node.removeEventListener("mousedown", handle_mousedown);
        node.removeEventListener("touchstart", handle_mousedown);
      },
    };
  }

  const handleSwipe = (
    event: CustomEvent<{
      direction: "left" | "right" | "up" | "down";
      target: HTMLElement;
    }>
  ) => {
    const { direction } = event.detail;

    if (direction === "right") {
      // go back
      window.history.back();
    }
    if (direction === "left") {
      // go forward
      window.history.forward();
    }
  };

  $: mobileTypingUtils = [
    {
      id: "speechToText",
      icon: Mic,
      onClick: () => {
        $dictateModeActive = !$dictateModeActive;
        $glanceViewInputVisible = true;
      },
      text: "Dictate",
    },
    {
      id: "typing",
      icon: Chat,
      text: "Typing",
      onClick: () => {
        $glanceViewInputVisible = !$glanceViewInputVisible;
      },
    },
    {
      id: "sendEvent",
      icon: Reply,
      text: "Send Event",
      onClick: () => {
        active = false;
        openSelectEventModal({
          onSelect: (item) => {
            const url = new URL(window.location.href);
            url.pathname = `/mobile/events/book`;
            url.searchParams.set("event", JSON.stringify(item));
            $currentChatMethods.sendMessage(url.toString());
          },
        });
      },
    },

    {
      id: "createEvent",
      icon: Plus,
      text: "Create Event",
      onClick: () => {
        active = false;
        navigate(`/mobile/events/create`);
      },
    },
  ] as ICustomButton[];

  $: basicNavigationUtils = [
    {
      id: "back",
      icon: ArrowLeft,
      text: "Back",
      onClick: () => {
        window.history.back();
      },
    },
    {
      id: "feeds",
      icon: Feeds,
      text: "Feeds",
      onClick: () => {
        navigate(`/mobile/feeds`);
      },
    },
    {
      id: "outlines",
      icon: TreeStructure,
      text: "Outlines",
      onClick: () => {
        navigate(`/mobile/outlines`);
      },
    },
    {
      id: "tasks",
      icon: TodoList,
      text: "Tasks",
      onClick: () => {
        navigate(`/mobile/tasks`);
      },
    },
    {
      id: "chats",
      icon: Chat,
      text: "Chats",
      onClick: () => {
        navigate(`/mobile/chats`);
      },
    },
    {
      id: "Events",
      icon: Events,
      text: "Events",
      onClick: () => {
        navigate(`/mobile/events`);
      },
    },
    {
      id: "manageDevices",
      icon: MultiDevice,
      text: "Manage Devices",
      onClick: () => {
        active = false;
        openConnectionManagerModal();
      },
    },
  ] as ICustomButton[];

  $: basicRightButtons = [
    {
      id: "forward",
      icon: ArrowRight,
      text: "Forward",
      onClick: () => {
        window.history.forward();
      },
    },
    {
      id: "createInvite",
      icon: PersonCheck,
      text: "Create Invite",
      onClick: async () => {
        const data = await onboardNewUser(pb);
        if (data) {
          const { invite, user, chat } = data;
          const channelName = chat.id;
          const currentUserId = pb.authStore?.model?.id;
          const key = `messages-${currentUserId}-chat-room-${channelName}`;
          const code = invite.acceptCode;

          const url = `${window.location.origin}/user/login?code=${code}#${
            user?.username + "," + invite.id
          }`;


          const message: IMessage[] = [
            {
              id: uid(),
              sender: currentUserId || "",
              type: MessageTypes.text,
              timestamp: Date.now(),
              value: `${url}`,
            },
          ];

          await storage.setItem(key, message);

          navigate(`/mobile/chat/${channelName}`);
        }
      },
    },
    {
      id: "requestInvite",
      icon: PersonAdd,
      text: "Request Invite",
      onClick: async () => {
        active = false;
        openSelectContactModal({
          onSelect: (contact) => {
           const id = uid();
            const currentUserId = pb.authStore?.model?.id;
            const key = `messages-${currentUserId}-chat-room-${id}`;

            const message: IMessage[] = [
              {
                id: uid(),
                sender: currentUserId || "",
                type: MessageTypes.text,
                timestamp: Date.now(),
                value: `Hey, I would like to request an invite for this user: \n\n Name: Anik Ganguly\nEmail: +1-734-542-5955`,
              },
            ];

            storage.setItem(key, message);

            navigate(`/mobile/chat/${id}`);
          }
        })
      },
    },
  ] as ICustomButton[];

  $: contactUtils = [
    {
      id: "contacts",
      icon: Contact,
      text: "Create Contact",
      onClick: () => {
        navigate(`/mobile/contacts/create`);
      },
    },
    {
      id: "Request Invite",
      icon: Contact,
      text: !requestingInvite ? "Request Invite" : "Requesting...",
      onClick: () => {
        const contacts = $activeContacts;

        if (contacts.length > 0) {
          requestingInvite = true;
          createInviteRequest(pb, contacts)
            .then((res) => {
              requestingInvite = false;
              recentInviteId.set(res.id);
              navigate(`/mobile/select-users`);
            })
            .catch((err) => {
              requestingInvite = false;
              console.log(err);
            });
        } else {
          alert("Please select at least one contact");
        }
      },
    },
  ] as ICustomButton[];
  $: outlineUtils = [
    {
      id: "outline-new",
      icon: Contact,
      text: "New Outline",
      onClick: () => {
        navigate(`/mobile/outlines/new`);
      },
    },
    // {
    //   id: "importVcf",
    //   icon: Contact,
    //   text: "Import Vcf",
    //   onClick: () => {
    //     navigate(`/mobile/import-vcf`);
    //   },
    // },
    // {
    //   id: "Request Invite",
    //   icon: Contact,
    //   text: !requestingInvite ? "Request Invite" : "Requesting...",
    //   onClick: () => {
    //     const contacts = $activeContacts

    //     if(contacts.length > 0){
    //       requestingInvite = true
    //       createInviteRequest(pb, contacts).then(res => {
    //         requestingInvite = false
    //         recentInviteId.set(res.id)
    //         navigate(`/mobile/select-users`)
    //       }).catch(err => {
    //         requestingInvite = false
    //         console.log(err)
    //       })
    //     }else{
    //       alert("Please select at least one contact")
    //     }
    //   },
    // },
  ] as ICustomButton[];

  $: defaultContextButtonsSection = "tasks" as MobileSections;

  $: activeMobileSection = (getActiveMobileSection($location.pathname) ||
    defaultContextButtonsSection) as MobileSections;

  $: contextMenuButtons = {
    tasks: {
      left: [...basicNavigationUtils],
      right: [...basicRightButtons, ...mobileTypingUtils],
    },
    chats: {
      left: [...basicNavigationUtils],
      right: [...basicRightButtons, ...mobileTypingUtils],
    },
    contacts: {
      left: [...basicNavigationUtils],
      right: [...basicRightButtons, ...contactUtils],
    },
    outlines: {
      left: [...basicNavigationUtils],
      right: [...basicRightButtons, ...outlineUtils],
    },
  } as MobileContextMenuConfig;

  $: defaultLeftButtons =
    contextMenuButtons[defaultContextButtonsSection]?.left;
  $: defaultRightButtons =
    contextMenuButtons[defaultContextButtonsSection]?.right;

  $: leftButtons =
    contextMenuButtons[activeMobileSection]?.left ||
    (defaultLeftButtons as ICustomButton[]);
  $: rightButtons =
    contextMenuButtons[activeMobileSection]?.right ||
    (defaultRightButtons as ICustomButton[]);
</script>

<div
  class="h-full w-full relative"
  on:contextmenu|preventDefault
  use:longpress={500}
  on:longpress={() => {
    active = true;
  }}
  use:doubletap
  on:doubletap={() => {
    console.log("doubletapped");
  }}
  use:swipe
  on:swipe={handleSwipe}
>
  <div class="relative z-10 h-full w-full">
    <slot />
  </div>

  {#if active}
    <div
      class="absolute inset-0 flex items-center justify-center p-2"
      on:click|self={() => {
        active = false;
      }}
      style="z-index:100000;"
      on:keydown
    >
      <div
        class="w-full flex flex-row gap-1 p-0.5 rounded-lg shadow-lg border border-base-content bg-neutral border-opacity-20"
      >
        <div class="flex flex-col w-1/2">
          {#each leftButtons as button}
            <button
              class="btn btn-block bg-base-300 px-2 mb-1 flex flex-row items-center justify-start"
              on:click={button.onClick}
            >
              <span class="inline-block h-5 w-5 mr-1">
                <svelte:component this={button.icon} />
              </span>
              <span>
                {button.text}
              </span>
            </button>
          {/each}
        </div>
        <div class="flex flex-col w-1/2">
          {#each rightButtons as button}
            <button
              class="btn btn-block mb-1 bg-base-300 px-2 text-left flex flex-row items-center justify-start"
              on:click={button.onClick}
            >
              <span class="inline-block h-5 w-5 mr-1">
                <svelte:component this={button.icon} />
              </span>
              <span class="">{button.text}</span>
            </button>
          {/each}
        </div>
      </div>
    </div>
  {/if}
</div>

<svelte:window
  on:keydown={(e) => {
    if (e.key === "Escape") {
      active = false;
    }

    if(e.key === "w"){
      active = true
    }
  }}
/>


