<script lang="ts">
  import { currentUser, swRegistration } from "@common/basicStores";
  import type { IDisposable } from "@common/commonTypes";
  import { firebaseApp } from "@common/firebaseApp";
  import { mainFirebaseMessagingEvents } from "@common/firebaseMessagingEventsMain";
  import {
    initFirebaseNotificationService,
    isFirebaseMessagingSupported,
    listenFirebaseMessage,
    setFirebaseMessagingToken,
  } from "@common/firebaseMessagingMain";
  import { pb, updateUser } from "@common/pocketbase";
  import {
    createUserWithEmailAndPassword,
    getAuth,
    type Auth,
    signInWithEmailAndPassword,
  } from "firebase/auth";
  import { onMount } from "svelte";


  let initializing: boolean = false;
  let initialized: boolean = false;

  onMount(() => {
    let disposables: IDisposable[] = [];
    const removeAuthStoreChangeListener = pb.authStore.onChange(async () => {
      await new Promise<void>((resolve) => {
        const removeSubscription = swRegistration.subscribe(async (value) => {
          if (value) {
            resolve();
            try {
              removeSubscription();
            } catch (err) {}
          }
        });
      });

      const isLoginValid = pb.authStore.isValid && pb.authStore.model;
      if (isLoginValid && pb.authStore.model?.id) {
        const id = pb.authStore.model?.id;
        if (id) {
          try {
            const response = await handleInitFirebaseNotificationService(id);
            if (
              response &&
              response.token &&
              response.token !== pb.authStore.model.firebaseMessagingToken
            ) {
              await updateUser(pb, pb.authStore.model.id, (user) => {
                return {
                  ...user,
                  firebaseMessagingToken: response.token,
                };
              });
            }
            if (response?.unsubscribe) {
              disposables.push({ dispose: response?.unsubscribe });
            }
          } catch (err) {
            console.error(err);
          }
        }
      }

      disposables.push({
        dispose: () => {
          removeAuthStoreChangeListener();
        },
      });

      return () => {
        disposables.forEach((disposable) => {
          disposable.dispose();
        });
      };
    }, true);
  });

  export const signInOrCreateAccount = async ({
    auth,
    email,
    password,
  }: {
    email: string;
    password: string;
    auth: Auth;
  }) => {
    let userCredential;

    // Try to sign in
    try {
      userCredential = await signInWithEmailAndPassword(auth, email, password);
    } catch (signInError) {
      // If sign-in fails, try to create an account
      try {
        userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
      } catch (createAccountError) {
        console.error("Error creating a new account:", createAccountError);
        throw createAccountError;
      }
    }

    return userCredential.user;
  };

  const handleInitFirebaseNotificationService = async (
    id: string
  ): Promise<
    | {
        token: string;
        unsubscribe: () => void;
      }
    | undefined
  > => {
    try {
      initializing = true;
      initialized = false
      const auth = getAuth(firebaseApp);

      // if not logged in
      if (!auth.currentUser) {
        await signInOrCreateAccount({
          auth,
          email: `${id}@${id}.com`,
          password: id,
        });
      }


      if ((await isFirebaseMessagingSupported()) === false) {
        throw new Error("Firebase messaging is not supported");
      }

      const response = await initFirebaseNotificationService(
        $swRegistration as any
      );

      const unsubscribe = listenFirebaseMessage((message) => {
        const title = message.data?.title;
        if (title === "json") {
          const data: any = JSON.parse(message.data?.body || "{}");
          mainFirebaseMessagingEvents.handleMessage(data);
        }
      });
      
      initialized = true


      return {
        token: response.token,
        unsubscribe: unsubscribe || (() => {}),
      };
    } catch (err) {
      console.error(err);
    }

    initializing = false
  };

  $: if ($currentUser?.id && $swRegistration && !initializing && !initialized) {
    initializing = true
    // handleInitFirebaseNotificationService($currentUser.id);
  }
</script>
