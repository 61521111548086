const MAX_WORD_LENGTH = 22
const MIN_WORD_LENGTH = 3
const MAX_WORD_COUNT = 100

/**
 * class to store and manage the word index
 * @class WordIndex
 */
class WordIndex {
  constructor() {
    this.index = {}
  }

  /**
   * add a word to the index
   */
  addWord(word) {
    /**
     * if word is bigger than the max word length, then dont add it
     */
    if (word.length > MAX_WORD_LENGTH || word.length < MIN_WORD_LENGTH) {
      return
    }
    if (!this.index[word]) {
      this.index[word] = {
        word: word,
        count: 0,
      }
    } else {
      this.incrementWordCount(word, 1)
    }
  }

  /**
   * increment the count of a word
   * @param {string} word - the word to increment
   * @param {number} count - the amount to increment by
   * @returns {number} the new count
   * @memberof WordIndex
   * @instance
   * @example
   * wordIndex.incrementWord('hello', 1);
   * // returns 2
   * wordIndex.incrementWord('hello', 1);
   * // returns 3
   */
  incrementWordCount(word, count) {
    if (!this.index[word]) {
      this.addWord(word)
    }
    this.index[word].count += count

    // if word count exceeds 2
    // make the count of other words start starting with same word to be zero
    if (this.index[word].count > 2) {
      for (let key in this.index) {
        if (this.index[key].word.startsWith(word)) {
          this.index[key].count = 0
        }
      }
    }
    return this.index[word].count
  }

  /**
   * find the word in the index that starts with the given string
   */
  findWord(word) {
    const words = Object.keys(this.index)
    const matches = words.filter(w => w.startsWith(word))

    if (matches.length === 0) {
      return null
    }

    //sort by count in descending order
    return matches.sort((a, b) => this.index[b].count - this.index[a].count)
  }

  /**
   * remove a word from the index
   * @param {string} word - the word to remove
   * @returns {boolean} true if the word was removed, false if it was not found
   */
  removeWord(word) {
    if (this.index[word]) {
      delete this.index[word]
      return true
    }
    return false
  }

  /**
   * when index grows too large, remove the least used words
   */
  prune() {
    const words = Object.keys(this.index)
    words.sort((a, b) => this.index[a].count - this.index[b].count)
    words.slice(words.length - MAX_WORD_COUNT)
    words.forEach(w => {
      delete this.index[w]
    })
  }

  /**
   * generate the index from bulk data
   * @param {string[]} bulkData - the bulk data
   */
  generateIndex(bulkData) {
    bulkData.forEach(d => {
      if (d) {
        const words = d.split(' ')
        words.forEach(w => {
          if (w && w.trim()) {
            const word = w.trim()
            this.addWord(word)
          }
        })
      }
    })
  }
}

export default WordIndex
