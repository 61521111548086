<script lang="ts">
    import { toolsMenuSettings } from "@common/basicStores";
	import Babelbab from "@icons/Babelbab.svelte";

	$: collapsed = $toolsMenuSettings.collapsed;

</script>

<div class="flex relative flex-col">
	<div class="flex relative flex-row items-center mb-2">
		<button
			on:click={() => ($toolsMenuSettings.collapsed = !collapsed)}
			class="btn mr-2 btn-ghost btn-circle"
			class:collapsedActive={collapsed}
		>
			<span class="h-6 w-6 inline-block text-neutral-focus">
				<Babelbab />
			</span>
		</button>
		{#if !collapsed}
			<div
				class:animateChildButtonsX={!collapsed}
				class="flex absolute left-14  right-0 sm:right-auto flex-row"
			>
				<slot />
			</div>
		{/if}
	</div>
	<!-- {#if !collapsed}
		<div
			class:animateChildButtonsY={!collapsed}
			class="absolute z-20 top-15 flex flex-col gap-2"
		>
			<AppNavigationButtons />
		</div>
	{/if} -->
</div>

<style lang="postcss" global>
	.animateChildButtonsX button {
		animation: ButtonChildAnimationX 0.2s ease-out !important;
	}

	.collapsedActive{
		@apply border border-base-content border-opacity-50 bg-neutral-focus;
	}

	@keyframes ButtonChildAnimationX {
		from {
			opacity: 0;
			transform: translateX(-50%);
		}
		to {
			opacity: 1;
			transform: translateX(0);
		}
	}

	.animateChildButtonsY button {
		animation: ButtonChildAnimationY 0.2s ease-out !important;
	}

	@keyframes ButtonChildAnimationY {
		from {
			opacity: 0;
			transform: translateY(-50%);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}
</style>
