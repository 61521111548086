<script lang="ts">
  import "@common/i18n";
  import { currentUser, overlayModal } from "@common/basicStores";

  import BeforeInstallPromp from "@components/BeforeInstallPromp.svelte";
  import ErrorView from "@components/Error/ErrorView.svelte";
  import Layout from "@components/Layout/Layout.svelte";

  import ServiceWorker from "@components/ServiceWorker.svelte";
  import { onMount } from "svelte";
  import Routes from "./Routes/Routes.svelte";

  import { extend } from "dayjs";
  import relativeTimePlugin from "dayjs/plugin/relativeTime";
  import { removeAllDatabasesWithChunks } from "@common/utils";
  import GlobalAudio from "@components/Utils/GlobalAudio.svelte";
  import BottomBarNotifications from "@components/Notifications/BottomBarNotifications.svelte";
  import KnockKnockSound from "@components/Utils/KnockKnockSound.svelte";
  import WakeLock from "@components/Utils/WakeLock.svelte";
  import NetworkManager from "@components/Utils/NetworkManager.svelte";
  import FirebaseNotifications from "@components/Layout/FirebaseNotifications.svelte";
  import LinkHints from "@components/Utils/LinkHints.svelte";
 

  extend(relativeTimePlugin);

  onMount(() => {
    removeAllDatabasesWithChunks();
  });
</script>

<Layout isModalOpen={$overlayModal.isOpen}>
  <ServiceWorker />
  <BeforeInstallPromp />
  <GlobalAudio />
  <BottomBarNotifications />
  <KnockKnockSound />
  <WakeLock />
  <Routes />
  <NetworkManager />
  <FirebaseNotifications />
  <LinkHints />
  <svelte:fragment slot="modal">
    {#if $overlayModal.component}
      <svelte:component
        this={$overlayModal.component}
        {...$overlayModal.props || {}}
      />
    {:else}
      <ErrorView error={new Error("No Modal Provided")} />
    {/if}
  </svelte:fragment>
</Layout>
