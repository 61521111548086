<script lang="ts">
  import CollapsibleToolsMenu from "@components/Menu/CollapsibleToolsMenu.svelte";
  import Send from "@icons/Send.svelte";
  import MessagesLayout from "../MessagesLayout.svelte";
  import MessageInputView from "../Views/MessageInputView.svelte";
  import VoiceMessageButton from "../VoiceMessageButton.svelte";
  import RoomControlsPlaceholder from "./RoomControlsPlaceholder.svelte";
  import RoomStatusPlaceholder from "./RoomStatusPlaceholder.svelte";
  import { fly, slide } from "svelte/transition";

  export let controlsVisible: boolean = true;
  export let inputDisabled: boolean = false;
  export let sendButtonDisabled: boolean = false;
  export let inputVisible: boolean = true;

  let messages: string[] = [];
</script>

<div class="flex flex-col items-center justify-center w-full h-full">
  <div class="flex items-center md:flex-row flex-col justify-between w-full">
    {#if controlsVisible}
      <div class="relative flex-grow w-full md:w-auto">
        <CollapsibleToolsMenu>
          <RoomControlsPlaceholder />
        </CollapsibleToolsMenu>
      </div>
      <div
        class="flex w-full md:w-auto overflow-auto items-center gap-2 text-sm font-semibold uppercase"
      >
        <RoomStatusPlaceholder />
      </div>
    {/if}
  </div>

  <div class="relative flex-grow w-full h-full">
    <div class="absolute inset-0">
      <MessagesLayout>
        <svelte:fragment slot="messages">
          <slot name="messages">
            <div
              class="h-[40%] mt-4 w-[80%] rounded-lg bg-neutral animate-pulse"
            />
          </slot>
        </svelte:fragment>

        <svelte:fragment slot="input">
          {#if inputVisible}
            <div class="relative w-full h-full" transition:fly>
              <MessageInputView
                value={""}
                placeholder="Type..."
                on:keydown
                disabled={inputDisabled}
              />
            </div>
          {/if}
        </svelte:fragment>

        <svelte:fragment slot="voiceButton">
          {#if inputVisible}
            <!-- <VoiceMessageButton /> -->
            <button
              disabled={sendButtonDisabled}
              class:disabled={sendButtonDisabled}
              class="h-full border-white btn-circle border-opacity-20 btn btn-ghost bordered hover:btn-accent"
            >
              <span class="iline-block w-5 h-5">
                <Send />
              </span>
            </button>
          {/if}
        </svelte:fragment>
      </MessagesLayout>
    </div>
  </div>
</div>

<style lang="postcss" global>
  .allDisabled button {
    @apply disabled opacity-50 cursor-not-allowed;
  }
</style>
