import { noop } from 'lodash'
// import { saveBlob, urlToBlob } from '../helpers/saveUserFile'
import { isMagnetLink } from '../helpers/utils'
import Writable from './advancedStore'
import PouchStore from './classes/PouchDBStore'
import _encryption from './encryption'

class FileStore extends PouchStore {
  constructor(options = {}, key) {
    options = {
      basePath: 'videos',
      files: [],
      ...options,
    }
    super(options, { key })
    this.saving = []
  }

  getExtension(str) {
    const re = /^(.+)(\.)(\w+)$/
    return str.replace(re, '$3')
  }

  getRandomName(name = '') {
    const ext = this.getExtension(name)
    return `${Date.now()}__file${ext ? '.' + ext : ''}`
  }

  addToSaving(url) {
    this.saving = [...this.saving, url]
  }

  isSaving(url) {
    return this.saving.find(_url => _url !== url)
  }

  removeFromSaving(url) {
    this.saving = this.saving.filter(_url => _url !== url)
  }

  async saveFile(url, privateKey, cb, name = this.getRandomName(url)) {
    if (isMagnetLink(url)) return url
    // return url
    try {
      this.addFile({ url })
      this.addToSaving(url)

      const blob = await urlToBlob(url)
      let fileURL = await saveBlob(`${this.basePath}/${name}`, blob)

      if (url.endsWith('.enc') || (url.endsWith('.encx') && privateKey)) {
        fileURL = await _encryption.decryptFile(fileURL, privateKey)
        this.addFile({ url, file: fileURL })
        cb(fileURL)

        this.removeFromSaving(url)
        return fileURL
      } else {
        this.addFile({ url, file: fileURL })
        cb(fileURL)
        this.removeFromSaving(url)
        return fileURL
      }
    } catch (err) {
      console.error(err)
      this.removeFromSaving(url)
      return url
    }
  }

  addFile({ url, file } = {}) {
    const exists = this.getFile(url)

    if (exists) {
      this.files = this.files.map(item => ({
        ...item,
        file: url === item.url ? file : item.file,
      }))
    } else {
      this.files = [...this.files, { url, file, timestamp: Date.now() }]
    }
  }

  hasFile(url) {
    return this.getFile(url) && true
  }

  getFile(url) {
    return this.files.find(item => item.url == url)
  }

  getFileURL(url, downloadIfNotExists, privateKey, cb = noop, store) {
    if (this.isSaving(url)) return url

    const result = this.files.find(item => item.url == url)

    const isValid =
      result && result.file !== 'undefined' && result.file !== undefined

    if (!isValid && downloadIfNotExists) {
      this.saveFile(url, privateKey, cb)
    }
    return result && result.file
  }
}

const fileStore = new FileStore({}, '_local/file_store_2')

export default fileStore

export { FileStore }
