const notificationApiBase = "http://localhost:4221";


export enum NotificationDestination {
	mobile = "mobile",
	web = "web"
}

export interface IPushNotification {

	title: string;
	body: string;
	id: string;
	destination?: NotificationDestination[];
}

export const sendNotification = async ({
	title,
	body,
	id,
	destination = [NotificationDestination.web, NotificationDestination.mobile]
}: IPushNotification = {} as any) => {
	
	const promises = destination.map(async (dest) => {
		try{
			const url = new URL(`/notifications/trigger`, notificationApiBase);
			url.searchParams.set("title", title )
			url.searchParams.set("body", body )
			url.searchParams.set("userId", id )
			const res = await fetch(url.href).then(res => res.json())
			
			return {
				success: true,
				destination: dest,
				response: res.json()
			}
			
		}catch(err){
			return {
				success: false,
				error: err,
				destination: dest
			}
		}
	})

	const results = await Promise.all(promises);

	return {
		success: results.every(r => r.success),
		failedResults: results.filter(r => !r.success),
		results: results
	}
}

export const sendNotificationToUsers = async ({
	members,
	excludes,
	notification
}: {
	notification:Partial<IPushNotification>,
	members: string[],
	excludes: string[]
}): Promise<boolean> => {
	const wids = (members?.map(m => m) || []).filter(wid => !excludes.includes(wid));

	const promises = wids.map(async (wid) => {
		return sendNotification({
			...notification,
			id: wid
		} as any)
	});

	const results = await Promise.all(promises);
	return results.every(r => r.success);
}