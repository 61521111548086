<svg
  xmlns="http://www.w3.org/2000/svg"
  class="h-full w-full"
  viewBox="0 0 24 24"
  ><path
    fill="none"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M8 9h8m-8 4h6m-5 5H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-3l-3 3l-3-3z"
  /></svg
>
