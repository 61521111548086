import {
	navigate,
	useLocation
} from 'svelte-navigator';
import { derived, writable } from 'svelte/store';

export const location = writable("");

export const push = (path: string) => {
	navigate(path);
}

export const replace = (path: string) => {
	navigate(path, { replace: true });
}

export const querystring = writable("");

