import _, { isEmpty, isString } from 'lodash'
import { getInternetDB } from './../database2'
import Writable from './../advancedStore'
import PouchDatabase from './../../helpers/PouchUtils'

class PouchStore extends Writable {
  constructor(
    initialState,
    {
      storeOptions = {},
      key,
      saveInterval = 1000,
      db = getInternetDB(),
      throttleOptions: { leading = false, trailing = true } = {},
      ...options
    } = {}
  ) {
    // if (!key && !isString(key)) throw new Error('key not present')

    super(initialState)

    this._options = {
      key,
      storeOptions,
      saveInterval,
      db,
      throttleOptions: { leading, trailing },
      ...options,
    }

    this._dataReady = new Writable({ val: false })
    this._key = key
    this._db = this._options.db
    this.db = db
    this._db = db

    this._init()
  }

  isReady() {
    // return new Promise((res, rej) => {
    //   setTimeout(() => rej('Timeout waiting store to be ready'), 10000)
    //   this._dataReady.subscribe(({ val }) => val && res())
    // })
  }

  async _init() {
    // try {
    //   await this.loadData()

    //   const unsubscribe = await this.handleChange()

    //   this._dataReady.val = true
    //   return unsubscribe
    // } catch (err) {
    //   console.error(err)
    // }
  }

  async loadData() {
    // try {
    //   const data = await this.getDoc(this._key)
    //   if (!isEmpty(data)) {
    //     this.current = data
    //   }
    // } catch (err) {
    //   console.error(err)
    //   const currentData = this.json
    //   await this.setDoc({ ...currentData, _id: this._key })
    // }
  }

  async getDoc(key, db = this._db) {
    // return PouchDatabase.getDoc(key, db)
  }

  async setDoc(doc, db = this._db) {
    // return PouchDatabase.setDoc(doc, db)
  }

  async handleChange() {
    // const handler = this.saveData.bind(this)
    // const _handler = _.throttle(
    //   handler,
    //   this._options.saveInterval,
    //   this._options.throttleOptions
    // )

    // const unsubscribe = this.subscribe(_handler)
    // return unsubscribe
  }

  async saveData() {
    // const data = this.json
    // await this.setDoc({ ...data, _id: this._key })
  }

  async retryUntilWritten(doc, db = this._db, count = 0) {
    // return PouchDatabase.retryUntilWritten(doc, db, count)
  }
}

export default PouchStore
