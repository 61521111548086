import {extend} from 'dayjs'
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import IsoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import IsoWeek from 'dayjs/plugin/isoWeek';
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import Utc from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone'


extend(AdvancedFormat);
extend(LocalizedFormat);
extend(IsoWeeksInYear);
extend(IsoWeek);
extend(Utc);
extend(Timezone);


