import _, { isEmpty, noop } from 'lodash'
import { writable, get, type Writable} from 'svelte/store'
import Emitter from 'tiny-emitter'

/**
 * @param {Object} initialState initial state for the store,
 * if object properties will be available as getters and setters
 *
 * @param {Object} options
 * @param {String} options.key if provided a key data will be persisted in local storage
 * @param {Boolean} options.defineGettersAndSetters if true it will make initialState properties as getters and setters
 * example: if initialState is {test: true} it will make test as getter and setter
 * you you can directly assign and access the value to `test` and it will update the store
 */
class Writable<T = any> {

  private _persistentMode: boolean
  public initialState: T
  public options: any

  public writable: any
  public subscribe: any


  constructor(initialState: T, options: any = {}) {
    this._persistentMode = options.key ? true : false

    this.initialState =
      this._persistentMode && !options.overwrite
        ? this.getDataFromStorage(options.key, initialState)
        : initialState

    this.options = {
      defineGettersAndSetters: true,
      key: null,
      onSet: noop,
      onChange: noop,
      onUpdate: noop,
      ...options,
    }

    const store = writable(_.cloneDeep(this.initialState))

    this.writable = writable
    this.subscribe = store.subscribe

    this._update = store.update
    this._set = store.set

    const emitter = new Emitter()

    this.on = emitter.on
    this.emit = emitter.emit
    this.off = emitter.off
    this.once = emitter.once

    if (this.options.defineGettersAndSetters && _.isObject(initialState)) {
      this.defineGettersAndSetters(this.initialState)
    }

    this.storeData(this.json)
  }
  update(...args) {
    const temp = this._update(...args)

    this.options.onUpdate && this.options.onUpdate(this.current)
    this.options.onChange && this.options.onChange(this.current)

    this.emit('change', ...args)
    this.emit('update', ...args)

    if (this._persistentMode) {
      this.storeData(this.json)
    }

    return temp
  }
  set(...args) {
    const temp = this._set(...args)

    this.options.onSet && this.options.onSet(...args)
    this.options.onChange && this.options.onChange(...args)

    this.emit('change', ...args)
    this.emit('set', ...args)

    if (this._persistentMode) {
      this.storeData(this.json)
    }
    return temp
  }

  waitUntilEvent(event, timeout) {
    return new Promise((res, rej) => {
      this.on(event, res)
      if (timeout) {
        setTimeout(() => {
          rej(new Error('TIMEOUT'))
        }, timeout)
      }
    })
  }

  storeData(data) {
    if (this._persistentMode) {
      if (isEmpty(data)) return
      try {
        let string = JSON.stringify(data)
        localStorage.setItem(this.options.key, string)
      } catch (err) {
        console.error(Err)
      }
    }
  }

  getDataFromStorage(key, initialState) {
    try {
      let data = localStorage.getItem(key)
      if (data) {
        const parsedData = JSON.parse(data)

        if (isEmpty(parsedData)) {
          return initialState
        }
        return parsedData
      } else {
        return initialState
      }
    } catch (err) {
      console.error(err)
    }
    return initialState
  }

  /**
   * resets to initialState
   */
  reset() {
    this.set(this.initialState)
  }

  getData() {
    return get(this)
  }

  defineGettersAndSetters(options = {}) {
    for (let key in options) {
      if (key === 'current' || key === 'json') return
      Object.defineProperty(this, key, {
        get: function() {
          return this.current[key]
        },
        set: function(value) {
          this.update(state => ({ ...state, [key]: value }))
        },
      })
    }
  }

  /**
   * gets the current store value
   */
  get current() {
    return this.getData()
  }

  /**
   * sets the current store value
   * @param {Object} value
   */
  set current(value) {
    this.set(value)
  }

  get json() {
    const current = this.current
    if (_.isObject(current)) {
      for (let key in current) {
        const item = current[key]

        if (_.isArray(item)) {
          current[key] = item.map(e => {
            if (_.isObject(e) && e instanceof Writable) {
              return e.json
            }
            return e
          })
        } else if (_.isObject(item) && item instanceof Writable) {
          current[key] = item.json
        }
      }
    }
    return current
  }
}

export default Writable
