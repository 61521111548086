import {uid} from 'uid'
import dayjs from 'dayjs'
import { generateUniqueName } from './../common/uniqueNameGenerator';

const types = {
  node: 'node',
  page: 'page',
  dailyNotes: 'dailyNotes',
}

class node {
  constructor({
    type,
    title,
    parent,
    string,
    createEmail,
    createTime,
    id,
    editTime,
    editEmail,
    parentPage,
    lastFocused,
    lastRendered,
    children,
  } = {}) {
    this['type'] = type
    this['create-email'] = createEmail || 'user@domain.com'
    this['create-time'] = createTime || Date.now()
    this['edit-time'] = editTime || Date.now()
    this['edit-email'] = editEmail || 'user@domain.com'
    this['uid'] = id || uid()
    this['children'] = children || []
    this.lastFocused = []
    this.lastRendered = []

    if (type === types.page) {
      this['title'] = title || generateUniqueName()
    }
    if (type === types.dailyNotes) {
      this['title'] = title || dayjs().format('LL')
      this['create-email'] = null
      this['create-time'] = null
    }

    if (type === types.node) {
      this['string'] = string || ''
      this['parent'] = parent
      this['parentPage'] = parentPage
      this['expanded'] = true
      this['active'] = false
    }
  }
}

class workspace {
  constructor({
    data,
    name,
    createEmail,
    createTime,
    editTime,
    editEmail,
    id,
    isPublished,
  } = {}) {
    this['create-email'] = createEmail || 'user@domain.com'
    this['create-time'] = createTime || Date.now()
    this['edit-time'] = editTime || Date.now()
    this['edit-email'] = editEmail || 'user@domain.com'
    this['uid'] = id || uid()
    this['name'] = name || generateUniqueName()

    this['data'] = data || {
      pages: [],
      nodes: {},
      shortcuts: [],
    }
    this['isPublised'] = isPublished || false
  }
}

export { node, types, workspace }
