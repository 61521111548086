import { uniqueNamesGenerator, adjectives, colors } from 'unique-names-generator';

export const config = {
	separator: '-',
	length: 2,
	dictionaries: [adjectives, colors],
};


export const generateUniqueName = (): string => {
	return uniqueNamesGenerator(config);
}
