import { calendarEventsStore, notificationController } from "./basicStores";
import { updateCalendarSubEvent, updateChanges } from "./calendarUtils";
import { FirebaseMessagingEvents } from "./firebaseMessagingEvents";
import { getCalendarEventsStore } from "./getCalendarStore";


export const mainFirebaseMessagingEvents= new FirebaseMessagingEvents();

mainFirebaseMessagingEvents.addListeners({
	"message": async (message) => {
		
	}
})