import { writable, get } from 'svelte/store'
import _ from 'lodash'

export const isSidebarOpen = writable(false)
export const activePage = writable(null)
export const hoveringTrigger = writable(true)
export const breathingActive = writable(false)
export const meditateActive = writable(false)
export const radioActive = writable(false)
export const overlayMenu = writable(false)
export const qrCodeView = writable(false)
export const timePicker = writable(false)
export const datePicker = writable(false)
export const layoutDivide = writable("no-split")
export const appIsReady = writable(false)
export const navbarMessage = writable(``)

export const webviewKeyup = writable(null)
export const webviewKeydown = writable(null)
export const webviewClick = writable(null)
export const tempViewSwitch = writable(null)
export const settingsActive = writable(null)
export const dontShowSidebar = writable(null)
export const currentActivePage = writable(null)
export const drawerViewQuery = writable('')
export const stopGlobalShortcuts = writable(false)
export const keyLoggerView = writable(false)
export const key = writable(false)
export const isRelaxVisible = writable(false)
export const embedOverlayDrawer = writable(false)
export const focusOverlayNode = writable(_.noop)
export const shortcutCaptureView = writable(false)
export const toggleBackContext = writable(null)
export const lastActiveStore = writable(null)
export const videoInMenu = writable("")
export const videoInMenuProvider = writable("")
export const imageInMenu = writable(false)
export const specialAppsMode = writable(false)
export const voiceCall = writable()
export const followingUser = writable({
  isFollowing: false,
  receivedFollowRequest: false,
  sentFollowRequest: false
})
export const memberCount = writable(0)
export const emojiPicker = writable({
  callback: () => {},
  active: false,
})
// export const mainUrl
export const isSynced = writable(true)
export const lightbox = writable({
  active: false,
  imageURL: null,
})

export const tabs = writable({
  isActive: false,
  activeTab: null,
  items: [],
})

export const drawerQueryBuilder = writable({
  isActive: false,
  store: null,
  uid: null,
})

export const drawerTemplateBuilder = writable({
  isActive: false,
  store: null,
  uid: null,
})


export let portalStore = writable({
  currentFocused: null,
  portals:[],
  currentFullscreen: null,
})

export const currentStore = writable(null)
export const currentSpacePath = writable("/page")
export const lastDayChanges = writable([])
export const referencesGutterEnabled = writable(false)

export const showChangelog = writable(false)

export const referencesModal = writable({
  isActive: false,
  store: null,
  references: [],
  close: () => {},
})


export const hideTags = writable(false)
export const tagFeed = writable({
  store: null,
  query: ""
})


export const feedBuilder = writable({
  isActive: false,
  store: null,
  onCompleted: () => {},
  onError: () => {},  
  onCancelled: () => {},
  close: () => {},
})


export const recentChanges = {
  ...writable({}),

  add(id, item){
    this.update(state => {
      state[id] = item
      return state
    })
  },
 
  remove(id){
    this.update(state => {
      delete state[id]
      return state
    })
  },

  clear(){
    this.update(state => {
      state = {}
      return state
    })
  },

  get(id){
    return get(this).get(id)
  },
  
  toJSON(){
    return get(this)
  }
}

class SearchBoxState {
  constructor() {
    this.isHidden = false
    this.isFocused = false
    this.isListOpen = false
    this.value = ''
    this.items = []
    this.selectedValue = null
    this.mode = 'intranet'
    this.preventItemsUpdate = false
    this.placeholder = 'Search notes...'
  }
}

class NavbarState {
  constructor() {
    this.visible = true
    this.mode = 'normal'
    this.showCancel = false
    this.hidePageOptions = false
    this.closeHandler = () => {}
  }
}

export const navbar = {
  ...writable(new NavbarState()),
}

export const searchBox = {
  ...writable(new SearchBoxState()),

  setItems: function(items = []) {
    const state = get(this)
    if (state.preventItemsUpdate) return true
    if (_.isArray(items)) {
      this.update(state => ({
        ...state,
        items,
      }))
    }
  },
  focus: function(mode) {
    this.update(state => {
      if (state.isFocused && state.mode) {
        if (!state.fixedMode) {
          state.mode = state.mode === 'internet' ? 'intranet' : 'internet'
        } else {
          state.mode = fixedMode
        }
        state.placeholder =
          state.mode === 'internet' ? 'Search the web..' : 'Search notes...'
      }
      return {
        ...state,
        isListOpen: true,
        isFocused: true,
      }
    })
  },
  setMode(mode) {
    this.update(state => {
      state.mode = mode
      state.placeholder =
        state.mode === 'internet' ? 'Search the web..' : 'Search notes...'
      return {
        ...state,
      }
    })
  },
  blur: function() {
    this.update(state => ({
      ...state,
      isFocused: false,
      isListOpen: false,
      mode: 'intranet',
    }))
  },
  setValue: function(value = '') {
    if (_.isString(value)) {
      this.update(state => ({
        ...state,
        value: value,
      }))
    }
  },
  select: function(selectedValue) {
    this.onSelect.set(selectedValue)
    this.update(state => ({
      ...state,
      selectedValue,
    }))
  },

  notify(msg = '', ms = 3000) {
    this.update(state => {
      state.preventItemsUpdate = true
      state.filterText = msg
      state.isFocused = true

      setTimeout(() => {
        this.update(state => {
          state.preventItemsUpdate = false
          state.filterText = ''
          return state
        })
        this.blur()
      }, ms)

      return state
    })
  },
  onSelect: writable(null),
}
