<script lang="ts">
  import { networks, notificationController } from "@common/basicStores";
  import { onMount } from "svelte";
  import ConnectionManagerView from "./ConnectionManagerView.svelte";

  onMount(() => {
    networks.load();
  });
</script>

{#if $networks.ready}
  {#each $networks.items as network (network.id)}
    <ConnectionManagerView
      connectionManager={network._connectionManager}
      sync={!network.temporary}
    />
  {/each}
{/if}
