<svg
	class="h-full w-full"
	viewBox="0 0 298 455"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<rect
		x="14.5"
		y="14.5"
		width="269"
		height="426"
		stroke="white"
		stroke-width="29"
	/>
</svg>
