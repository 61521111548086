<script lang="ts">
  import Toast from "@components/Toast/Toast.svelte";
import { mainIframe, rootElement, theme } from "../../common/basicStores";
import MainEmbed from "./MainEmbed.svelte";
  import MobileContainer from "./MobileContainer.svelte";

  export let isModalOpen = false;
</script>

<div
  bind:this={$rootElement}
  data-theme={$theme}
  class="flex flex-col dark w-full h-full bg-base-300 text-base-content"
>
  <Toast />
  <MobileContainer>
    <div class="relative flex flex-row flex-grow w-full">
      <div class="sidebar">
        <slot name="sidebar" />
      </div>

      <div class="relative flex-grow">
        <div class="absolute inset-0">
          <div class="h-full w-full flex flex-col gap-2">
            {#if $mainIframe}
              <div class="relative" style="flex-grow: 1.5;">
                <div class="absolute inset-0">
                  <MainEmbed />
                </div>
              </div>
            {/if}
            <div class="flex-grow relative" style="flex-grow:2;">
              <div class="absolute inset-0">
                <slot name="body" />
                <slot />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {#if isModalOpen}
      <div class="absolute inset-0 z-50">
        <slot name="modal" />
      </div>
    {/if}
  </MobileContainer>
</div>
