<script>
	import { isMobileDevice } from "@common/isMobileDevice";
	import Copy from "@icons/Copy.svelte";
	import Logout from "@icons/Logout.svelte";
	import Whatsapp from "@icons/whatsapp.svelte";
	import RoomButtonsContainer from "../Views/RoomButtonsContainer.svelte";
</script>

<RoomButtonsContainer>
	<button class="button" on:click={() => {}}>
		<span class="inline-block h-5 w-5">
			<Copy />
		</span>
	</button>
	{#if isMobileDevice()}
		<button class="button" on:click={() => {}}>
			<span class="inline-block h-5 w-5">
				<Whatsapp />
			</span>
		</button>
	{/if}

	<button class="button" on:click={() => {}}>
		<span class="inline-block h-5 w-5">
			<Logout />
		</span>
	</button>
</RoomButtonsContainer>

<style lang="postcss">
	.button {
		@apply btn btn-circle btn-ghost mr-2 border-opacity-50 border border-base-content;
	}
</style>
