import { uid } from "uid";
import type {
	ManipulateType,
	UnitType
} from 'dayjs'
import Dexie, { liveQuery } from 'dexie';
import dayjs from "dayjs";

export const DEFAULT_EVENT_LENGTH: ITimeLength = {
	length: 15,
	unit: "minute"
}
export const DEFAULT_TIME_FORMAT: ITimeFormat = "12";

export const FullToSmallDayNameMap: Record<IDay, string> = {
	"monday": "Mon",
	"tuesday": "Tue",
	"wednesday": "Wed",
	"thursday": "Thu",
	"friday": "Fri",
	"saturday": "Sat",
	"sunday": "Sun",
}
export type IDay = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";


export type ITimeFormat = "12" | "24";


export interface ITime {
	hour: number;
	minute: number;
	second: number;
}

export interface ICalAvailabilityRange {
	start: ITime;
	end: ITime;
}
export interface ICalAvailability {
	day: IDay;
	range: ICalAvailabilityRange[];
}

export interface ITimeLength {
	length: number;
	unit: ManipulateType;
}


export interface ICalRecurring {
	repeat: ITimeLength;
	maximumEvents: number;
}

export interface ICalEvent {
	id: string;
	title: string;
	eventLength: ITimeLength;
	description?: string;

	availability: ICalAvailability[];

	recurring?: ICalRecurring;

	bufferBefore?: ITimeLength,
	bufferAfter?: ITimeLength,

	limitBookingFrequency?: ITimeLength;
	limitBookingDuration?: {
		amount: ITimeLength,
		every: ITimeLength,
	},

	limitFutureBooking?: {
		range?: {
			start: Date,
			end: Date
		},
		amount?: ITimeLength,
	},

	allowSubscribing?: boolean;

	minimumNotice?: ITimeLength,
	timezone?: string,
	accepted?: boolean;
	startDate: number;
}


export interface ICalBookingMembers {
	id: string;
	name: string;
	isEventCreator: boolean;
}

export interface ICalBooking{
	id: string;
	eventId: string;
	from: ITime;
	to: ITime;
	startDate: number;
	members: ICalBookingMembers[];
	accepted?: boolean;
}

export interface ICalEventInput {
	title: string;
	eventLength: number;
	description?: string;
}


export interface ICalDatabase extends Dexie {
	calendarEvents: Dexie.Table<ICalEvent, string>;  // Table type with ICalEvent and primary key type
	calendarBookings: Dexie.Table<ICalBooking, string>;
}

export const timeToDayjs = (time: ITime) => {
	const instance =
		dayjs()
			.hour(time.hour)
			.minute(time.minute)
			.second(time.second);
	return instance;
}

export const perUnitSelectItems: Array<{
	value: ManipulateType;
	label: string;
}> = [
		{
			value: "day",
			label: "Per day"
		},
		{
			value: "week",
			label: "Per week"
		},
		{
			value: "month",
			label: "Per month"
		},
		{
			value: "year",
			label: "Per year"
		}
	]


export const isTimeEqual = (time1?: ITime, time2?: ITime) => {
	if (!time1 || !time2) {
		return false;
	}
	return time1.hour === time2.hour && time1.minute === time2.minute && time1.second === time2.second;
}

export const getDefaultAvailability = (): ICalAvailability[] => {
	const days: IDay[] = ["monday", "tuesday", "wednesday", "thursday", "friday"];
	const defaultAvailability: ICalAvailability[] = days.map(day => ({
		day,
		range: [{
			start: {
				hour: 9,
				minute: 0,
				second: 0
			},
			end: {
				hour: 17,
				minute: 0,
				second: 0
			}
		}]
	}));

	return defaultAvailability;
}

class CalDatabase extends Dexie implements ICalDatabase {
	calendarEvents!: Dexie.Table<ICalEvent, string>;  // '!' is for non-null assertion
	calendarBookings!: Dexie.Table<ICalBooking, string>;

	constructor() {
		super('CalDatabase');

		this.version(1).stores({
			calendarEvents: 'id, title',  // Index fields
			calendarBookings: 'id, eventId'
		});

		// Dexie's TypeScript declarations are a bit complex, 
		// this will bind the table instances correctly with TypeScript
		this.calendarEvents = this.table('calendarEvents');
		this.calendarBookings = this.table('calendarBookings');
	}

	// Typed methods
	async addEvent(event: ICalEvent): Promise<string> {
		return await this.calendarEvents.add(event);
	}

	async getEvent(id: string): Promise<ICalEvent | undefined> {
		return await this.calendarEvents.get(id);
	}

	async getAllEvents(): Promise<ICalEvent[]> {
		return await this.calendarEvents.toArray();
	}

	async updateEvent(id: string, updatedEvent: Partial<ICalEvent>): Promise<number> {
		return await this.calendarEvents.update(id, updatedEvent);
	}

	async deleteEvent(id: string): Promise<void> {
		return await this.calendarEvents.delete(id);
	}

	async searchEvents(query: string): Promise<ICalEvent[]> {
		return await this.calendarEvents
			.where('title')
			.startsWithIgnoreCase(query)
			.toArray();
	}

	async filterEventsByDay(day: IDay): Promise<ICalEvent[]> {
		return await this.calendarEvents
			.filter(event => event.availability.some(avail => avail.day === day))
			.toArray();
	}

	async filterEventsByTimezone(timezone: string): Promise<ICalEvent[]> {
		return await this.calendarEvents
			.where('timezone')
			.equals(timezone)
			.toArray();
	}

	async getUpcomingEvents(): Promise<ICalEvent[]> {
		const now = new Date();
		return await this.calendarEvents
			.filter(event => {
				if (event.limitFutureBooking?.range) {
					return event.limitFutureBooking.range.start > now && event.limitFutureBooking.range.end > now;
				}
				return true;
			})
			.toArray();
	}

	async getEventsSortedByTitle(): Promise<ICalEvent[]> {
		return await this.calendarEvents
			.orderBy('title')
			.toArray();
	}

	async generateRecurringEvents(eventId: string): Promise<string> {
		const event = await this.getEvent(eventId);
		if (!event || !event.recurring) {
			throw new Error("Event is not recurring");
		}

		const events = [];
		for (let i = 0; i < event.recurring.repeat.length; i++) {
			const newEvent: ICalEvent = { ...event };
			newEvent.id = uid();
			newEvent.recurring!.repeat.length -= i;
			events.push(newEvent);
		}

		return await this.calendarEvents.bulkAdd(events);
	}

	$getEvent(id: string) {
		return liveQuery(async () => {
			return await this.calendarEvents.where('id').equals(id).first();
		})
	}
	$getEvents() {
		return liveQuery(async () => {
			return await this.calendarEvents.toArray();
		})
	}

	async liveQuery(
		query: () => Promise<Dexie.Collection<ICalEvent, string>>
	) {
		return liveQuery(async () => {
			return await query();
		})
	}

	async addBooking(booking: ICalBooking): Promise<string> {
		return await this.calendarBookings.add(booking);
	}

	async getBooking(id: string): Promise<ICalBooking | undefined> {
		return await this.calendarBookings.get(id);
	}

	async getAllBookings(): Promise<ICalBooking[]> {
		return await this.calendarBookings.toArray();
	}

	$getBooking(id: string) {
		return liveQuery(async () => {
			return await this.calendarBookings.where('id').equals(id).first();
		})
	}

	$getBookings() {
		return liveQuery(async () => {
			return await this.calendarBookings.toArray();
		})
	}
}

export const getCalDb = () => {
	return new CalDatabase();
}


export const addCalEvent = async (event: ICalEvent) => {
	const db = getCalDb();

	await db.getEvent(event.id).then((existingEvent) => {
		if (existingEvent) {
			throw new Error("Event already exists");
		}
	})

	return await db.addEvent(event);
}

export const getCalEvent = async (id: string) => {
	const db = getCalDb();
	return await db.getEvent(id);
}

export const removeCalEvent = async (id: string) => {
	const db = getCalDb();
	return await db.deleteEvent(id);
}

export const updateCalEvent = async (id: string, callback: (events: ICalEvent) => ICalEvent) => {
	const db = getCalDb();
	const oldEvent = await db.getEvent(id);
	if (!oldEvent) throw new Error("Event does not exist");

	const updatedEvent = callback(oldEvent);
	if (!updatedEvent) throw new Error("Callback did not return an event");

	return await db.updateEvent(id, updatedEvent);
}


export const generateNewEvent = async (inputs: ICalEventInput) => {
	const { title, description, eventLength } = inputs;

	const newEvent: ICalEvent = {
		id: uid(),
		title,
		description,
		eventLength: {
			length: eventLength,
			unit: "minute"
		},
		availability: getDefaultAvailability(),
		timezone: dayjs.tz.guess(),
		startDate: Date.now(),
	}

	await addCalEvent(newEvent)

	return newEvent;
}
