import PocketBase, { Record, RecordService, type RecordSubscription, getTokenPayload } from 'pocketbase';
import { uid } from 'uid'
import { getLocalStorageItem, setLocalStorageItem } from './localStorage';
import { writable, type Unsubscriber, get } from 'svelte/store';
import store from '../Tree/stores';
import { generateAcceptCode } from './utils';

export const getPocketBaseInstance = (url: string) => {
	const pb = new PocketBase(url);
	return pb;
}

export const createAnonymousUser = async (pb: PocketBase, options: {
	isAdmin: boolean;
}) => {
	const username = uid(8);
	const user = await pb.collection("users").create({
		"username": username,
		"email": `${username}@biz.xn--lkv.com`,
		"emailVisibility": false,
		"password": "password",
		"passwordConfirm": "password",
		"lastSeen": new Date(),
		"isOnline": false,
		"isAdmin": options.isAdmin,
		"chats": [],
		"invites": [],
	})
	return user;
}




export const createAdminUser = async (pb: PocketBase): Promise<{
	username: string;
	password: string;
} | undefined> => {
	try {
		const json: {
			username: string;
			password: string;
		} = await fetch(`/admin/create-admin-user`).then(res => res.json())

		if (!json?.username || !json?.password) throw new Error("Failed to create admin user")

		return {
			username: json?.username,
			password: json?.password
		}
	} catch (err) {
		console.error(err)
		return undefined
	}
}


export const login = async (pb: PocketBase, username: string, password: string) => {
	try {
		const user = await pb.collection("users").authWithPassword(username, password)
		return user;
	} catch (err) {
		console.error(err)
		return undefined
	}
}


export const exportToCookie = async (pb: PocketBase): Promise<string> => {
	try {
		const isValid = pb.authStore.isValid;

		if(isValid) {
			const key = "pb_auth"
			const cookie = pb.authStore.exportToCookie({
				httpOnly: false,
			}, key);
			document.cookie = cookie;
			return pb.authStore.token
		}else{
			throw new Error("Invalid auth store")
		}
	} catch (err) {
		throw err;
	}
}

export const loadFromCookie = async (pb: PocketBase, cookieStr: string): Promise<boolean> => {
	try {
		pb.authStore.loadFromCookie(cookieStr)
		return true
	} catch (err) {
		console.error(err)
		return false
	}
}

export const storeUserCredentials = async (username: string, password: string) => {
	try {
		setLocalStorageItem("__id__", btoa(username))
		setLocalStorageItem("__secret__", btoa(password))
	} catch (err) {
		console.error(err)
	}
}

export const getStoredUserCredentials = async () => {
	try {
		const username = getLocalStorageItem("__id__")
		const password = getLocalStorageItem("__secret__")
		if (!username || !password) return undefined
		return {
			username: atob(username),
			password: atob(password)
		}
	} catch (err) {
		console.error(err)
		return undefined
	}
}

export const loginWithStoredCredentials = async (pb: PocketBase) => {
	try {
		const credentials = await getStoredUserCredentials()
		if (!credentials) return undefined
		const user = await login(pb, credentials.username, credentials.password)
		return user;
	} catch (err) {
		console.error(err)
		return undefined
	}
}


export const logout = async (pb: PocketBase) => {

	pb.authStore.clear();
	setLocalStorageItem("__id__", "")
	setLocalStorageItem("__secret__", "")

	const key = "pb_auth"

	// clear cookie
	document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}



export const createChat = async (pb: PocketBase, options: {
	title: string;
	description?: string;
	tags?: string[];
	members: string[];
}) => {
	if (!pb.authStore?.model) throw new Error("Not logged in")
	const response = await pb.collection("chats").create({
		title: options.title,
		description: options.description || "",
		tags: options.tags?.join(",") || "",
		createdBy: [pb.authStore.model?.id],
		subChats: [],
		members: [...options.members, pb.authStore.model?.id],
	})

	return response;
}


export const updateChat = async (pb: PocketBase, options: {
	chatId: string;
	body: any
}) => {
	if (!pb.authStore?.model) throw new Error("Not logged in")
	const response = await pb.collection("chats").update(options.chatId, options.body)
	return response;
}


export const createInvite = async (pb: PocketBase, options: {
	chatId: string;
	secret: string;
	acceptCode: string;
	user: string;
}) => {
	if (!pb.authStore?.model) throw new Error("Not logged in")
	const response = await pb.collection("invites").create({
		isAccepted: false,
		secret: options.secret,
		acceptCode: options.acceptCode,
		chat: options.chatId,
		createdBy: pb.authStore.model?.id,
		expiry: new Date(Date.now() + 1000 * 60 * 60 * 24 * 1),
		user: options.user
	})
	return response;
}

export const acceptInvite = async (pb: PocketBase, options: {
	inviteId: string;
}) => {
	if (!pb.authStore?.model) throw new Error("Not logged in")
	const response = await pb.collection("invites").update(options.inviteId, {
		isAccepted: true,
	})
	return response;
}

export interface IPbStoreState {
	data: RecordSubscription<Record> | undefined;
	error: Error | undefined;
	isReady: boolean;
	loading: boolean;
}

export const pbToStore = (item: RecordService, topic?: string) => {
	const store = writable<IPbStoreState>({
		data: undefined,
		error: undefined,
		loading: true,
		isReady: false
	})

	store.subscribe = (run, invalidate) => {
		let unsubscribe: Unsubscriber = () => { }

		const handler = async () => {
			run({
				data: undefined,
				error: undefined,
				loading: true,
				isReady: false
			})
			try {
				if (!topic) {
					unsubscribe = await item.subscribe("*", (data) => {
						run({
							data,
							error: undefined,
							isReady: true,
							loading: false
						})
					})
				} else {
					unsubscribe = await item.subscribe(topic || "", (data) => {
						run({
							data,
							error: undefined,
							isReady: true,
							loading: false
						})
					})
				}

			} catch (err: any) {
				console.error(err)
				run({
					data: undefined,
					error: err,
					isReady: false,
					loading: false
				})
			}
		}

		handler()

		return () => unsubscribe()
	}

	return store
}


export interface IPbStoreFetchState {
	data: RecordSubscription<Record> | undefined;
	fetchedData: any;
	error: Error | undefined;
	isReady: boolean;
	loading: boolean;
}


export const pbToFetchStore = (item: RecordService, topic: string, fetchData: (pb: PocketBase) => any) => {
	const store = writable<IPbStoreFetchState>({
		data: undefined,
		error: undefined,
		loading: true,
		isReady: false,
		fetchedData: undefined
	})
	let lastFetch: any = undefined
	const handler = async (run: any): Promise<Unsubscriber> => {
		run({
			data: undefined,
			error: undefined,
			loading: true,
			isReady: false,
			fetchedData: lastFetch
		})
		let unsubscribe: any = () => { }
		try {

			if(!lastFetch) {
				const fetchedData = await fetchData(pb)
				lastFetch = fetchedData
				run({
					data: undefined,
					error: undefined,
					isReady: true,
					loading: false,
					fetchedData
				})
			}
			if (!topic) {
				unsubscribe = await item.subscribe("*", async (data) => {

					const fetchedData = await fetchData(pb)
					run({
						data,
						error: undefined,
						isReady: true,
						loading: false,
						fetchedData
					})
				})
			} else {
				unsubscribe = await item.subscribe(topic || "", async (data) => {
					const fetchedData = await fetchData(pb)
					run({
						data,
						error: undefined,
						isReady: true,
						loading: false,
						fetchedData
					})
				})
			}


		} catch (err: any) {
			// console.error(err)
			run({
				data: undefined,
				error: err,
				isReady: false,
				loading: false,
				fetchedData: lastFetch
			})

			const removeOnChange = pb.authStore.onChange(() => {
				handler(run)
				removeOnChange()
			})
		}

		return unsubscribe
	}
	store.subscribe = (run, invalidate) => {
		let unsubscribe: Unsubscriber = () => { }
		handler(run).then((unsub) => {
			unsubscribe = unsub
		})
		return () => unsubscribe()
	}

	const unsub = store.subscribe((state) => {
		if (state.isReady) {
			unsub()
		}
	})

	return store
}


export const getUser = async (pb: PocketBase, userId: string, expand?: string) => {
	const user = await pb.collection("users").getOne(userId, {
		expand,
		$autoCancel: false
	})
	return user;
}

export const getChats = async (pb: PocketBase, userId: string) => {
	const chats = await pb.collection("users").getOne(userId, {
		expand: "chats",
		$autoCancel: false
	})
	return chats?.expand?.["chats"]?.sort((a: any, b: any) => {
		const aDate = new Date(a.created);
		const bDate = new Date(b.created);

		if (aDate > bDate) return -1;
		if (aDate < bDate) return 1;
		return 0;
	})
}

export const getChat = async (pb: PocketBase, chatId: string) => {
	const chat = await pb.collection("chats").getOne(chatId, {
		$autoCancel: false,
	})
	return chat;
}

export const getAdminChats = async (pb: PocketBase, userId: string) => {
	const res = await pb.collection("users").getOne(userId, {
		expand: "chats(createdBy).members",
		$autoCancel: false
	})
	return res?.expand?.["chats(createdBy)"]?.sort((a: any, b: any) => {
		const aDate = new Date(a.created);
		const bDate = new Date(b.created);

		if (aDate > bDate) return -1;
		if (aDate < bDate) return 1;
		return 0;
	})
}



export const updateUser = async (pb: PocketBase, userId: string, callback: (user: any) => any) => {
	const user = await getUser(pb, userId)
	if (!user) return undefined

	const newUser = await callback(user)

	const response = await pb.collection("users").update(userId, newUser)

	return response;

}

export const updateUserPassive = async (pb: PocketBase, userId: string, params: any) => {

	const response = await pb.collection("users").update(userId, params)

	return response;

}

export const getInviteRequests = async (pb: PocketBase) => {
	const requests = await pb.collection("inviteRequests").getFullList({
		expand: "createdBy,invite.user",
		sort: "-created",
	})
	return requests;
}

export const createInviteRequest = async (pb: PocketBase, contactData: any[]) => {
	const inviteRequest = await pb.collection("inviteRequests").create({
		contactData: JSON.stringify(contactData),
		createdBy: pb.authStore?.model?.id || "",
		isRequestAccepted: false,
	})

	return inviteRequest;
}

export const acceptInviteRequest = async (pb: PocketBase, inviteRequestId: string) => {
	const onboardUser = await onboardNewUser(pb);
	if (!onboardUser) {
		throw new Error("Error creating data to onboarding user")
	}


	const inviteRequest = await pb.collection("inviteRequests").update(inviteRequestId, {
		isRequestAccepted: true,
		invite: onboardUser.invite.id,
	})

	return inviteRequest;
}

export const newChat = async (
	pb: PocketBase,
	userId: string,
	options: {
		title: string;
		description: string;
	}
) => {


	const chat = await createChat(pb, {
		title: options.title,
		description: options.description,
		members: [pb.authStore?.model?.id || "", userId].filter((e) => e),
	});

	if (userId) {
		await updateUser(
			pb,
			userId || "",
			(user) => {
				user.chats = [...(user.chats || []), chat.id];
				return user
			}
		)
	}

	if (pb.authStore?.model?.id && pb.authStore.model?.isAdmin) {
		await updateUser(
			pb,
			pb.authStore?.model?.id || "",
			(user) => {
				user.chats = [...(user.chats || []), chat.id];
				return user
			}
		)
	}
}


export const isAdmin = (pb: PocketBase) => {
	return Boolean(pb.authStore.isValid && pb.authStore.model?.isAdmin)
}

export const isLoggedIn = (pb: PocketBase) => {
	return Boolean(pb.authStore.isValid && pb.authStore.model)
}




export const onboardNewUser = async (pb: PocketBase, isAdmin: boolean = false) => {
	try {
		const acceptCode = generateAcceptCode();
		const secret = uid(32);
		const user = await createAnonymousUser(pb, {
			isAdmin,
		});

		if (!user) {
			alert("Failed to create user");
			return;
		}

		const chat = await createChat(pb, {
			title: "Welcome Chat",
			description: "This is a welcome chat",
			tags: ["welcome"],
			members: [user.id],
		});


		const invite = await createInvite(pb, {
			chatId: chat.id,
			secret,
			acceptCode: acceptCode.toString(),
			user: user.id,
		});

		await updateUser(pb, user.id, (user) => ({
			...user,
			chats: [...user.chats, chat.id],
			invites: [...user.invites, invite.id],
		}));

		if (pb.authStore?.model?.id) {
			updateUser(pb, pb.authStore?.model?.id || "", (user) => ({
				...user,
				chats: [...user.chats, chat.id],
			}));
		}

		return {
			invite,
			chat,
			user,
			secret,
			acceptCode
		}
	} catch (err: any) {
		alert(err.message);
		console.error(err);
	}
}


export const getContacts = async (pb: PocketBase) => {
	if(!pb.authStore?.model?.id) return [];
	const chats = await getChats(pb, pb.authStore?.model?.id || "");
	const contacts: any[] = chats?.map((chat: any) => {
		return chat.members.filter((member: any) => member !== pb.authStore?.model?.id)
	}) || []
	const _contacts =  contacts.flat();

	const uniqueContacts = _contacts.filter((v, i, a) => a.indexOf(v) === i);
	return uniqueContacts;
}

export const pb = getPocketBaseInstance(import.meta.env.VITE_POCKETBASE_HOST);