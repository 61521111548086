import { overlayModal } from '@common/basicStores';
import type { ICalEvent } from '@common/calEvents';
import type { IDisposable } from '@common/commonTypes';
import type { Props } from '@common/svelteUtils';
import type { SvelteComponentTyped } from 'svelte';

export const openSettings = async (props: {
	[key: string]: any;
} = {}): Promise<IDisposable> => {

	const promise = import('./SettingsModal.svelte');
	overlayModal.open(promise, props);

	return {
		dispose: () => overlayModal.close(),
	}
}


export const openTextMessageModal = async (props: {
	[key: string]: any;
} = {}): Promise<IDisposable> => {

	const promise = import('./TextMessageModal.svelte');
	overlayModal.open(promise, props);

	return {
		dispose: () => overlayModal.close(),
	}
}


export const openAttachmentModal = async (props: {
	[key: string]: any;
} = {}): Promise<IDisposable> => {

	const promise = import('./AttachmentModal.svelte');
	overlayModal.open(promise, props);

	return {
		dispose: () => overlayModal.close(),
	}
}


export const openTorrentProcessModal = async (props: {
	[key: string]: any;
} = {}): Promise<IDisposable> => {

	const promise = import('./TorrentProcessModal.svelte');
	overlayModal.open(promise, props);

	return {
		dispose: () => overlayModal.close(),
	}
}


export const openUserList = async (props: {
	[key: string]: any;
} = {}) => {

	const promise = import('./UserListModal.svelte');
	overlayModal.open(promise, props);

	return {
		dispose: () => overlayModal.close(),
	}
}

export const openShowAnswerModal = async (props: {
	[key: string]: any;
} = {}) => {

	const promise = import('./ShowAnswerModal.svelte');
	overlayModal.open(promise, props);

	return {
		dispose: () => overlayModal.close(),
	}
}


export const openMediaCameraModal = async (props: {
	[key: string]: any;
} = {}): Promise<IDisposable> => {

	const promise = import('./MediaCameraModal.svelte');
	overlayModal.open(promise, props);

	return {
		dispose: () => overlayModal.close(),
	}
}

export const openModal = async <
	T extends typeof SvelteComponentTyped<any, any, any>
>(
	component: () => Promise<{ default: T }>,
	props: Props<T> = {} as any): Promise<IDisposable> => {

	const promise = component();
	overlayModal.open(promise, props);
	return {
		dispose: () => overlayModal.close(),
	}
}


export const openTimePickerModal = async (props: any) => {
	const promise = import('./TimePickerModal.svelte');
	overlayModal.open(promise, props);

	return {
		dispose: () => overlayModal.close(),
	}
}


export const openConnectionManagerModal = () => {
	return openModal(
		() => import("./ConnectionManagerModal.svelte"),
	)
}

export const openNewChatModal = (props: any) => {
	return openModal(
		() => import("./NewChatModal.svelte"),
		props
	)
}


export const openSelectEventModal = (props: {
	onSelect: (event: ICalEvent) => void;
}) => {
	return openModal(
		() => import("./SelectEventModal.svelte"),
		props
	)
}

export const openSelectContactModal = (props: {
	onSelect: (contact: any) => void;
}) => {
	return openModal(
		() => import("./SelectContactModal.svelte"),
		props
	)
}