
import { register, init, getLocaleFromNavigator, _, locale} from 'svelte-i18n';
import { derived } from 'svelte/store';
import SupportedLanguages from './../assets/supportedLanguages.json'


export interface ILanguage {
  language: string;
  nativeLanguage: string;
  code: string;
  flag: string;
  service: string;
  direction?: "ltr" | "rtl";
}

SupportedLanguages.forEach((lang) => {
  register(lang.code, () => import(`./../locales/${lang.code}/${lang.code}.json`));
});
init({
  fallbackLocale: 'en',
  initialLocale: getLocaleFromNavigator(),
});

export const supportedLanguages: ILanguage[] = SupportedLanguages as any;

export const localeLanguageDirection = derived(locale, ($locale) => {
  const language = supportedLanguages.find((lang) => lang.code === $locale);
  return language?.direction || "ltr";
})
export const isCurrentLanguageRTL = derived(localeLanguageDirection, ($localeLanguageDirection) => {
  return $localeLanguageDirection === "rtl";
})

export {
  _ as _
}