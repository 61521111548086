import { uid } from 'uid';
import { RpcProvider } from 'worker-rpc'
import type { IDisposable } from './commonTypes';


export interface ISharedLogicMethodResponse<T> {
	result: T;
	channel: RTCDataChannel
}

export interface IMessagePendingQueue<T = any> {
	id: string;
	pendingResponses: RTCDataChannel[];
	data: {
		channelLabel: string;
		data: T;
	}[]
}



export interface ISharedMethods {
	[key: string]: (...args: any[]) => Promise<any> | any;
}


export interface ISharedLogic<MethodsClient extends ISharedMethods = any, MethodsServer extends ISharedMethods = any> {
	rpcProviders: [RTCDataChannel, RpcProvider][];
	channels: RTCDataChannel[];
	disposeMessageListeners: IDisposable;
	messagePendingQueue: IMessagePendingQueue[];

	registerMethod<P1 extends keyof MethodsServer>(
		name: P1, method: (arg: Parameters<MethodsServer[P1]>[0], channel: RTCDataChannel) => Promise<ReturnType<MethodsServer[P1]>> | ReturnType<MethodsServer[P1]>
	): void;

	call<P1 extends keyof MethodsClient>(
		name: P1,
		arg: Parameters<MethodsClient[P1]>[0],
		channel?: RTCDataChannel | string
	): Promise<ReturnType<MethodsClient[P1]>>;

	setDataChannels: (channels: RTCDataChannel[]) => void;

}


export class SharedLogic<MethodsClient extends ISharedMethods, MethodsServer extends ISharedMethods> implements ISharedLogic<MethodsClient, MethodsServer>{

	public rpcProviders: [RTCDataChannel, RpcProvider][] = []
	public disposeMessageListeners: IDisposable;
	public messagePendingQueue: IMessagePendingQueue[] = [];

	public rpcHandlerMethods: MethodsServer = {} as any;
	public unregisterRpcHandlerMethods: IDisposable = {
		dispose: () => {}
	}
	constructor(
		public channels: RTCDataChannel[]
	) {

		this.rpcProviders = this.getRpcProviders(channels)
		this.disposeMessageListeners = this.setDataChannels(channels)
	}


	getRpcProviders(dataChannels: RTCDataChannel[]): [RTCDataChannel, RpcProvider][] {
		return dataChannels.map(channel => {
			const rpcProvider = new RpcProvider((message, transfer) => {
				console.log({dispatch: message})
				if (channel.readyState === 'open') {
					channel.send(JSON.stringify(message))
				} else {
					console.error('channel is not open', channel)
				}
			});

			return [channel, rpcProvider]
		})
	}

	get rpcProvider(): RpcProvider | undefined {
		return this.rpcProviders.find(([channel]) => {
			return channel.readyState === 'open'
		})?.[1]
	}

	findRpcProvider(channel: RTCDataChannel | string): [RTCDataChannel, RpcProvider] | undefined {
		return this.rpcProviders.find(([c]) => {
			if (typeof channel === 'string') {
				return c.label === channel
			}
			return c === channel
		})
	}

	setDataChannels(channels: RTCDataChannel[]) {
		this.disposeMessageListeners?.dispose?.()
		this.channels = channels
		this.rpcProviders = this.getRpcProviders(channels)
		const disposeMessageListeners: IDisposable[] = this.rpcProviders.map(([channel, provider]) => {
			const handler = (event: MessageEvent) => {
				if (typeof event.data !== 'string') return;
				const message = JSON.parse(event.data)
				console.log({receive: message})
				provider?.dispatch(message)
			}
			channel.addEventListener("message", handler)
			return {
				dispose: () => {
					channel.removeEventListener("message", handler)
				}
			}
		})

		this.disposeMessageListeners = {
			dispose: () => {
				disposeMessageListeners.forEach(d => d.dispose())
			}
		}

		if(Object.keys(this.rpcHandlerMethods).length > 0) {
			this.setRpcHandlerMethods(this.rpcHandlerMethods)
		}

		return this.disposeMessageListeners
	}


	registerMethod<P1 extends keyof MethodsServer>(
		name: P1, method: (arg: Parameters<MethodsServer[P1]>[0], channel: RTCDataChannel) => Promise<ReturnType<MethodsServer[P1]>> | ReturnType<MethodsServer[P1]>
	): IDisposable {
		const disposables: IDisposable[] = this.rpcProviders.map(([channel, provider]) => {
			const _method = async (arg: Parameters<MethodsServer[P1]>[0]) => {
				const data = await method(arg, channel)
				console.log({sendingBack: data})
				return data
			}
			provider?.registerRpcHandler(name as any, _method as any)
			return {
				dispose: () => {
					this.rpcProvider?.deregisterRpcHandler(name as any, _method as any)
				}
			}
		})

		return {
			dispose: () => {
				disposables.forEach(d => d.dispose())
			}
		}
	}

	async call<P1 extends keyof MethodsClient>(
		name: P1,
		arg: Parameters<MethodsClient[P1]>[0],
		channel?: RTCDataChannel | string
	): Promise<ReturnType<MethodsClient[P1]>> {
		const provider = channel ? this.findRpcProvider(channel)?.[1] : this.rpcProvider
		const result = await provider?.rpc<any, any>(name as any, arg)
		return result;
	}

	async callEach<P1 extends keyof MethodsClient>(
		name: P1,
		arg: Parameters<MethodsClient[P1]>[0],
		onResolve?: (result: ReturnType<MethodsClient[P1]>, channel: RTCDataChannel) => void,
	): Promise<[RTCDataChannel, ReturnType<MethodsClient[P1]>][]> {
		const providers = this.rpcProviders

		const results: [
			RTCDataChannel, ReturnType<MethodsClient[P1]>
		][] = await Promise.all(providers.map(async ([channel, provider]) => {
			const result: any = await provider?.rpc<any, any>(name as any, arg)
			onResolve?.(result, channel)
			return [channel, result]
		}))
		return results;
	}

	setRpcHandlerMethods(methods: MethodsServer): IDisposable {
		this.unregisterRpcHandlerMethods?.dispose?.()
		this.rpcHandlerMethods = methods
		const disposable: IDisposable[] = Object.entries(methods).map(([name, method]) => {
			return this.registerMethod(name, method)
		})

		this.unregisterRpcHandlerMethods = {
			dispose: () => {
				disposable.forEach(d => d.dispose())
			}
		}

		return this.unregisterRpcHandlerMethods
	}

}