import Writable from './advancedStore'

class Tag extends Writable {
  constructor({ value, label, ...options }, _parentThis) {
    super({ value, label, ...options })
    this._parentThis
  }

  remove() {
    this._parentThis.removeTag(this.value)
  }
}

class RecentTags extends Writable {
  constructor({ tags = [], ...options } = {}) {
    super({ tags, ...options })
  }

  addTag({ label, value, ...options } = {}) {
    const tag = this.findTag(value)

    if (tag) {
      this.removeTag(tag.value)
      this.addTag.call(this, { value, label, ...options })
    } else {
      this.tags = [new Tag({ label, value }, this), ...this.tags]
    }
  }
  removeTag(value) {
    this.tags = this.tags.filter(tag => tag.value !== value)
  }

  findTag(value) {
    return this.tags.find(tag => tag.value === value)
  }
}

const recentTags = new RecentTags()

export default recentTags
