
import type { DocTypeDescription } from '@syncedstore/core/types/doc';
import { uid } from 'uid';
import { SyncedStore } from './SyncedStore';
import { get } from 'svelte/store';
import { ExtendedWritable } from './ExtendedWritable';
import { ReactiveObject } from './ReactiveObject';
import type { Transaction, YArrayEvent } from 'yjs';
import { generateRandomId } from './roomUtils';
import { currentUser } from './basicStores';
import type { YArray, YMap } from 'yjs/dist/src/internals';
import type { IMediaSyncState } from './MediaSync';
import type { IAction } from '../overmind/common';
import { mimeTypeFromName } from './mimeTypes';
import { openTorrentProcessModal } from '@components/Modals/controllers';
import type { ISharedMethods } from './sharedLogic';
import { createInstance } from 'localforage';

localStorage.log = "false"
export interface IMember {
	id: string;
	displayName: string
	windowFocused: boolean
	isMuted: boolean
	isAudioOutputMuted: boolean
	isActive: boolean
	isScreenSharing: boolean
	isVoiceSharing: boolean
	isVideoSharing: boolean;
	isConnected: boolean
	isAdmin?: boolean
	isKicked?: boolean;
	isAccepted?: boolean;
	isRejected?: boolean;
	joinedNumber?: number;
	typing?: boolean;
	guestNumber?: number;
	joinedTimestamp: number;
}


export enum MessageTypes {
	text = "text",
	image = "image",
	video = "video",
	audio = "audio",
	file = "file",
	location = "location",
	system = "system",
	pdf = "pdf",
}

export type MessageHandlers = {
	[key in MessageTypes]?: any
}

export enum MessageActionTypes {
	button = "button",
}


export interface IMessageActionCallbackOptions {
	currentMessage: IMessage;
	removeMessage: () => void;
	editMessage: (callback: (message: Partial<IMessage>) => IMessage) => void;
	currentAction: IMessageAction;
}

export type IMessageActionCallback = (message: IMessage, options: IMessageActionCallbackOptions) => void;
export interface IMessageAction {
	id: string;
	type: MessageActionTypes;
	label: string;
	icon?: string;
	class?: string;
	callback: IMessageActionCallback;
}
export interface IMessage {
	value: any;
	metaData?: any
	timestamp: number;
	sender: string;
	id: string;
	type: MessageTypes
	actions?: IMessageAction[]
}


export interface IRoomStore extends DocTypeDescription {
	members: IMember[]
	messages: IMessage[]
}

export interface IMessageStore {
	messages: IMessage[]
	push?: (message: IMessage) => void
}


export enum CustomEventTypes {
	kickMember = "kickMember",
}

export interface ICustomEvent {
	type: CustomEventTypes
	data: any
	target: string[]
}


export enum SystemMessageTypes {


	APP_INTRO_1 = "APP_INTRO_1",
	APP_INTRO_2 = "APP_INTRO_2",
	SELECT_LANGUAGE = "SELECT_LANGUAGE",

	SHARE_ROOM = "SHARE_ROOM",

	AUDIO_TROUBLESHOOTER = "AUDIO_TROUBLESHOOTER",
	VIDEO_TROUBLESHOOTER = "VIDEO_TROUBLESHOOTER",
	SCREENSHARING_TROUBLESHOOTER = "SCREENSHARING_TROUBLESHOOTER",

	STARTED_SCREENSHARING = "STARTED_SCREENSHARING",
	STOPPED_SCREENSHARING = "STOPPED_SCREENSHARING",

	STARTED_VIDEOSHARING  = "STARTED_VIDEOSHARING",
	STOPPED_VIDEOSHARING = "STOPPED_VIDEOSHARING",

	SECRET_CODE_VALIDATOR = "SECRET_CODE_VALIDATOR",

	ENABLED_SPEAKER = "ENABLED_SPEAKER",
	DISABLED_SPEAKER = "DISABLED_SPEAKER",

	STARTED_VOICESHARING = "STARTED_VOICESHARING",
	STOPPED_VOICESHARING = "STOPPED_VOICESHARING",

	ONBOARDING_MESSAGE = "ONBOARDING_MESSAGE",

	JOIN_AUDIO_ROOM = "JOIN_AUDIO_ROOM",

	MEMBER_JOINED = "MEMBER_JOINED",
	MEMBER_LEFT = "MEMBER_LEFT",
	MEMBER_KICKED = "MEMBER_KICKED",
	MEMBER_REJECTED = "MEMBER_REJECTED",
	MEMBER_ACCEPTED = "MEMBER_ACCEPTED",
	EMBED = "EMBED",

	UNMUTE = "UNMUTE",
	STUN_FAILED = "STUN_FAILED",

	DEFAULT = "DEFAULT",

	SECRET_CODE_VALIDATOR_IMMEDIATE = "SECRET_CODE_VALIDATOR_IMMEDIATE"
}



export class RoomStore<RpcClient extends ISharedMethods = any, RpcServer extends ISharedMethods = any> extends SyncedStore<IRoomStore, RpcClient, RpcServer>{
	public _currentMember: IMember = this.generateMember();
	public messagesStore: ReactiveObject<IMessageStore> = new ReactiveObject<IMessageStore>({
		messages: [],
	});

	public systemMessages: ExtendedWritable<IMessage[]> = new ExtendedWritable<IMessage[]>([])

	public archivedMessagesStorage = createInstance({
		name: 'archivedMessages',
	});
	public archivedMessages: ExtendedWritable<IMessage[]> = new ExtendedWritable<IMessage[]>([])
	public destroyMessagesObserver: () => void = () => { }

	set currentMember(value: IMember) {
		this._currentMember = value;
	}

	get currentMember(): IMember {
		return this._currentMember;
	}

	public syncedMedia: YMap<IMediaSyncState>

	constructor(roomName: string, initialState: IRoomStore = {} as any, initializeStore: boolean = true) {
		const defaultState: IRoomStore = {
			members: [],
			messages: [],
			systemMessages: [],
		}

		super(roomName, {
			...defaultState,
			...initialState
		}, currentUser.get()?.id);
		this.userId = this.currentMember.id;

		this.currentMember = this.generateMember();
		this.coreStore.members.push(this.currentMember);
		this.syncedMedia = this.doc.getMap('syncedMedia');
		if(initializeStore){
			this.init();
		}
		// this.loadMessagesFromArchive();
	}

	getTrackId(userId: string, type: "video" | "screen"): string | undefined {
		const isCurrentUser = userId === this.currentMember.id;
		if(isCurrentUser){
			if(type === "video"){
				const videoStream = this.webrtcMediaStream.get().cameraStream;
				return videoStream?.getVideoTracks()[0].id;
			}else{
				const screenStream = this.webrtcMediaStream.get().screenShareStream;
				return screenStream?.getVideoTracks()[0].id;
			}
		}else{
			const remoteStreams = this.webrtcMediaStream.get().remoteStreams;
			const trackMap = Object.entries(this.webrtcMediaStream.trackMetaMap).filter(([key, value]) => {
				if(value.userId){
					return value.userId === userId 
						&& value.type === type
						&& remoteStreams.find(stream => {
							if(stream?.streams){
								const tracks = stream.streams.map(s => s.getVideoTracks()).flat();
								return tracks.find(t => t.id === value.track.id)
							}
						})
				}
				return false
			});
			if(trackMap.length > 0){
				return trackMap[0][0];
			}else{
				return undefined;
			}
		}
	}

	activateStreamView(
		userId: string,
		type: "video" | "screen"
	){

		const trackId = this.getTrackId(userId, type);

		if(trackId){
			this.webrtcMediaStream.proxy.activeStreamView = {
				trackId,
				userId,
				type
			}
		}

		this.webrtcMediaStream.proxy.isVideoSharingViewActive = true;
	}

	deactivateStreamView(){
		this.webrtcMediaStream.proxy.activeStreamView = undefined;
		this.webrtcMediaStream.proxy.isVideoSharingViewActive = false;
	}

	async updateCurrentMember(callback: (member: IMember) => void) {
		const index = this.coreStore.members.findIndex(member => member.id === this.currentMember.id);
		if (index !== -1) {
			callback(this.coreStore.members[index])
		}
	}

	getGuestNumber(memberId: string): number | undefined {
		const member = this.coreStore.members.find(member => member.id === memberId);
		if (!member || (member.guestNumber === undefined)) {
			return undefined
		}
		return member.guestNumber
	}

	getGuestId(memberId: string): string | undefined {
		const guestNumber = this.getGuestNumber(memberId);
		return guestNumber === undefined ? undefined : `Guest ${(guestNumber || 0) + 1}`
	}

	getMinimalGuestId(memberId: string): string | undefined {
		const guestNumber = this.getGuestNumber(memberId);
		return guestNumber === undefined ? undefined : `G${(guestNumber || 0) + 1}`
	}

	getCurrentMemberItem(): IMember | undefined {
		return this.coreStore.members.find(member => member.id === this.currentMember.id);
	}

	init() {

		this.connect();
		this.onClose = () => {
			const awarenessState = this.provider.awareness.getStates();

			let membersToRemove: string[] = [];
			this.coreStore.members.forEach(member => {
				if (!this.hasMemberInAwareness(member.id, awarenessState)) {
					membersToRemove.push(member.id)
				}
			})

			const unsub = this.store.subscribe(state => {
				membersToRemove.forEach(id => {
					const index = state.members.findIndex(member => member.id === id);
					if (index !== -1) {
						state.members.splice(index, 1)
					}
				})
				setTimeout(() => {
					unsub()
				})
			})
		}
		this.storeCurrentMemberInAwareness();

		this.destroyMessagesObserver = this.initMessagesStore();
		this.assignNumberToCurrentMember();
		this.assignAdminToFirstMember();
	}

	setMessages(messages: IMessage[]) {
		this.messagesStore.update(state => {
			state.messages = messages;
			return state
		})
	}


	public saveMessagesToArchive() {
		this.archivedMessagesStorage.setItem(
			this.roomName,
			[
				...this.archivedMessages.get(),
				...this.messagesStore.get().messages
			]
		)
	}

	public async loadMessagesFromArchive() {
		const messages: IMessage[] | null = await this.archivedMessagesStorage.getItem(this.roomName);
		if (messages) {
			// filter messages older than a week
			const filteredMessages =
				messages.filter(
					message => message.timestamp > Date.now() - 1000 * 60 * 60 * 24 * 7
				)
			this.archivedMessages.set(filteredMessages)
		}
	}

	sendFileMessage(file: File) {
		const fileId = uid();
		this.webrtcFileServer.addFile(fileId, file);
		const type = MessageTypes.file;
		if (file && type) {
			const message: IMessage = {
				sender: this.currentMember.id,
				value: {
					fileId,
					serverId: this.currentMember.id,
					fileName: file.name,
					fileType: file.type,
					fileSize: file.size,
				},
				timestamp: Date.now(),
				id: uid(),
				type: type as any,
			};
			this.messagesStore.get()?.push?.(message);
		}
	}

	async assignAdminToFirstMember() {
		await this.isSynced();
		const members = this.coreStore.members;

		if (members.length === 1) {
			members[0].isAdmin = true;
		} else if (members.length === 2 && !members.filter(member => member.isAdmin).length) {
			members.forEach(member => {
				member.isAdmin = true;
			})
		}
	}

	async assignNumberToCurrentMember() {
		try {
			await this.isSynced();
			const members = this.coreStore.members;

			members.forEach(member => {
				if (member.id === this.currentMember.id) {
					member.joinedNumber = members.length;
				}
			})
		} catch (err: any) {
			console.error(err)
		}
	}

	initMessagesStore() {
		const yArray = this.doc.getArray('messages');

		this.messagesStore.update(state => {
			state.push = (message: Partial<IMessage>) => {
				message.id = uid()
				yArray.push([message] as any);
			}
			return state
		})

		const observeHandler = (event: YArrayEvent<unknown>, transaction: Transaction) => {

			const events = yArray.toJSON();
			this.messagesStore.update(state => {
				state.messages = events;
				return state;
			})
		}

		yArray.observe(observeHandler);
		return () => {
			yArray.unobserve(observeHandler);
		}
	}

	hasMemberInAwareness(id: string, state: any): boolean {
		const awarenessState = state || this.provider.awareness.getStates();
		for (let [key, value] of awarenessState) {
			if (value?.currentMember?.id === id) {
				return true
			}
		}

		return false
	}


	getPeerIdFromAwareness(id: string): string | undefined {
		const userMeta = this.webrtcFileServer.get().userMeta.find(user => user.id === id)

		if (userMeta) {
			return userMeta.peerId
		}
		return undefined
	}


	sendMessage(message: Partial<IMessage>) {
		const newMessage: IMessage = {
			sender: this.currentMember.id,
			value: '',
			timestamp: Date.now(),
			id: uid(),
			type: MessageTypes.text,
			...message
		};

		const state = this.messagesStore.get();
		state?.push?.(newMessage);

		return newMessage
	}


	sendTextMessage(val: string) {
		const message: IMessage = {
			sender: this.currentMember.id,
			value: val,
			timestamp: Date.now(),
			id: uid(),
			type: MessageTypes.text,
		}

		this.sendMessage(message)
	}


	async sendMedia(files: File[]) {
		if (!files.length) return;
		for (let file of files) {
			const data: File = file;
			const mimeType = data.type || mimeTypeFromName(data.name);
			const type = mimeType.split("/")[0];
			const { TorrentFileShare } = await import("@common/TorrentFileShare");

			const torrentFileShare = new TorrentFileShare({
				data: data,
			});

			const closeModal = await openTorrentProcessModal();
			const torrent = await torrentFileShare.startSeeding();
			closeModal.dispose();
			const magnetUri = torrent?.magnetURI;

			if (type === MessageTypes.image || type === MessageTypes.video) {
				const message: IMessage = {
					sender: this.currentMember.id,
					value: magnetUri,
					timestamp: Date.now(),
					id: uid(),
					type: type as any,
				};
				this.sendMessage(message);
			}
		}
	}


	sendMediaWithFileServer(files: File[]) {
		if (!files.length) return;

		files.forEach((file) => {
			const fileId = uid();
			this.webrtcFileServer.addFile(fileId, file);
			if (file && file.type) {
				const message: IMessage = {
					sender: this.currentMember.id,
					value: {
						fileId,
						serverId: this.currentMember.id,
						fileName: file.name,
						fileType: file.type,
						fileSize: file.size,
					},
					timestamp: Date.now(),
					id: uid(),
					type: MessageTypes.file,
				};
				this.messagesStore.get().push?.(message);
			}
		})
	}



	generateMember(options: Partial<IMember> = {}): IMember {
		const globalId = currentUser.get()?.id;
		const globalName = currentUser.get()?.displayName;
		const member: IMember = {
			id: globalId || generateRandomId(),
			displayName: globalName || generateRandomId(),
			windowFocused: true,
			isMuted: false,
			isAudioOutputMuted: false,
			isActive: false,
			isScreenSharing: false,
			isConnected: false,
			isVideoSharing: false,
			isVoiceSharing: false,
			joinedTimestamp: Date.now(),
			...options
		}
		return member
	}

	storeCurrentMemberInAwareness() {
		this.provider.awareness.setLocalStateField('currentMember', {
			id: this.currentMember.id,
		});
	}

	getDisplayName(id: string): string {
		if (id === this.currentMember.id) {
			return this.currentMember.displayName || this.currentMember.id
		}
		const member = this.coreStore.members.find(member => member.id === id);
		return member?.displayName || id;
	}

	addSystemMessageView(type: SystemMessageTypes, additionalMessageMeta: Partial<IMessage> = {}) {
		this.addSystemMessage({
			id: type,
			...additionalMessageMeta
		})
	}

	addSystemMessage(message: Partial<IMessage>) {
		const newMessage: IMessage = {
			id: uid(),
			type: MessageTypes.system,
			timestamp: Date.now(),
			sender: "system",

			...message
		} as IMessage
		this.systemMessages.update(state => {
			state.push(newMessage);
			return state
		})
	}

	removeSystemMessage(id: string) {
		this.systemMessages.update(state => {
			const index = state.findIndex(message => message.id === id);
			if (index !== -1) {
				state.splice(index, 1)
			}
			return state
		})
	}

	editSystemMessage(id: string, callback: (message: IMessage) => IMessage) {
		this.systemMessages.update(state => {
			const index = state.findIndex(message => message.id === id);
			if (index !== -1) {
				state[index] = callback(state[index]);
			}
			return state
		})
	}

	editMessage(id: string, callback: (message: IMessage) => IMessage) {
		this.messagesStore.update(state => {
			const index = state.messages.findIndex(message => message.id === id);
			if (index !== -1) {
				state.messages[index] = callback(state.messages[index]);
			}
			return state
		})
	}


	getMergedMessages(...args: IMessage[][]): IMessage[] {
		const messages: IMessage[] = args.flat();
		messages.sort((a, b) => {
			return a.timestamp - b.timestamp
		})
		return messages;
	}

	generateActionCallbackOptions(action: IMessageAction, message: IMessage): IMessageActionCallbackOptions {

		return {
			currentAction: action,
			currentMessage: message,
			editMessage: (callback) => {
				if (message.sender === "system") {
					this.editSystemMessage(message.id, callback)
				} else {
					this.editMessage(message.id, callback)
				}
			},
			removeMessage: () => {
				if (message.sender === "system") {
					this.removeSystemMessage(message.id)
				}
			},

		} as IMessageActionCallbackOptions
	}
}