import type { IDisposable } from "./commonTypes";


export type RecordToListeners<T> = T extends Record<infer K, infer V> 
	? {
		[K in keyof T]: (opts: T[K]) => void
	}
	: never;



export class ExtendedEventTarget<
	T extends Record<string, any> = any
> extends EventTarget{

	listen<
		K extends keyof T
	>(
		type: K,
		listener: (ev: T[K]) => any,
		options?: boolean | AddEventListenerOptions
	): IDisposable{

		const handler = (ev: CustomEvent<T[K]>) => {
			listener(ev.detail);
		}

		this.addEventListener(type as any, handler as any, options)

		return {
			dispose: () => {
				this.removeEventListener(type as any, listener as any, options)
			}
		}
	}
	

	dispatch<
		K extends keyof T
	>(
		type: K,
		data: T[K]
	){
		this.dispatchEvent(new CustomEvent(type as any, {
			detail: data
		}))
	}


	addListeners(
		listeners: Partial<RecordToListeners<T>>,
		options?: boolean | AddEventListenerOptions
	): IDisposable{
		const disposables = Object.entries(listeners).map(([type, listener]) => {
			return this.listen(type as any, listener as any, options)
		})

		return {
			dispose: () => {
				disposables.forEach(d => d.dispose())
			}
		}
	}
}