import Writable from './advancedStore'
import _, { debounce } from 'lodash'
import { node } from './../helpers/helperClasses'
import { metaDataParseRE } from '../helpers/utils'
import { parseMetaData } from './../helpers/utils'
import { currentStore } from './../Layout/store';
// import store from './index';

class MetaTag {
  constructor({ value = '', states = [], icon } = {}) {
    this.value = value
    this.states = states
    this.icon = icon
  }

  addState({ value = '', states = [], icon = '' } = {}) {
    const tag = new MetaTag({
      value,
      states,
      icon,
    })
    this.states = [...this.states, tag]
  }
  removeState(value) {
    this.states = this.states.filter(item => item.value === value)
  }
  findState(value) {
    this.states.find(item => item.value === value)
  }

  get firstState() {
    return this.states[0] && this.states[0].value
  }
}

class MetaTags extends Writable {
  constructor(options = {}, key = 'asdasd') {
    options = {
      tags: [],
      ...options,
    }
    super(options, { key })
  }

  addTag(value, states = []) {
    const tag = {
      value,
      label: value,
      states: states,
    }
    this.tags = [...this.tags, tag]
    return tag
  }

  addState(tag, value) {
    value = value.trim()
    this.tags = this.tags.map(item => {
      if (item.value === tag) {
        return {
          ...item,
          states: [
            ...item.states,
            {
              value: value,
              label: value,
            },
          ],
        }
      } else {
        return item
      }
    })
  }

  getTree() {
    const [uid, data] = MetaTags.tagsToTree(this.tags)
    return {
      uid,
      data,
    }
  }

  setTags({ uid, data } = {}) {
    this.tags = MetaTags.treeToTags(uid, data)

  }

  findTag(value) {
    return this.tags.find(tag => tag.value === value)
  }

  getStates(value) {
    const tag = this.findTag(value)
    if (tag) {
      return tag.states
    }
    return null
  }

  static tagsToTree(tags = [], data = {}) {
    try {
      const mainNode = new node({
        type: 'page',
        title: 'Tags Schema',
      })

      for (let item of tags) {
        const nodeTemp = new node({
          type: 'node',
        })

        nodeTemp.string = item.value

        for (let state of item.states) {
          const subnode = new node({
            type: 'node',
          })
          subnode.string = !state.type
            ? state.value
            : `${state.value}:: ${state.type}`
          const icon = new node({
            type: 'node',
          })
          if (state.icon && state.icon !== 'undefined') {
            icon.string = metaDataParseRE.test(state.icon)
              ? state.icon
              : `\`${state.icon}\``
            icon.parent = subnode.uid
            subnode.children = [icon.uid]
          }
          subnode.parent = nodeTemp.uid
          data[icon.uid] = icon
          nodeTemp.children.push(subnode.uid)
          data[subnode.uid] = subnode
        }
        nodeTemp.parent = mainNode.uid

        mainNode.children.push(nodeTemp.uid)
        data[nodeTemp.uid] = nodeTemp
      }

      data[mainNode.uid] = mainNode

      return [mainNode.uid, data]
    } catch (err) {
      console.error(err)
      return data
    }
  }
  static getChildrenData(uid, data) {
    return (data[uid] && data[uid].children.map(id => data[id])) || []
  }

  static parseMetaInfo(str) {
    const metaData = parseMetaData(str)

    if (metaData && metaData.length) {
      const meta = metaData[0]

      if (meta && meta.value) {
        return {
          string: meta.name,
          type: meta.value.trim(),
        }
      }
    }
    return {
      string: str,
    }
  }

  static treeToTags(initialUid, data = {}, tags = []) {
    try {
      let config = {}
      const childrenData = MetaTags.getChildrenData(initialUid, data)
      for (let item of childrenData) {
        config[item.string] = {
          value: item.string,
          label: item.string,
          states: item.children
            .map(id => {
              let temp = data[id]
              if (!temp || !temp.string) return
              const metaData = MetaTags.parseMetaInfo(temp.string)
              return {
                value: metaData.string,
                label: metaData.string,
                type: metaData.type,
                icon: _.first(
                  temp.children.map(child => {
                    const item = data[child]

                    const re = /(\`)(.+)(\`)/

                    if (re.test(item.string)) {
                      return item.string.replace(/(\`)(.+)(\`)/, '$2')
                    }
                    return item.string
                  })
                ),
              }
            })
            .filter(item => item),
        }
      }

      return _.values(config)
    } catch (err) {
      console.error(err)
      return config
    }
  }


  findNestedTag(nestedTags = []){
    const mainTag = this.findTag(nestedTags[0])
    if(mainTag){
      const subTag = mainTag.states.find(state => state.value === nestedTags[1])
      if(subTag){
        return {
          mainTag,
          subTag,
        }
      }
    }
  }
}

const metaTags = new MetaTags();



let unsub


currentStore.subscribe(currentStore => {
  unsub?.();

  if(!currentStore) return;

  const _store = currentStore 

  const handler = (state) => {
    const nodes = state?.nodes || {};
    if(nodes){
      metaTags.setTags({
        uid: "tags",
        data: nodes,
      })
    }
  }
  _store.on("root_tags", handler);
  return  () => _store.off("root_tags", handler);
})

export default metaTags
