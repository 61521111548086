import type { Instance } from 'simple-peer'
import { uid } from 'uid';
import type { IDisposable } from './commonTypes';
import { ReactiveObject } from './ReactiveObject';
import type { ICustomDataChannel } from './SyncedStore';
import { append32BitNumberToArrayBuffer, divideFileIntoEvenParts, get32BitNumberFromArrayBuffer } from './utils';
import type { RoomStore } from './roomStore';
// import renderMedia from 'render-media'
// import stream from 'stream-browserify'

// console.log({renderMedia})


export interface IWebrtcFile {
	data: File;
	id: string;
	timestamp: number;
}

export interface IFileDownloadControls {
	abort: () => void
	pause: () => void
	resume: () => void

}

export interface IWebrtcFileServer {
	files: IWebrtcFile[];
	id: string;
	dataChannels: ICustomDataChannel[];
	userMeta: IUserMeta[];
	userId: string;
}

export enum FileServerMessageTypes {
	file = "file",
	fileProgress = "fileProgress",
	fileResponse = "fileResponse",
	fileComplete = "fileComplete",
	filePartComplete = "filePartComplete",
	fileStart = "fileStart",
	fileError = "fileError",
	fileRequest = "fileRequest",
	conformation = "conformation",
	userMeta = "userMeta",
	none = "none"

}

export interface IFileServerMessage {
	type: FileServerMessageTypes;
	data: any;
	id: string;
}

export interface IFileMeta {
	id: string;
	name: string;
	size: number;
	type: string;
}

export const binaryMessagePrefix = "binary-message:";

export interface IUserMeta {
	id: string;
	peerId: string;
	label?: string;
}


export class WebrtcFileServer extends ReactiveObject<IWebrtcFileServer>{
	public destroyServer: IDisposable
	constructor(options: Partial<IWebrtcFileServer>) {
		const defaultOptions: IWebrtcFileServer = {
			files: [],
			id: uid(),
			dataChannels: [],
			userMeta: [],
			userId: uid(),
		}

		super({
			...defaultOptions,
			...options,
		});

		this.destroyServer = this.startServer()
	};

	removeDataChannel(id: string) {
		this.update(state => {
			state.dataChannels = state.dataChannels.filter(({ id: dataChannelId }) => dataChannelId !== id)
			return state
		})
		this.restartServer()
	}

	startServer(): IDisposable {
		let receivedBuffer = []
		const { dataChannels } = this.get()
		const removeMessageListeners: IDisposable[] = dataChannels.map(({ channel, id }) => {
			const messageHandler = (event: MessageEvent) => {
				const data = event.data

				if (data instanceof ArrayBuffer) {

				} else {
					const message: IFileServerMessage = JSON.parse(event.data)
					switch (message.type) {
						case FileServerMessageTypes.file: {
							const { data: file } = message
							console.log({ file })
							break;
						}

						case FileServerMessageTypes.fileProgress:
							break;
						case FileServerMessageTypes.fileComplete:
							console.log({ complete: true })
							break;
						case FileServerMessageTypes.fileStart:
							break;

						case FileServerMessageTypes.userMeta: {
							let { data: userMeta } = message;
							userMeta.peerId = id;
							if (userMeta && userMeta.id && userMeta.peerId) {
								if (this.hasUserMeta(userMeta.id)) {
									this.removeUserMeta(userMeta.id)
								}
								this.addUserMeta(userMeta)
							}
						}
						case FileServerMessageTypes.fileError:
							break;
						case FileServerMessageTypes.fileRequest:
							const { fileId } = message.data
							this.sendFile(id, fileId)
							break;

						case FileServerMessageTypes.conformation:
							break;
					}

					if (message.type !== FileServerMessageTypes.conformation) {
						this.sendMessageConformation(id, message.id)
					}
				}
			}
			channel.addEventListener('message', messageHandler)
			return {
				dispose: () => {
					channel.removeEventListener('message', messageHandler)
				}
			}
		})

		return {
			dispose: () => {
				for (let removeMessageListener of removeMessageListeners) {
					removeMessageListener.dispose()
				}
			}
		}
	}

	addUserMeta(userMeta: { id: string; peerId: string }) {
		this.update(state => {
			state.userMeta.push(userMeta)
			return state
		})
	}

	hasUserMeta(id: string): boolean {
		const userMeta = this.get().userMeta
		return userMeta.find(({ id: userMetaId }) => userMetaId === id) !== undefined
	}

	removeUserMeta(id: string) {
		this.update(state => {
			state.userMeta = state.userMeta.filter(userMeta => userMeta.id !== id)
			return state
		})
	}

	async sendUserMeta(serverId: string) {
		await this.sendMessage(serverId, {
			type: FileServerMessageTypes.userMeta,
			data: {
				id: this.get().userId,
				peerId: serverId
			}
		})
	}


	async streamMedia(options: {
		fileId: string
		serverId: string
		element: HTMLMediaElement
		type: string
	}) {
		// const mediaSource = new MediaSource()
		// options.element.src = URL.createObjectURL(mediaSource)
		// await new Promise<undefined>((resolve, reject) => {


		// 	mediaSource.addEventListener('sourceopen', () => {
		// 		resolve(undefined)
		// 	})

		// 	mediaSource.addEventListener('error', (error) => {
		// 		reject(error)
		// 	})
		// });







		// const sourceBuffer = mediaSource.addSourceBuffer(`video/mp4; codecs="avc1.42E01E, mp4a.40.2"`)


		// const chunkBuffer:any[] = []
		// const chunkBufferCache: any = {}
		// let currentTargetChunkIndex = 0
		// let chunkBufferCacheIndex = 0


		// let readableStream;


		// let ri = 0

		// const tempFile = {
		// 	name: "test.mp4",
		// 	createReadStream: () => {
		// 		readableStream = new stream.Readable()

		// 		readableStream._read = (size: number) => {
		// 			setTimeout(() => {
		// 				if (chunkBuffer.length > 0) {
		// 					const chunk = chunkBuffer[ri]

		// 					if (chunk) {
		// 						ri++ 
		// 						console.log({ chunk2: chunk, size })
		// 						if(chunk){
		// 							const c=  new Uint8Array(chunk)
		// 							readableStream.push(c)
		// 						}
		// 					}
		// 				} else {
		// 					// readableStream.push(null)
		// 				}
		// 			}, 50)
		// 		}

		// 		return readableStream
		// 	}
		// }



		// const updateChunkBuffer = () => {
		// 	for(let i = currentTargetChunkIndex; i < chunkBufferCacheIndex; i++){
		// 		if(chunkBufferCache[i]){
		// 			appendChunkBuffer(chunkBufferCache[i])
		// 		}else{
		// 			break;
		// 		}
		// 	}
		// }

		// const appendChunkBuffer = (chunk: ArrayBufferLike) => {
		//     currentTargetChunkIndex++
		// 	// sourceBuffer.appendBuffer(chunk)
		// 	console.log({
		// 		chunk,
		// 		currentTargetChunkIndex,
		// 	})
		// 	chunkBuffer.push(chunk)
		// }

		// const file = await this.requestFile({
		// 	fileId: options.fileId,
		// 	serverId: options.serverId,

		// 	onProgress: ({chunk, chunkIndex}) => {	
		// 		console.log({
		// 			chunk,
		// 			chunkIndex
		// 		})
		// 		if(chunkIndex === currentTargetChunkIndex){
		// 			appendChunkBuffer(chunk)
		// 			updateChunkBuffer()
		// 		}else if(chunkIndex > currentTargetChunkIndex){
		// 			chunkBufferCache[chunkIndex] = chunk

		// 			if(chunkBufferCacheIndex < chunkIndex){
		// 				chunkBufferCacheIndex = chunkIndex
		// 			}
		// 		}
		// 	},
		// })

		// renderMedia.append(tempFile, options.element)

		// return file
	}


	sendMessageConformation(channelId: string, messageId: string) {
		this.sendMessage(channelId, {
			type: FileServerMessageTypes.conformation,
			data: {
				messageId
			},
			id: messageId
		})
	}

	getDataChannel(id: string): ICustomDataChannel | undefined {
		const { dataChannels } = this.get()
		return dataChannels.find(({ id: dataChannelId }) => dataChannelId === id)
	}

	getFile(id: string): IWebrtcFile | undefined {
		const { files } = this.get()
		return files.find(file => file.id === id)
	}


	sendMessage(serverId: string, message: Partial<IFileServerMessage>): Promise<IFileServerMessage> {
		return new Promise((res, rej) => {
			const dataChannel = this.getDataChannel(serverId)
			const _msg: IFileServerMessage = {
				id: uid(),
				data: {},
				type: FileServerMessageTypes.none,
				...message,
			}

			if (!dataChannel) return
			const { channel } = dataChannel

			const handleConformation = (event: MessageEvent) => {
				if (event.data instanceof ArrayBuffer) {
				} else {
					const message: IFileServerMessage = JSON.parse(event.data)
					if (message.type === FileServerMessageTypes.conformation && message.data.id === _msg.id) {
						channel.removeEventListener('message', handleConformation)
						res(_msg)
					}
				}
			}
			channel.addEventListener("message", handleConformation)
			channel.send(JSON.stringify(_msg))
		})
	}


	sendFileMeta(serverId: string, file: IWebrtcFile, messageId?: string) {
		this.sendMessage(serverId, {
			type: FileServerMessageTypes.fileResponse,
			data: {
				name: file.data.name,
				size: file.data.size,
				type: file.data.type,
				id: file.id,
			},
			id: messageId
		})
	}

	sendFileComplete(id: string, fileId: string) {
		this.sendMessage(id, {
			type: FileServerMessageTypes.fileComplete,
			data: {
				id: fileId,
			}
		})
	}

	sendFileNotFound(serverId: string) {
		this.sendMessage(serverId, {
			type: FileServerMessageTypes.fileResponse,
			data: {
				error: {
					message: "File not found",
					code: 404,
				},
			}
		})
	}



	addIndexToArrayBuffer(arrayBuffer: ArrayBuffer, index: number) {


	}



	async sendFile(serverId: string, fileId: string) {
		const dataChannel = this.getDataChannel(serverId)
		if (!dataChannel) return
		const { channel } = dataChannel
		const { data: file } = this.getFile(fileId) || { data: undefined }
		if (!file) {
			this.sendFileNotFound(serverId)
			return;
		}

		this.sendFileMeta(serverId, {
			data: file,
			id: fileId,
			timestamp: Date.now()
		})

		const chunkSize = 62 * 1024;
		const fileReader = new FileReader();

		let offset = 0;
		const allChannels = [channel, ...(dataChannel.supportingChannels || [])]
		const allChannelsLength = allChannels.length
		let currentChannelIndex = 0
		let currentChannel = allChannels[0]
		let currentChunkIndex: number = 0;



		const onLoad = (e: ProgressEvent<FileReader>) => {

			if (e.target?.result instanceof ArrayBuffer) {
				if (currentChannel.bufferedAmount > 5 * 1024 * 1024) {
					currentChannel.onbufferedamountlow = () => {
						currentChannel.onbufferedamountlow = null;
						onLoad(e);
					};
					return;
				} else {
					const newArrayBuffer = append32BitNumberToArrayBuffer(currentChunkIndex, e.target.result)
					currentChannel.send(newArrayBuffer);
					offset += e.target.result.byteLength;
					currentChunkIndex++
				}
				if (offset < file.size) {
					readSlice(offset);
				} else {

					allChannels.forEach(channel => {
						channel.send(JSON.stringify({
							type: FileServerMessageTypes.filePartComplete,
							data: {
								id: fileId,
							}
						}))
					})
					this.sendFileComplete(serverId, fileId)
				}

				if (currentChannelIndex < allChannelsLength - 1) {
					currentChannelIndex++
					currentChannel = allChannels[currentChannelIndex]
				} else {
					currentChannelIndex = 0
					currentChannel = allChannels[currentChannelIndex]
				}
			}
		}

		fileReader.addEventListener('error', error => console.error('Error reading file:', error));
		fileReader.addEventListener('abort', event => console.log('File reading aborted:', event));
		fileReader.addEventListener('load', e => {
			onLoad(e)
		});
		const readSlice = (o: any) => {
			const slice = file.slice(offset, o + chunkSize);
			fileReader.readAsArrayBuffer(slice)
		};
		readSlice(0);
	}


	restartServer() {
		this.destroyServer.dispose()
		this.destroyServer = this.startServer()
	}


	destroy(): void {
		this.destroyServer.dispose()
	}


	addDataChannel(dataChannel: ICustomDataChannel) {

		console.log("adding data channel")
		this.update(state => {
			state.dataChannels.push(dataChannel)
			return state
		})
		this.restartServer()
	}


	addFile(id: string, file: File) {
		this.update(state => {
			state.files.push({
				data: file,
				id,
				timestamp: Date.now()
			})
			return state
		})
	}

	removeFile(id: string) {
		this.update(state => {
			state.files = state.files.filter(file => file.id !== id)
			return state
		})
	}

	waitForMessage({
		channel,
		messageType,
		messageId,
		callback,
		timeout
	}: {
		channel: RTCDataChannel,
		messageType: FileServerMessageTypes,
		messageId?: string,
		timeout?: number,
		callback?: (message: IFileServerMessage) => boolean
	}): Promise<IFileServerMessage> {
		return new Promise((res, rej) => {
			const messageHandler = (event: MessageEvent) => {
				if (event.data instanceof ArrayBuffer) {
				} else {
					const message: IFileServerMessage = JSON.parse(event.data)
					if (message.type === messageType) {

						if (messageId && message.data.id !== messageId) return
						if (callback) {
							if (callback(message)) {
								res(message)
								channel.removeEventListener('message', messageHandler)
							} else {
								return
							}
						}
						channel.removeEventListener('message', messageHandler)
						res(message)
					}
				}
			}

			if (timeout !== undefined) {
				setTimeout(() => {
					channel.removeEventListener('message', messageHandler)
					rej(new Error('Timeout'))
				}, timeout)
			}
			channel.addEventListener("message", messageHandler)
		})
	}


	async requestFile({
		serverId,
		fileId,
		onProgress,
		onComplete,
		onStart,
		onError,
	}: {
		serverId: string,
		fileId: string,
		onProgress?: (stats: {
			progress: number,
			receivedSize: number,
			totalSize: number,
			receivedChunks: any[]
			chunk: ArrayBufferLike,
			chunkIndex: number
		}) => void,
		onComplete?: (file: File) => void,
		onStart?: (fileMeta: IFileMeta, options: IFileDownloadControls) => void,
		onError?: (error: Error) => void,
	}): Promise<File | undefined> {

		const dataChannel = this.getDataChannel(serverId)
		if (!dataChannel) return
		const messageId = uid()
		this.sendMessage(serverId, {
			type: FileServerMessageTypes.fileRequest,
			data: {
				fileId,
			},
			id: messageId,
		})
		const response = await this.waitForMessage({
			channel: dataChannel.channel,
			messageType: FileServerMessageTypes.fileResponse,
			callback: (message) => {
				if (message.data.id === fileId) {
					return true
				} else {
					return false
				}
			}
		})


		const { data } = response
		if (data.error) {
			throw new Error(data.error.message)
		}

		onStart && onStart(data, {
			abort: () => {
				onError && onError(new Error('Aborted'))
			},
			pause: () => {

			},
			resume: () => {

			}
		})
		let receivedSize = 0;

		const allChannels = [dataChannel.channel, ...(dataChannel.supportingChannels || [])]

		const listenForFilePart = (channel: RTCDataChannel): Promise<any[]> => {
			return new Promise((res, rej) => {
				const receivedChunks: ({
					chunk: ArrayBuffer,
					index: number,
				})[] = [];
				const receivedChunkHandler = (event: MessageEvent) => {
					if (event.data instanceof ArrayBuffer) {
						const index = get32BitNumberFromArrayBuffer(event.data)
						const chunk = event.data.slice(0, event.data.byteLength - 4)
						receivedChunks.push({
							index,
							chunk
						})
						receivedSize += chunk.byteLength;
						onProgress && onProgress({
							progress: receivedSize / data.size,
							receivedSize,
							totalSize: data.size,
							receivedChunks,
							chunk,
							chunkIndex: index
						})
					} else {
						const msg = JSON.parse(event.data)
						if (msg.type === FileServerMessageTypes.filePartComplete) {
							res(receivedChunks)
						}
					}
				}
				channel.addEventListener("message", receivedChunkHandler)
			})
		}

		const fileCompetePromise = this.waitForMessage({
			channel: dataChannel.channel,
			messageType: FileServerMessageTypes.fileComplete,
			callback: (message) => message.data.id === fileId ? true : false
		})

		const promises = allChannels.map(listenForFilePart)
		let receivedChunks: any[][] = await Promise.all(promises)

		await fileCompetePromise;

		const file = new File([
			...receivedChunks
				.flat()
				.sort((a, b) => a.index - b.index)
				.map(({ chunk }) => chunk)
		], data.name, {
			type: data.type,
		})
		onComplete && onComplete(file)
		return file
	}
}


