import { initializeApp } from "firebase/app";
import { isMobileDevice } from "./isMobileDevice";


const firebaseConfig = {
	apiKey: "AIzaSyDGCsfuWxDDqY3eZm4QySQNh3Oj2LJpqhE",
	authDomain: "chatubz-191c5.firebaseapp.com",
	projectId: "chatubz-191c5",
	storageBucket: "chatubz-191c5.appspot.com",
	messagingSenderId: "431086812920",
	appId: "1:431086812920:web:72c94ba8ede56e5fcd450d",
	measurementId: "G-6M38BTLDNQ"
};
export const firebaseApp = initializeApp(firebaseConfig);

export const vapidKey = "BLU5oH6fgpTVmx1dIJ34hN55IucSkScMcz0UpAS8uI9hJqWeAdYw6TVvIfxhquLfovqdtS1pBxxmKkKyeMg5SeA"
export const NOTIFICATIONS_API = "http://localhost:4221"

export const formatFirebaseMessagingId = (id: string) => {
	const prefix = isMobileDevice() ? "mobile" : "web";
	return `${prefix}__${id}`;
}