<script lang="ts">
	import { connectionManager as defaultConnectionManager } from "@common/basicStores";
	import type { ConnectionManager } from "@common/ConnectionManager";
	import { RemoteController } from "@common/RemoteController";
	import { RoomStore } from "@common/roomStore";
	import { openConnectionManagerModal } from "@components/Modals/controllers";
	import { onDestroy, onMount } from "svelte";

	export let connectionManager: ConnectionManager = defaultConnectionManager;
	export let sync: boolean = true;
	export let syncListFilter: ((listId: string) => boolean) | undefined = undefined;


	let memberStore: RoomStore["store"] | undefined;
	let destroyRealtimeSync: (() => void) | undefined;
	let syncing: boolean = false;

	onMount(async () => {
		await connectionManager.load();


		if ($connectionManager.connected && !$connectionManager.roomStore) {
			await connectionManager.reconnect(RoomStore);

			const roomStore = $connectionManager.roomStore;

			if (roomStore) {
				const remoteController = new RemoteController(roomStore);
				connectionManager.setRemoteController(
					roomStore,
					remoteController
				);
			}

			$defaultConnectionManager = $connectionManager;
		}

		memberStore = $connectionManager.roomStore?.store;
	});

	onDestroy(() => {
		const roomStore = $connectionManager.roomStore;
		if (roomStore) {
			roomStore.destroy();
		}
		destroyRealtimeSync?.(); 
	});

	const handleSyncData = async () => {
		if (syncing) return;
		try {
			syncing = true;
			if (!$connectionManager.synced) {
				await connectionManager.syncList(syncListFilter);
				$connectionManager.syncTime = Date.now();
				await connectionManager.save();
				destroyRealtimeSync?.();
				destroyRealtimeSync =
					await connectionManager.enableDataRealtimeSync();
			}
			syncing = false;
		} catch (err) {
			console.error(err);
			syncing = false;
		}
	};

	$: isRemoteDeviceConnected =
		memberStore && $memberStore ? $memberStore.members.length > 1 : false;

	$: if (
		sync &&
		isRemoteDeviceConnected &&
		$connectionManager.ready &&
		!$connectionManager.syncing &&
		!$connectionManager.synced
	) {
		handleSyncData();
	}
</script>
