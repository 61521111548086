import {marked} from 'marked'

function isList(string = ``) {
  if (!string) return

  let tokenizedMarkdown = tokenizeMarkdown(string)

  return tokenizedMarkdown.some(item => item.type === 'list')
}

function tokenizeMarkdown(string) {
  if (!string) {
    throw new Error('Tokenize Markdown: passed param must be a string')
  }

  return marked.lexer(string)
}

function parseList(string = ``) {
  try {
    if (!string || !isList(string)) return

    const tokenizedMarkdown = marked.lexer(string)
    const list = tokenizedMarkdown.find(item => item.type === 'list')

    return parseMainList(list)
  } catch (err) {
    throw err
  }
}

function parseMainList(list, array = []) {
  try {
    const items = list.items || []

    items.forEach(item => {
      let data = parseListItem(item)
      array.push({
        ...data,
      })
      if (data && data.list) {
        parseMainList(data.list, data.children)
      }
    })
    return array
  } catch (err) {
    throw err
  }
}

function parseListItem(listItem) {
  try {
    const text = getTextInListItem(listItem) || ''
    const list = getListInListItem(listItem)

    return {
      text,
      list,
      children: [],
    }
  } catch (err) {
    throw err
  }
}

function getListInListItem(listItem) {
  try {
    return listItem.tokens.find(item => item.type === 'list')
  } catch (err) {
    throw err
  }
}

function getTextInListItem(listItem) {
  try {
    const result = listItem.tokens.find(
      item => item.type === 'text' || item.type === 'code'
    )

    if (result && result.type === 'code') {
      return result && result.text && result.text !== 'undefined'
        ? `\`\`\`${result.lang}\r\n${result.text}\r\n\`\`\``
        : ''
    }
    return result && result.text && result.text !== 'undefined'
      ? result.text
      : ''
  } catch (err) {
    throw err
  }
}

function stringSplice(str, index, count, add) {
  // We cannot pass negative indexes directly to the 2nd slicing operation.
  if (index < 0) {
    index = str.length + index
    if (index < 0) {
      index = 0
    }
  }

  return str.slice(0, index) + (add || '') + str.slice(index + count)
}

const transformToMarkdownList = str => {
  const arr = str.split(/\n\r?/)
  const newStr = arr
    .map(str => {
      const firstCharacterMatch = str.match(/\S/)
      if (!firstCharacterMatch || firstCharacterMatch.index === -1) {
        return stringSplice(str, str.length, 0, '- ')
      } else {
        return stringSplice(str, firstCharacterMatch.index, 0, '- ')
      }
    })
    .join('\n')

  return newStr
}

const canTransformToMarkdownList = str => {
  const match = str.match(/\n/g)

  if (match && match.length >= 2) {
    return true
  }

  return false
}

export default parseList

export {
  parseList,
  isList,
  transformToMarkdownList,
  canTransformToMarkdownList,
}
