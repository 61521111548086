<script lang="ts">
  import { dictateModeActive } from "@common/uiStores";
  import Mic from "@icons/Mic.svelte";

	export let value: string;
	export let placeholder: string;
  export let languageDirection: "rtl" | "ltr" = "ltr"; 
  export let disabled: boolean = false;
  let className: string = ""

  export {
    className as class
  }
</script>

<div class="h-full w-full relative">

  <input
    on:keydown
    placeholder={placeholder}
    bind:value
    type="text"
    style:direction={languageDirection}
    class={className}
    disabled={disabled}
    class:disabled={disabled}
  />

  {#if $dictateModeActive}
    <div class="absolute top-0 right-0 bottom-0 flex items-center justify-center">
      <button
        class="btn btn-ghost btn-sm"
        on:click={() => {
          dictateModeActive.set(false);
        }}
      >
        <span class="h-4 w-4 text-error">
          <Mic />
        </span>
      </button>
    </div>
  {/if}
</div>

<style lang="postcss">
  input{
    @apply input w-full h-full rounded-xl bg-neutral border border-base-content border-opacity-20;
  }
</style>