import localforage from 'localforage';
import {uid} from 'uid';

export interface IDocMock {
	_id: string;
	[key: string]: any;
}


export class PouchDBMock {

	public databaseName: string;
	public store: LocalForage;
	constructor(databaseName: string) {
		this.databaseName = databaseName;
		this.store = localforage.createInstance({
			name: this.databaseName
		});
	}

	public async get(key: string): Promise<any> {
		const item = await this.store.getItem(key);
		if (!item && key.startsWith("_local")) {
			return {
				_id: uid(),
			}
		}
		return item
	}

	public async set(key: string, value: any): Promise<void> {
		return this.store.setItem(key, value);
	}

	public async remove(key: string): Promise<void> {
		return this.store.removeItem(key);
	}

	public async allDocs(): Promise<any> {
		const keys = await this.store.keys();

		const docs = await Promise.all(keys.map(async (key) => {
			const doc = await this.store.getItem(key);
			return {
				_id: key,
				doc: doc
			};
		}));

		return docs.filter(doc => doc?.doc);
	}

	public async put(doc: IDocMock): Promise<IDocMock> {
		return this.store.setItem(doc._id, doc);
	}
}