<script lang="ts">
  import { SvelteToast } from "@zerodevx/svelte-toast";
</script>

<div class="wrap">
  <SvelteToast />
</div>

<style>
  .wrap {
    --toastContainerTop: 0.5rem;
    --toastContainerRight: 0.5rem;
    --toastContainerBottom: auto;
    --toastContainerLeft: 0.5rem;
    --toastWidth: 100%;
    --toastMinHeight: 2rem;
    --toastPadding: 0 0.5rem;
	--toastBarHeight: 0;
	--toastBorder: 0;
	--toastBorderRadius: 10px;
	--toastBackground: hsla(var(--nf)/80%);
    font-size: 0.875rem;
  }
  @media (min-width: 40rem) {
    .wrap {
      --toastContainerRight: auto;
      --toastContainerLeft: calc(50vw - 20rem);
      --toastWidth: 40rem;
    }
  }
</style>
