

export const mimeTypeFromName = (value: string) => {
	const extension = value.split('.').pop();
	if (extension) {
		switch(extension) {
			case 'mkv':
				return 'video/mkv';
		}
	}
	return "unknown/unknown"
}