import { ReactiveObject } from "@common/ReactiveObject";
import type { Instance as SimplePeerInstance, } from 'simple-peer'
import type { WebrtcProvider } from 'y-webrtc'
import { DEFAULT_FACING_MODE } from "../constants";
import { globalMedia, selectedDevices } from './basicStores';


const isFacingModeSupported = (): boolean => {
	const supports = navigator.mediaDevices.getSupportedConstraints();
	if (!supports['facingMode']) {
		return false;
	}
	return true;
}

export interface IRemoteStream {
	streams: MediaStream[];
	id: string;
	userName: string;
	isMuted: boolean;
}

export interface IActiveStreamView {
	trackId: string;
	userId: string;
	type: "video" | "screen";
}

export interface IWebRTCMediaStreamOptions {
	stream?: MediaStream;
	cameraStream?: MediaStream;
	voiceStream?: MediaStream;
	screenShareStream?: MediaStream;
	provider?: WebrtcProvider;
	isScreenSharing?: boolean;
	isActive?: boolean;
	isVideoShareActive: boolean;
	isVoiceShareActive: boolean;
	isMuted?: boolean;
	waitingForStream?: boolean;
	waitingForPeers?: boolean;
	remoteStreams: IRemoteStream[],
	isVideoSharingViewActive: boolean;
	isScreenSharingViewActive: boolean;
	audioOutputMuted: boolean;
	audioInputMuted: boolean;
	joiningInProgress: boolean;
	viewLayout?: "multi" | "single";

	activeStreamView?: IActiveStreamView
	isEarpieceActive?: boolean;
}


export interface IWebrtcPeer {
	id: string;
	peer: SimplePeerInstance & {
		_remoteStreams: MediaStream[]
		_pc: RTCPeerConnection
	};
}

interface ITrackMetaMap{
	[trackId: string]: {
		userId?: string;
		type?: "video" | "screen";
		timestamp: number;
		track: MediaStreamTrack;
		stream: MediaStream;
	}
}

export class WebrtcMediaStream extends ReactiveObject<IWebRTCMediaStreamOptions> {
	public destroyListeners: () => void = () => { };
	public destroyProviderListeners: () => void = () => { };

	public trackMetaMap: ITrackMetaMap = {};


	constructor(options: IWebRTCMediaStreamOptions) {
		super({
			...options,
			isActive: false,
			isMuted: false,
			isScreenSharing: false,
			remoteStreams: [],
		})

		if (this.get().provider) {
			this.destroyProviderListeners = this.initProviderListeners()
		}
	}

	muteAudioOutput(isMuted: boolean) {
		this.proxy.audioOutputMuted = isMuted
	}

	muteAudioInput(isMuted: boolean) {
		const stream = this.get().stream || this.get().voiceStream
		if (stream) {
			stream.getAudioTracks().forEach(track => {
				track.enabled = !isMuted
			})
			this.proxy.audioInputMuted = isMuted
		}
	}

	isEnumerateDevicesSupported() {
		return navigator.mediaDevices && navigator.mediaDevices.enumerateDevices
	}

	getMediaDevices(): Promise<MediaDeviceInfo[]> {
		return new Promise((resolve, reject) => {
			if (this.isEnumerateDevicesSupported()) {
				navigator.mediaDevices.enumerateDevices().then(devices => {
					resolve(devices)
				}).catch(err => {
					reject(err)
				})
			} else {
				reject("enumerateDevices() not supported.")
			}
		})
	}

	getAudioInputDevices() {
		return this.getMediaDevices().then(devices => {
			return devices.filter(device => device.kind === "audioinput")
		})
	}

	getAudioOutputDevices() {
		return this.getMediaDevices().then(devices => {
			return devices.filter(device => device.kind === "audiooutput")
		})
	}

	getVideoInputDevices() {
		return this.getMediaDevices().then(devices => {
			return devices.filter(device => device.kind === "videoinput")
		})
	}

	async setEarpiece(isEarpieceActive: boolean) {

		const audioOutputDevices = await this.getAudioInputDevices()
		const headsetEarpiece = audioOutputDevices.find(
			device => device.label.toLowerCase().includes("earpiece")
		);

		const speakerphone = audioOutputDevices.find(
			device => device.label.toLowerCase().includes("speakerphone")
		) || audioOutputDevices.find(
			device => device.deviceId === "default"
		)


		if (isEarpieceActive) {
			if (headsetEarpiece) {
				selectedDevices.update(state => {
					state.audioInput = headsetEarpiece
					return state
				})
				this.proxy.isEarpieceActive = true
			} else {
				this.proxy.isEarpieceActive = false
				if (speakerphone) {
					selectedDevices.update(state => {
						state.audioInput = speakerphone
						return state
					})
				} else {
					selectedDevices.update(state => {
						state.audioInput = audioOutputDevices[0]
						return state
					})
				}
			}
		} else {
			if (speakerphone) {
				selectedDevices.update(state => {
					state.audioInput = speakerphone
					return state
				})
			} else {
				selectedDevices.update(state => {
					state.audioInput = audioOutputDevices[0]
					return state
				})
			}
			this.proxy.isEarpieceActive = false
		}
	}


	async toggleEarpiece() {
		const isEarpieceActive = this.get().isEarpieceActive
		if (isEarpieceActive) {
			await this.setEarpiece(false)
		} else {
			await this.setEarpiece(true)
		}
	}

	sendStreamsToAdded(added: string[]) {
		const peers = this.getPeers()

		const state = this.get()
		for (let { id, peer } of peers) {
			const senderMap: Map<MediaStreamTrack, Map<MediaStream, RTCRtpSender>> = (peer as any)._senderMap
			if (added.includes(id)) {
				const stream = state.stream;
				const videoStream = state.screenShareStream || state.cameraStream
				if (videoStream) {
					const videoTracks = videoStream.getVideoTracks()
					const currentStreamVideoTracks = stream?.getVideoTracks()
					if (videoTracks.length > 0) {
						videoTracks.forEach(track => {
							if (!currentStreamVideoTracks?.includes(track)) {
								videoStream.addTrack(track)
							}
						})
					}
				}

				let allTracksInStream = stream?.getTracks();

				if (!allTracksInStream?.length) {
					return
				}

				for (let [track, streamMap] of senderMap) {
					const tracks = allTracksInStream
					if (tracks?.includes(track)) {
						allTracksInStream = allTracksInStream?.filter(item => item !== track)
					}
				}

				if (allTracksInStream?.length) {
					const _stream = new MediaStream(allTracksInStream)
					peer.addStream(_stream)
				}
			}
		}
	}


	trackAlreadyAdded(track: MediaStreamTrack, peer: SimplePeerInstance) {
		const senderMap: Map<MediaStreamTrack, Map<MediaStream, RTCRtpSender>> = (peer as any)._senderMap
		for (let [t, streamMap] of senderMap) {
			if (t === track) {
				return true
			}
		}
		return false
	}

	initProviderListeners() {

		const provider = this.get().provider

		const onPeers = (peerEvent: any) => {
			this.refreshCurrentRemoteStreams()
			this.destroyListeners?.();
			this.handleStreamEvents();

			const added = peerEvent.added
			if (added?.length > 0) {
				this.sendStreamsToAdded(added)
			}
		}

		provider?.on("peers", onPeers)

		return () => {
			provider?.off("peers", onPeers)
		}
	}

	getAudioInstance(stream: MediaStream) {
		// using the audio api to play the stream
		const audio = new Audio();
		audio.srcObject = stream;
		return audio;
	}

	async startMediaStream(localStream: MediaStream) {
		if (this.proxy.isActive) return

		if (this.proxy.joiningInProgress) return
		this.proxy.joiningInProgress = true

		try {
			if (!this.hasPeers()) {
				this.proxy.waitingForPeers = true
				await this.waitForPeers()
				this.proxy.waitingForPeers = false
			} else {
				if (this.proxy.waitingForPeers) {
					this.proxy.waitingForPeers = false
				}
			}

			this.destroyListeners?.()
			this.handleStreamEvents()

			this.refreshCurrentRemoteStreams();
			const stream = localStream;
			this.proxy.stream = stream
			this.addStreamToAllPeers(stream);
			this.proxy.isActive = true;
		} catch (err) {
			console.error(err)
		}
		this.proxy.joiningInProgress = false
	}

	waitForStream() {
		const peers = this.getPeers();
		return new Promise((res, rej) => {
			peers.forEach(({ peer }) => {
				const handler = () => {
					res(true)
				}
				peer.on("stream", handler)
			})
		})
	}

	updateProvider(provider: WebrtcProvider) {
		this.destroyProviderListeners()
		this.update(state => {
			state.provider = provider
			return state
		})
		this.destroyProviderListeners = this.initProviderListeners()
	}


	hasPeers(): boolean {
		return !!this.getPeers().length
	}


	hasStreams(): boolean {
		return !!this.getCurrentRemoteStreams().length
	}

	waitForPeers() {
		return new Promise((res, rej) => {
			const provider = this.get().provider;
			const onPeers = () => {
				provider?.off("peers", onPeers)
				res(true)
			}
			provider?.on("peers", onPeers)
		})
	}

	async stopMediaStream() {
		const stream = this.get().stream
		const peers = this.getPeers()
		peers.forEach(({ peer }) => {
			if (stream) {
				try {
					peer.removeStream(stream)
				} catch (err) {
					console.error("error removing stream", err)
				}
			}
		})
		this.proxy.isActive = false;
		this.destroyCurrentStream()
		this.proxy.stream = undefined;

		this.destroyListeners?.();
		this.handleStreamEvents()
	}

	destroyCurrentStream() {
		const stream = this.get().stream
		if (stream) {
			stream.getTracks().forEach(track => track.stop())
		}
	}


	async startVoiceStream() {
		const stream = await this.getVoiceStream()
		this.proxy.voiceStream = stream
		this.proxy.isVoiceShareActive = true
		const audioTrack = stream.getAudioTracks()[0]
		const currentStream = this.get().stream
		if (currentStream) {
			this.addTrack(audioTrack)
		} else {
			await this.startMediaStream(stream)
		}
	}

	async stopVoiceStream() {
		const stream = this.get().voiceStream
		if (stream) {
			const audioTracks = stream.getAudioTracks()
			audioTracks.forEach(track => track.stop())
			audioTracks.forEach(track => stream.removeTrack(track))
			audioTracks.forEach(track => this.removeTrack(track))

			this.proxy.voiceStream = undefined
		}
		this.proxy.isVoiceShareActive = false
	}

	async startScreenShare() {
		const stream = await this.getScreenShareStream()
		this.proxy.screenShareStream = stream
		this.proxy.isScreenSharing = true
		const videoTrack = stream.getVideoTracks()[0]
		const currentStream = this.get().stream
		if (currentStream) {
			this.addTrack(videoTrack)
		} else {
			await this.startMediaStream(stream)
		}
	}

	async stopScreenShare() {
		const stream = this.get().screenShareStream
		if (stream) {
			const videoTracks = stream.getVideoTracks()
			videoTracks.forEach(track => track.stop())
			videoTracks.forEach(track => stream.removeTrack(track))
			videoTracks.forEach(track => this.removeTrack(track))

			this.proxy.screenShareStream = undefined
		}
		this.proxy.isScreenSharing = false
	}

	handleStreamEvents() {
		const handleStreamEvent = (stream: MediaStream) => {
			const videoTracks = stream.getVideoTracks()
			if(videoTracks.length > 0) {
				const track = videoTracks[0]
				console.log(track)
				this.trackMetaMap = {
				...this.trackMetaMap,
					[track.id]: {
						track,
						stream,
						timestamp: Date.now()
					}
				}
				console.log({
					trackMetaMap: this.trackMetaMap
				})
			}
			this.refreshCurrentRemoteStreams()
		}
		const handleTrackEvent = (track: MediaStreamTrack, stream: MediaStream) => {
			
			this.trackMetaMap = {
				...this.trackMetaMap,
				[track.id]: {
					track,
					stream,
					timestamp: Date.now()
				}
			}

			this.refreshCurrentRemoteStreams(
				track, stream
			)
		}

		const handleClose = () => {
			this.refreshCurrentRemoteStreams()
		}


		const disposeStream = this.addListenerToAllPeers("stream", handleStreamEvent)
		const disposeTrack = this.addListenerToAllPeers("track", handleTrackEvent)
		const disposeClose = this.addListenerToAllPeers("close", handleClose)

		const destroyListeners = () => {
			disposeStream()
			disposeTrack()
			disposeClose()
		}

		this.destroyListeners = destroyListeners

		return destroyListeners
	}


	addListenerToAllPeers(event: string, callback: (...args: any[]) => void) {
		const peers = this.getPeers()
		peers.forEach(({ peer }) => {
			peer.on(event, callback)
		})

		return () => {
			peers.forEach(({ peer }) => {
				peer.off(event, callback)
			})
		}
	}

	addTrack(track: MediaStreamTrack) {
		this.addTrackToCurrentStream(track)
		this.addTrackToAllPeers(track)
	}

	replaceTrack(oldTrack: MediaStreamTrack, newTrack: MediaStreamTrack) {
		this.replaceTrackInCurrentStream(oldTrack, newTrack)
		this.replaceTrackInAllPeers(oldTrack, newTrack)
	}


	addTrackToCurrentStream(track: MediaStreamTrack) {
		if (track) {
			const stream = this.get().stream
			if (!stream?.getTracks().find(t => t === track)) {
				stream?.addTrack(track);
			}
		}
	}

	replaceTrackInCurrentStream(oldTrack: MediaStreamTrack, newTrack: MediaStreamTrack) {

		const stream = this.get().stream
		if (stream) {
			stream.removeTrack(oldTrack);
			stream.addTrack(newTrack);
		}
	}

	replaceTrackInAllPeers(oldTrack: MediaStreamTrack, newTrack: MediaStreamTrack) {
		const peers = this.getPeers()
		const stream = this.get().stream
		if (stream) {
			peers.forEach(({ peer }) => {
				peer.replaceTrack(oldTrack, newTrack, stream)
			})
		}
	}

	addTrackToAllPeers(track: MediaStreamTrack) {
		const peers = this.getPeers()
		peers.forEach(({ peer }) => {
			const stream = this.get().stream

			if (stream) {
				if (!this.trackAlreadyAdded(track, peer)) {
					peer.addTrack(track, stream)
				}
			}
		})
	}

	removeTrack(track: MediaStreamTrack) {
		this.removeTrackFromCurrentStream(track)
		this.removeTrackFromAllPeers(track)
	}

	removeTrackFromAllPeers(track: MediaStreamTrack) {
		const stream = this.get().stream
		const videoTrack = track
		const peers = this.getPeers()
		peers.forEach(({ peer }) => {
			stream && peer.removeTrack(videoTrack, stream)
		})
	}

	removeTrackFromCurrentStream(track: MediaStreamTrack) {
		const videoTrack = track
		if (videoTrack) {
			this.get().stream?.removeTrack(videoTrack);
		}
	}


	addStreamToAllPeers(stream: MediaStream) {
		const peers = this.getPeers()
		peers.forEach(({ peer }) => {
			peer.addStream(stream)
		})
	}

	refreshCurrentRemoteStreams(track?: MediaStreamTrack, stream?: MediaStream) {
		const currentRemoteStreams = this.getCurrentRemoteStreams()
		const remoteStreams = currentRemoteStreams.map(item => ({
			...item,
			isMuted: false,
			userName: "default"
		}))
		

		if(track && stream) {
			const findStream = remoteStreams.find(item => item.streams?.find(s => s.id === stream.id))
			// console.log("findStream", findStream)
			// console.log("track", track)
			// console.log("stream", stream)
			// console.log("remoteStreams", remoteStreams)

			if(findStream) {
				const targetStream = findStream.streams?.find(s => s.id === stream.id);
				const findTrack = targetStream?.getTracks().find(t => t.id === track.id)
				
				// console.log("findTrack", findTrack)
				// console.log("targetStream", targetStream)

				if(!findTrack && targetStream){
					targetStream?.addTrack(track)
				}

				// if(findTrack) {
				// 	findTrack.enabled = false
				// }
			}
		}

		this.setRemoteStreams(remoteStreams as any)
	}

	setRemoteStreams(remoteStreams: IRemoteStream[]) {
		this.update(state => ({
			...state,
			remoteStreams
		}))
	}

	getCurrentRemoteStreams() {
		const peers = this.getPeers()
		return peers.map(({ peer, id }) => {
			if (peer && peer._remoteStreams && peer._remoteStreams.length) {
				return {
					id,
					streams: peer._remoteStreams
				}
			}
		})
	}

	getPeers(): IWebrtcPeer[] {
		const connections = this.getConnections();
		const peers: IWebrtcPeer[] = [];

		if (!connections) return [];

		for (let [key, value] of connections) {
			const peer: IWebrtcPeer["peer"] = value.peer
			peers.push({
				id: key,
				peer: peer,
			})
		}
		return peers
	}

	getConnectedPeers(): IWebrtcPeer[] {
		const peers = this.getPeers()
		return peers.filter(({ peer }) => peer && peer.connected)
	}


	getConnections() {
		const provider = this.get().provider
		const webrtcConns = provider?.room?.webrtcConns
		return webrtcConns
	}

	async getVoiceStream(options: MediaStreamConstraints = {
		audio: selectedDevices.get()?.audioInput?.deviceId
			? {
				deviceId: selectedDevices.get()?.audioInput?.deviceId
			} :
			true,
	}): Promise<MediaStream> {
		const globalMediaState = globalMedia.get();

		if (globalMediaState?.audioStream && globalMediaState?.audioStream?.active) {
			return globalMediaState?.audioStream;
		}

		const stream = await navigator.mediaDevices.getUserMedia({
			audio: true,
			video: false,
			...(options || {})
		})

		return stream
	}





	async getScreenShareStream(): Promise<MediaStream> {
		const globalMediaState = globalMedia.get();

		if (globalMediaState?.screenshareStream && globalMediaState?.screenshareStream?.active) {
			return globalMediaState?.screenshareStream;
		}
		const stream = await navigator.mediaDevices.getDisplayMedia({
			video: true,
			audio: false
		})
		return stream
	}

	async toggleCameraFacingMode() {
		const stream = this.get()?.cameraStream;

		if (stream) {

			const facingMode = this.getCameraCurrentFacingMode(stream);
			const newFacingMode = facingMode === "user" ? "environment" : "user";
			this.switchCameraFacingMode(stream, newFacingMode);
		}
	}

	getCameraCurrentFacingMode(cameraStream: MediaStream) {
		const videoTracks = cameraStream?.getVideoTracks()
		const videoTrack = videoTracks?.[0]
		const constraints = videoTrack?.getConstraints()
		const cameraFacingMode = constraints?.advanced?.[0]?.facingMode
		return cameraFacingMode
	}

	async switchCameraFacingMode(stream: MediaStream, facingMode: "user" | "environment") {
		const videoTracks = stream.getVideoTracks()

		// stop all video tracks
		videoTracks.forEach(track => track.stop())
		// remove all video tracks
		videoTracks.forEach(track => stream.removeTrack(track))

		const oldVideoTrack = videoTracks[0]

		// create new video track
		const newVideoStream = await navigator.mediaDevices.getUserMedia({
			video: {
				advanced: [{
					facingMode: facingMode
				}]
			}
		})

		const newVideoTrack = newVideoStream.getVideoTracks()?.[0]

		// add new video track to stream
		newVideoTrack && stream.addTrack(newVideoTrack)
		this.replaceTrackInAllPeers(oldVideoTrack, newVideoTrack)
	}



	async getCameraStream({
		video = {
			facingMode: DEFAULT_FACING_MODE
		},
		audio,
		...options
	}: MediaStreamConstraints = {} as any): Promise<MediaStream> {

		const globalMediaState = globalMedia.get();

		if (globalMediaState?.videoStream && globalMediaState?.videoStream?.active) {
			return globalMediaState?.videoStream;
		}
		const stream = await navigator.mediaDevices.getUserMedia({
			video,
			audio,
			...options
		})
		return stream
	}

	async startCameraStream(stream?: MediaStream) {
		const cameraStream = stream || await this.getCameraStream({
			audio: false
		})
		this.proxy.cameraStream = cameraStream
		this.proxy.isVideoShareActive = true

		const videoTrack = cameraStream.getVideoTracks()[0]
		const currentStream = this.get().stream
		if (currentStream) {
			this.addTrack(videoTrack)
		} else {
			await this.startMediaStream(cameraStream)
		}
	}

	async stopCameraStream() {
		const stream = this.get().cameraStream
		if (stream) {
			const videoTracks = stream.getVideoTracks()
			videoTracks.forEach(track => track.stop())
			videoTracks.forEach(track => stream.removeTrack(track))
			videoTracks.forEach(track => this.removeTrack(track))

			this.proxy.cameraStream = undefined
		}
		this.proxy.isVideoShareActive = false
	}


	dispose() {
		if (this.proxy.isActive) {
			this.stopMediaStream()
			this.stopCameraStream();
			this.stopScreenShare();
		}
		this.destroyProviderListeners?.()
	}



	async changeAudioDevice(deviceId: string) {
		const stream = this.get().voiceStream;
		if (stream) {
			// const audioTracks = stream.getAudioTracks()

			this.stopVoiceStream();
			await new Promise(resolve => setTimeout(resolve, 1000));
			this.startVoiceStream();
			// audioTracks.forEach(track => track.stop())
			// audioTracks.forEach(track => stream.removeTrack(track))

			// const oldAudioTrack = audioTracks[0]

			// // create new video track
			// const newAudioStream = await navigator.mediaDevices.getUserMedia({
			// 	audio: {
			// 		deviceId: deviceId
			// 	},
			// 	video: false,
			// })

			// const newAudioTrack = newAudioStream.getVideoTracks()?.[0]

			// // add new video track to stream
			// newAudioTrack && stream.addTrack(newAudioTrack)
			// this.replaceTrackInAllPeers(oldAudioTrack, newAudioTrack)
		}
	}
}
