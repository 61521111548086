import { syncedStore, getYjsValue } from "@syncedstore/core";
import type { DocTypeDescription } from '@syncedstore/core/src/doc'
import { WebrtcProvider } from "y-webrtc";
import { svelteSyncedStore } from "@syncedstore/svelte";
import type { MappedTypeDescription } from "@syncedstore/core/types/doc";
import type * as Y from "yjs";
import { WebrtcMediaStream, type IWebrtcPeer } from "./WebrtcMediaStream";
import type { Writable } from "svelte/store";
import type { IDisposable } from './commonTypes';
import type { Instance, Options as SimplePeerOptions } from "simple-peer";
import { WebrtcFileServer } from "./WebrtcFileServer";
import { ReactiveObject } from "./ReactiveObject";
import { SharedLogic, type ISharedMethods } from "./sharedLogic";
import type { IMember } from "./roomStore";


const signaling = import.meta.env.DEV
	? ['ws://localhost:4444']
	: ['wss://connect.babelbab.com']

const simplePeerOptions: SimplePeerOptions = {
	config: {
		iceServers: [
			// { urls: 'stun:invalid.com:1930' },
			// { urls: 'stun:stun.l.google.com:1930' },
			// { urls: 'stun:global.stun.twilio.com:3478' },
			// {
			// 	urls: "turn:coturn.babelbab.com:443",
			// 	username: "babelbab",
			// 	credential: "babelbab2023",
			// },
			{
				urls: "turn:coturn.babelbab.com:443?transport=tcp",
				username: "babelbab",
				credential: "babelbab2023",
			},
			// {
			// 	urls: "stun:openrelay.metered.ca:80",
			// },
			// {
			// 	urls: "turn:openrelay.metered.ca:80",
			// 	username: "openrelayproject",
			// 	credential: "openrelayproject",
			// },
			// {
			// 	urls: "turn:openrelay.metered.ca:443",
			// 	username: "openrelayproject",
			// 	credential: "openrelayproject",
			// },
			// {
			// 	urls: "turn:openrelay.metered.ca:443?transport=tcp",
			// 	username: "openrelayproject",
			// 	credential: "openrelayproject",
			// },
			// {
			// 	"urls": "stun:meet-jit-si-turnrelay.jitsi.net:443"
			// },
			// {
			// 	"urls": "turns:meet-jit-si-turnrelay.jitsi.net:443?transport=tcp"
			// },
			// {
			// 	urls: "turns:meet-jit-si-turnrelay.jitsi.net:443?transport=tcp"
			// }
		],

		iceTransportPolicy: "relay"
		
	},
}

export interface ICustomDataChannel {
	channel: RTCDataChannel;
	id: string;
	supportingChannels?: RTCDataChannel[];
}



export enum ConnectionState {
	Connected = 'Connected',
	Connecting = 'Connecting',
	Disconnected = 'Disconnected'
}

export interface IConnectionMeta {
	state: ConnectionState;
	connectedUsersCount: number;
}


export enum SyncedStoreEvents {
	peers = "peers",
	peer = "peer",

	memberAdded = "memberJoined",
	memberRemoved = "memberLeft",

	stunFailed = "stunFailed",
	stunSuccess = "stunSuccess",
	switchedToTurn = "switchedToTurn",
}

export interface ISyncedStoreEventsData {
	[SyncedStoreEvents.peers]: {
		added: IWebrtcPeer[];
		removed: IWebrtcPeer[];
		bcPeers: IWebrtcPeer[];
		webrtcPeers: IWebrtcPeer[];
	}

	[SyncedStoreEvents.peer]: {
		peer: IWebrtcPeer;
	}

	[SyncedStoreEvents.memberAdded]: {
		member: IMember[]
	}

	[SyncedStoreEvents.memberRemoved]: {
		member: IMember[];
	}

	[SyncedStoreEvents.stunFailed]: {},
	[SyncedStoreEvents.switchedToTurn]: {},
	[SyncedStoreEvents.stunSuccess]: {},
}


export class SyncedStore
	<
		T extends DocTypeDescription,
		RpcClient extends ISharedMethods = any,
		RpcServer extends ISharedMethods = any
	>
	extends EventTarget {

	public roomName: string;
	public provider: WebrtcProvider;
	public coreStore: MappedTypeDescription<T>;
	public store: Writable<T>
	public doc: Y.Doc;
	public webrtcMediaStream: WebrtcMediaStream;
	public webrtcFileServer: WebrtcFileServer
	public providerDestroyed: boolean = false;
	public rpc: SharedLogic<RpcClient, RpcServer>
	public generalDataChannelId: string = 'general-data-channel';
	public generalDataChannel: ICustomDataChannel[] = [];


	public connectionMeta: ReactiveObject<IConnectionMeta> = new ReactiveObject<IConnectionMeta>({
		state: ConnectionState.Disconnected,
		connectedUsersCount: 0
	})

	public onClose: (id: string, peer: Instance) => void = () => { };

	public disposables: IDisposable[] = []
	public removeListeners: () => void = () => { }
	public userId?: string

	constructor(roomName: string, initialState: T, userId?: string) {
		super();
		this.roomName = roomName;
		this.userId = userId;
		this.coreStore = syncedStore<T>(initialState);
		this.store = svelteSyncedStore(this.coreStore) as any
		this.doc = getYjsValue(this.coreStore) as any;
		this.provider = new WebrtcProvider(roomName, this.doc, {
			signaling: signaling,
			filterBcConns: false,
			peerOpts: simplePeerOptions
		} as any);
		this.webrtcMediaStream = new WebrtcMediaStream({
			provider: this.provider,
			remoteStreams: [],
		} as any)


		this.webrtcFileServer = new WebrtcFileServer({
			files: [],
			dataChannels: [],
			userId: this.userId,
		});

		this.initListeners();
		this.webrtcMediaStream.initProviderListeners()
		this.rpc = new SharedLogic([])
	}


	listenEvent<
		E extends keyof ISyncedStoreEventsData,
		D extends ISyncedStoreEventsData[E]
	>(
		event: E,
		callback: (data: D) => void,
		options?: AddEventListenerOptions
	): () => void {
		const handler = (e: CustomEvent<D>) => {
			callback(e.detail)
		}
		this.addEventListener(event as any, handler as any, options)

		return () => {
			this.removeEventListener(event, handler as any, options)
		}
	}

	dispatch(
		event: keyof ISyncedStoreEventsData,
		detail: ISyncedStoreEventsData[keyof ISyncedStoreEventsData]
	) {
		this.dispatchEvent(new CustomEvent(event, { detail }))
	}

	isSynced(timeout: number = 5000): Promise<boolean> {
		return new Promise((res, rej) => {
			if (this.provider.room?.synced) {
				res(true)
			} else {
				this.provider.once("synced", () => {
					clearTimeout(timeoutId)
					// console.log("synced")
					res(true)
				})

				this.provider.once("error", (err: any) => {
					clearTimeout(timeoutId)
					console.error(err)
					rej(new Error("unknown"))
				})

				let timeoutId = setTimeout(() => {
					if (!this.provider.room?.synced) {
						res(false)
					} else {
						res(true)
					}
				}, timeout)
			}
		})
	}


	setConnectionState(state: ConnectionState) {
		this.connectionMeta.setItem('state', state)
	}

	connect() {

		console.log("connecting..")
		this.setConnectionState(ConnectionState.Connecting)
		if (this.providerDestroyed) {
			this.provider = new WebrtcProvider(this.roomName, this.doc, {
				signaling: signaling
			} as any);
			this.webrtcMediaStream.updateProvider(this.provider)

			this.providerDestroyed = false
		}

		this.provider.connect();
		this.webrtcMediaStream.handleStreamEvents();
		this.webrtcMediaStream.refreshCurrentRemoteStreams();
	}

	disconnect() {
		this.provider.disconnect();
	}



	destroy() {
		try {
			this.setConnectionState(ConnectionState.Disconnected)
			this.destroyCustomDataChannel();
			this.removeListeners();
			this.webrtcMediaStream.dispose()
			this.provider.destroy()
			this.disposables.forEach(d => d.dispose())
			this.providerDestroyed = true;
			this.webrtcMediaStream.getPeers().forEach(peer => {
				if (!peer.peer.destroyed) {
					peer.peer.destroy()
				}
			})
		} catch (err) {
			console.error(err)
		}
	}

	handlePeerClosed(id: string, peer: Instance) {
		// @ts-ignore
		peer.customDataChannels = [];
		this.webrtcFileServer.removeDataChannel(id)
	}

	checkConnectionStatus(webrtcPeerId: string): boolean {
		const peer = this.webrtcMediaStream.getPeers().find(p => p.id === webrtcPeerId)
		if (peer) {
			return peer.peer.connected
		}

		return false
	}

	initListeners() {
		let disposables: IDisposable[] = []
		const onPeers = (data: {
			added: string[],
			removed: string[]
			bcPeers: string[]
			webrtcPeers: string[]
		}) => {
			disposables.forEach(({ dispose }) => dispose())
			const peers = this.webrtcMediaStream.getPeers();
			this.connectionMeta.update(state => {
				state.connectedUsersCount = peers.length;

				if (peers.length > 0) {
					state.state = ConnectionState.Connected;
				} else {
					state.state = ConnectionState.Disconnected;
				}
				return state
			})

			this.handleDataChannels(peers)
			disposables = peers.map(({ peer, id }) => {
				const peerConnection = peer._pc
				const onClose = () => {
					this.handlePeerClosed(id, peer)
					this.onClose(id, peer)
				}
				const onError = (err: Error) => {
					console.error("peer close error", err)
				}

				const onEnd = (event: any) => {
					console.log("peer end", event)
				}

				const handleICEConnectionStateChange = () => {
					console.log(`ICE connection state: ${peerConnection.iceConnectionState}`);

					if (peerConnection.iceConnectionState === 'failed') {

						this.dispatch(
							SyncedStoreEvents.stunFailed,
							{
							}
						)
						console.log('ICE connection failed, closing connection stun 1');
					} else {
						console.log('ICE connection success');
					}
				}

				const handleICEGatheringStateChange = () => {

					console.log(`ICE gathering state: ${peerConnection.iceGatheringState}`);

					if (peerConnection.iceGatheringState === 'complete' && peerConnection.iceConnectionState !== 'connected') {
						console.log('ICE gathering complete, but connection not established, closing connection stun 2');
						this.dispatch(
							SyncedStoreEvents.stunFailed,
							{
							}
						)
					} else {
						console.log('ICE gathering complete, connection established');
					}
				}

				const handleICECandidateError = (err: any) => {
					console.error('ICE candidate error:', err);
				}

				const getCandidateType = (candidateString: string) => {
					const typeRegex = /typ ([a-z]+)/;
					const match = candidateString.match(typeRegex);
					return match ? match[1] : null;
				}

				const handleICECandidate = (event: any) => {
					try{
						if (event.candidate) {
							const candidateType = getCandidateType(event.candidate.candidate);
							console.log(`Candidate type: ${candidateType}`);
	
							if (candidateType === 'relay') {
								// TURN candidate detected
								console.log('Switched to TURN server');
							}else{
								console.log('Switched to STUN server');
							}
						}
					}catch(err){
						console.error(err)
					}
				}

				peer.on("close", onClose);
				peer.on("error", onError);
				peer.on("end", onEnd)

				peerConnection.addEventListener(
					"iceconnectionstatechange",
					handleICEConnectionStateChange
				)

				peerConnection.addEventListener(
					"icegatheringstatechange",
					handleICEGatheringStateChange
				)

				peerConnection.addEventListener(
					"icecandidateerror",
					handleICECandidateError
				)

				peerConnection.addEventListener(
					"icecandidate",
					handleICECandidate
				)


				return {
					dispose: () => {
						peer.off("close", onClose);
						peer.off("error", onError)
						peer.off("end", onEnd)

						peerConnection.removeEventListener(
							"iceconnectionstatechange",
							handleICEConnectionStateChange
						)

						peerConnection.removeEventListener(
							"icegatheringstatechange",
							handleICEGatheringStateChange
						)

						peerConnection.removeEventListener(
							"icecandidateerror",
							handleICECandidateError
						)

						peerConnection.removeEventListener(
							"icecandidate",
							handleICECandidate
						)
					}
				}
			})
		}

		this.provider.on("peers", onPeers);

		this.removeListeners = () => {
			this.provider.off("peers", onPeers);
			disposables.forEach(({ dispose }) => dispose())
		}
	}
	setGeneralDataChannel(channels: ICustomDataChannel[]) {
		this.rpc.setDataChannels(channels.map(c => c.channel))
		this.generalDataChannel = channels;
	}

	handleDataChannels(peers: IWebrtcPeer[]) {

		const dataChannelCount: number = 10;
		peers.forEach(({ peer, id }: {
			peer: any;
			id: string;
		}) => {
			const pc: RTCPeerConnection = peer._pc;
			if (!pc) {
				return
			}
			const prevDatachannelHandler = pc.ondatachannel;
			const initiator = peer.initiator;

			if (peer.customDataChannels?.length === dataChannelCount || peer.dataChannelCreationPending) {
				return
			}

			peer.dataChannelCreationPending = true;

			if (!initiator) {
				pc.ondatachannel = (event: RTCDataChannelEvent) => {
					const dataChannel = event.channel;
					const name = dataChannel.label;
					if (name.startsWith("file-transfer")) {

						if (!peer.customDataChannels) {
							peer.customDataChannels = []
						}
						peer.customDataChannels.push(dataChannel)


						if (peer.customDataChannels.length === dataChannelCount) {
							peer.dataChannelCreationPending = false;
							pc.ondatachannel = prevDatachannelHandler;
							this.setGeneralDataChannel([
								...this.generalDataChannel,
								{
									channel: peer.customDataChannels[0],
									id: id
								}
							])
							this.webrtcFileServer.addDataChannel({
								channel: peer.customDataChannels[1],
								id: id,
								supportingChannels: peer.customDataChannels.slice(2)
							})

							this.webrtcFileServer.sendUserMeta(id)
						}
					}
					else if (name === this.generalDataChannelId) {
						this.setGeneralDataChannel([
							...this.generalDataChannel,
							{
								channel: dataChannel,
								id: id
							}
						])
					} else {
						peer._setupData(event)
					}
				}
			} else {

				let promises: Promise<RTCDataChannel>[] = new Array(dataChannelCount).fill(0).map((_, i) => {
					return new Promise((res, rej) => {
						const dataChannel = pc.createDataChannel(`file-transfer-${i}`, {
							ordered: false,
						});
						dataChannel.binaryType = "arraybuffer";
						dataChannel.onopen = () => {
							res(dataChannel)
						}
					})
				})

				Promise.all(promises)
					.then((dataChannels: RTCDataChannel[]) => {
						peer.customDataChannels = dataChannels;
						peer.dataChannelCreationPending = false;

						this.setGeneralDataChannel([
							...this.generalDataChannel,
							{
								channel: peer.customDataChannels[0],
								id: id
							}
						])
						this.webrtcFileServer.addDataChannel({
							channel: peer.customDataChannels[1],
							id: id,
							supportingChannels: peer.customDataChannels.slice(2)
						})

						this.webrtcFileServer.sendUserMeta(id)
					})
					.catch(err => {
						console.error(err)
					})

			}
		})
	}


	destroyCustomDataChannel() {
		const peers = this.webrtcMediaStream.getPeers();
		peers.forEach(({ peer }: { peer: any }) => {
			if (peer.customDataChannel) {
				peer.customDataChannel.close()
				peer.customDataChannel = null
			}
		})
	}


	getCustomDataChannels(): ICustomDataChannel[] {
		const peers = this.webrtcMediaStream.getPeers();
		const channels: ICustomDataChannel[] = peers.map(({ peer, id }: { peer: any, id: string }) => {
			return {
				channel: peer.customDataChannel,
				id,
			}
		})
		return channels
	}
}




