import type { IDisposable } from "./commonTypes";
import { ExtendedEventTarget } from "./ExtendedEventTarget";
import { sendNotification, type NotificationDestination } from "./pushNotification";

export interface ICloudMessagePayload{
	type: IFirebaseMessagingEventsTypes;
	data: any;
	isUrgent: boolean;
	sendingUserId: string[];
	receivingUserId: string[];
	sentTimestamp: number;
	destination?: NotificationDestination 
}

export type IFirebaseMessagingEventsTypes =
	| "message"

export interface ICalendarEventBasicProps{
	eventId: string;
	subEventId: string;
	userId: string;
	timestamp: number;
}




export type FirebaseMessagingEventsProps = Record<
	IFirebaseMessagingEventsTypes,
	any
>

export interface IFirebaseMessagingEventsPropsMap extends FirebaseMessagingEventsProps{
	"message": {
		id: string;
	}
}

export class FirebaseMessagingEvents extends ExtendedEventTarget<
	IFirebaseMessagingEventsPropsMap
>{
	public disposables: (IDisposable)[] = [];
	constructor(){
		super();
	}

	handleMessage(
		message: ICloudMessagePayload
	){
		this.dispatch(
			message.type as any,
			message.data
		)
	}
}

export const sendCloudMessage = async (
	message: ICloudMessagePayload
) => {


	console.log({message})

	for(let userId of message.receivingUserId){
	 	await sendNotification({
			title: "json",
			body: JSON.stringify(message),
			id: userId,
		})
	}
}


export const sendCloudEventMessage = <
	T extends keyof IFirebaseMessagingEventsPropsMap
>(
	type: T,
	props: IFirebaseMessagingEventsPropsMap[T],
	options: Partial<ICloudMessagePayload> & {
		receivingUserId: string[];
		sendingUserId: string[];
	}
) => {
	sendCloudMessage({
		type,
		data: props,
		isUrgent: options?.isUrgent ?? false,
		sendingUserId: options.sendingUserId,
		receivingUserId: options.receivingUserId,
		sentTimestamp: Date.now(),
		destination: options.destination,
	})
}