import {PouchDBMock as PouchDB} from './../common/PouchdbMock'

const getWorkspacesDBOld = () => new PouchDB('workspaces')
const getPagesDB = () => new PouchDB('pages')
const getSettingsDB = () => new PouchDB('settings')
const getNodesDB = () => new PouchDB('nodes')
const getWorkspacesDB = () => new PouchDB('woandar')
const getInternetDB = () => new PouchDB('internet')
const getCalendarDB = () => new PouchDB('calendar')
const getUpdateHistoryDB = () => new PouchDB('updatehistory')
const getPouchStoreDB = () => new PouchDB('pouch_store')
const getKeyStoreDB = () => new PouchDB('key_store')

export {
  getNodesDB,
  getWorkspacesDB,
  getInternetDB,
  getCalendarDB,
  getUpdateHistoryDB,
  getPouchStoreDB,
  getKeyStoreDB,
  getWorkspacesDBOld,
  getPagesDB,
  getSettingsDB,
}
