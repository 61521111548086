import { ExtendedWritable } from "./ExtendedWritable";
import type { Swiper } from "swiper";
import type {BaseAuthStore} from 'pocketbase'


export type MobileSections = "glance" | "chats" | "tasks" | "feeds" | "contacts";

export type DesktopChatSections = "chats" | "outline" | "glance" | "users" | "stats" | "settings" | "calendar";

export const mobileActiveSection = new ExtendedWritable<MobileSections>("glance");
export const glanceViewInputVisible = new ExtendedWritable<boolean>(false);
export const taskViewActive = new ExtendedWritable<boolean>(false);
export const dictateModeActive = new ExtendedWritable<boolean>(false);

export const sectionsSwiperInstance = new ExtendedWritable<Swiper | undefined>();
export const glanceSwiperInstance = new ExtendedWritable<Swiper | undefined>();

export const activeDesktopChatSection = new ExtendedWritable<DesktopChatSections>("chats");
export const authStore = new ExtendedWritable<BaseAuthStore | undefined>(undefined);
export const chats = new ExtendedWritable<any[]>([]);

export const getIndexFromMobileSection = (section: MobileSections) => {
	switch (section) {
		case "chats":
			return 0;
		case "glance":
			return 1;
		case "tasks":
			return 2;
	}
}

export const getMobileSectionFromIndex = (index: number) => {
	switch (index) {
		case 0:
			return "chats";
		case 1:
			return "glance";
		case 2:
			return "tasks";
	}
}


export interface IContact{
	fullName: string;
	tel: string[];
	email: string[];
}


export const activeContacts = new ExtendedWritable<IContact[]>([]);

export const recentInviteId = new ExtendedWritable<string | undefined>(undefined);

export const currentChatMethods = new ExtendedWritable<{
	chatId: string;
	sendMessage: (message: string) => Promise<void>;
}>([]);
