import shurley from 'shurley'
import urlJoin from 'url-join'
import { tick } from 'svelte'
import _ from 'lodash'
import recentTags from './../stores/recentTags'
import metaTags from './../stores/metaTags'
import dayjs from 'dayjs'
import qs from 'querystringify'
import fileStore from '../stores/fileStoreData'
import youtubeUrl from 'youtube-url'

import {uid as _uid} from 'uid'
import { saveAs } from 'file-saver';
import { hideTags } from './../Layout/store';
import { get } from 'svelte/store';

const randomUid = {
  default: _uid,
}

export const commandsRE = /(?<commandFull>\{\{(?<command>.+?)\}\})/g
export const doneRE = /{{\[\[DONE\]\]}}/g
export const dateRE = /{{DATE}}/g
export const todoStartedRE = /(?<complete>started\((?<time>[\w\d:\- ]+)\))/g
export const todoPausedRE = /(?<complete>paused\((?<time>[\w\d:\- ]+)\))/g
export const todoDoneRE = /(?<complete>done\((?<time>[\w\d:\- ]+)\))/g
export const todoLastedRE = /(?<complete>@asted\((?<time>[\w\d:\- ]+)\))/g
export const todoTimeRE = /(\+(?<time>\d+)(?<unit>\w))/g
export const pausedRE = /((?<=({{\[\[TODO\]\]}})| ({{\[\[DONE\]\]}})) {{\[\[PAUSED\]\]}})/g
export const pageBracketsRE = /(\[\[(?<page>.+?)\]\])/g
export const pageBracketsHashRE = /#\[\[(?<page>.+?)\]\]/g
export const pageRE = /(?<hashFull>\B#(?<hashPage>\w+)(?=($| )))|(?<hashBracketFull>\B#\[\[(?<hashBracketPage>.+?)\]\]\B)|(?<bracketFull>(?<!#)\[\[(?<bracketPage>.+?)\]\])/g
export const aliasLinksRE = /(\[(?<alias>.+?)\]\((?<url>.+)\))/g
export const markdownLink = /(?<=^|\s)(?<full>\[(?<name>.+?)\]\((?<url>.*?[)]*)\)(?<seekTimeFull>\((?<seekTime>.+)\))?)/g
export const markdownImage = /(?<=^|\s)(?<full>!\[(?<name>.+?)\]\((?<url>.*?[)]*)\))/g
export const markdownGitos = /(?<=^|\s)(?<full>\*\[(?<name>.+?)\]\((?<url>.*?[)]*)\))/g
export const markdownVideo = /(?<=^|\s)(?<full>@\[(?<name>.+?)\]\((?<url>.*?[)]*)\)(?<seekTimeFull>\((?<seekTime>.+)\))?)\B/g
export const markdownLinkEmbed = /(?<=^|\s)(?<full>&\[(?<name>.+?)\]\((?<url>.*?[)]*)\)(?<seekTimeFull>\((?<seekTime>.+)\))?)\B/g
export const cellRefRE = /(?<full>\(\((@(?<workspace>\w+):)?((?<store>\w+):)?(?<id>[a-z0-9A-Z ]+?)\)\))/g
export const pageRefRE = /(?<full>\<\<(@(?<workspace>\w+):)?((?<store>\w+):)?(?<id>[a-z0-9A-Z ]+?)\>\>)/g
export const metaDataRE = /^(?<full>(?<name>.+?)::)/g
export const highlightTextRE = /(?<full>\^\^(?<text>.+?)\^\^)/g
export const locationRE = /^\/(?<page>.*)(\/?).*?/g
export const codeBlocksRE = /(?<=^|\s)(?<full>(?<begin>```)(?<language>.*\r?\n)(?<code>[^]*?)(?<end>```))(?=$|\s)/gm
export const localHyperLink = /(?<=^|\s)(?<full>woandar:\/\/(?<url>.+))(?!\B)/g
export const scoreRE = /((?<=^|\s)\{\{\[\[SCORE (\d)\]\]\}\}(?=$|\s))/g
export const nodeMarkdownAlias = /(?<=^|\s)(?<full>\[(?<alias>.+?)\]\(\(\((?<uid>.*?[)]*)\)\)\))/g
export const performSelectionRe = /(?<=^|\s)(?<full>\$\$(?<text>.+?)\$\$)(?=$|\s)/gi
export const metaDataParseRE = /^(?<full>(?<name>.+?)::(?<value>.+)?)/g
export const dateRe = /(?<full>\b(?<day>0?[1-9]|[12][0-9]|3[01])[- /.](?<month>0[1-9]|1[012])[- /.](?<year>19|20)\d\d\b)/g
export const usernameRe = /(?<=^|\s)(?<full>@(?<username>[A-Za-z0-9_]+(?=$|\s)))/g
export const timeRangeMatch = /(?<full>(?<startTime>(?<=^|\s)(?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s?))-(?<endTime>(?:\s?)(?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])))/g
export const timeMatch = /(?<full>(?:(?:(?<hours>[01]?\d|2[0-3]):)?(?<minutes>[0-5]?\d):)?(?<seconds>[0-5]?\d)(?<endTime>-(?:(?:(?<endhours>[01]?\d|2[0-3]):)?(?<endMinutes>[0-5]?\d):)?(?<endSeconds>[0-5]?\d))?)/g
export const URLRe = /(?<=^|\s)(?<full>((ftp|http|https):\/\/)?(([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6})|localhost:\d+)\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))(?=$|\s)/g
export const youtubeRe = /(?<full>(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)\&?[\S]*)/g
export const hashtagRe = /(?<=^|\s)(?<full>\#(?<value>.+?)\#)(?=$|\s)/g
export const searchRe = /(?<=^|\s)(?<full>w\.(?<value>.+)\.)(?=$|\s)/g
export const queriesRe = /(?<=^|\s)(?<full>wq\.(?<value>.+)\.)(?=$|\s)/g
export const templateRe = /(?<=^|\s)(?<full>wt\.(?<value>.+)\.)(?=$|\s)/g
export const notificationQueriesRe = /(?<=^|\s)(?<full>wqn\.(?<value>.+)\.)(?=$|\s)/g

const pageTypes = {
  bracket: 'bracket',
  hashBracket: 'hashBracket',
  hash: 'hash',
  metaData: 'metaData',
}

const commandTypes = {
  todo: 'todo',
  done: 'done',
  date: 'date',
  paused: 'paused',
  search: 'search',
  score: 'score',
  goal: 'goal',
  bug: 'bug',
}

String.prototype.betterReplace = function(search, replace, from) {
  if (this.length > from) {
    return this.slice(0, from) + this.slice(from).replace(search, replace)
  }
  return this
}

export const getVideoUploadPlaceholderUrl = (id) => `${window.location.origin}/#/videos/upload-placeholder/${id}`

export function parseExternalLinks(str) {
  const matches = getAllMatches(str, URLRe)
  return matches
    .map(match => {
      try {
        const full = getGroupItem('full', match)

        const parsed = shurley.parse(full || '')

        return {
          url: full,
          parsedURL: parsed,
        }
      } catch (err) {
        console.error(err.message)
        return null
      }
    })
    .filter(item => item)
}

export function hasSubcaret({ id, options }) {
  if (options && options.shared && options.shared.caretPositions) {
    const caretPositions = options.shared.caretPositions || {}
    const data = Object.keys(caretPositions)
      .map(key => caretPositions[key])
      .filter(item => item && item.uid === id)

    if (!data.length) return

    return data
  }
}

export function renderExternalLinks(str, uid) {
  const matches = parseExternalLinks(str)

  // console.log(matches, uid)
  matches.forEach(match => {
    try {
      const matchedUrl = new URL(match.url)

      if (
        matchedUrl.origin === window.location.origin 
      ) {

        if(matchedUrl.hash.match(/\/videos\/upload-placeholder\/.+/)){
          const videoId = matchedUrl.hash.match(/\/videos\/upload-placeholder\/(.+)/)[1]

          const html = `<span class="video-upload-placeholder" data-video-id="${videoId}">uploading...</span>`
          str = str.replace(match.url, html)
        }else{
          if(matchedUrl.hash.match(/[a-zA-Z0-9]{3,16}\/[a-zA-Z0-9]{6,16}/)){
            const a = matchedUrl.hash.split('/')[3]
    
            if (a) {
              let id = a
              // matchedUrl.hash = matchedUrl.hash.replace('#/', '#/sharedspace/')
              let html = `<span class="" ><a data-uid="${id}" href="${
                matchedUrl.href
              }" data-currentuid="${uid}" title="cell: ${id}" class="cell--local cell-local-link bg-base-100 hover:bg-base-100 hover:underline cursor-pointer">${`((${id}))`}</a></span>`
              str = str.replace(match.url, html)
            } else {
              let html = `<a class=" external-links markdown--local" href="${match.parsedURL}" data-uid="${uid}" title="${match.parsedURL}" data-isexternal="true">${match.url}</a>`
              str = str.replace(match.url, html)
            }
          }
        }

      } else {
        let html = `<a class="external-links  markdown--local" href="${match.parsedURL}" data-uid="${uid}" title="${match.parsedURL}" data-isexternal="true">${match.url}</a>`
        str = str.replace(match.url, html)
      }
    } catch (err) {
      let html = `<a class="external-links markdown--local" href="${match.parsedURL}" data-uid="${uid}" title="${match.parsedURL}" data-isexternal="true">${match.url}</a>`
      str = str.replace(match.url, html)
    }
  })
  return str
}

export function getNumberFromTime(string = '') {
  if (string) {
    const _string = string.replace(/$0(\d)/, '$1')
    return +_string || 0
  }
  return 0
}

export function parseSearch(str) {
  const matches = getAllMatches(str, searchRe)

  return matches.map(match => {
    const full = getGroupItem('full', match)
    const value = getGroupItem('value', match)

    return {
      full,
      value,
    }
  })
}

export function parsePlaceholder(str) {
  const placeholderRe = /(?<full>{{PLACEHOLDER}})/g
  const matches = getAllMatches(str, placeholderRe)

  return matches.map(match => ({
    full: getGroupItem('full', match),
  }))
}

export function renderPlaceholder(str) {
  const matches = parsePlaceholder(str)

  matches.forEach(match => {
    str = str.replace(
      match.full,
      `<span style="height:1.5em" class="inline-block absolute gradient bg-base-100 w-full"></span>`
    )
  })

  return str
}

export function highlightAttributes(str) {
  const attributes = parseAttributes(str)

  attributes.forEach(attribute => {
    str = str.betterReplace(
      attribute.value,
      `<span class="text-purple-800">${attribute.value}</span>`,
      attribute.index
    )
    str = str.betterReplace(
      attribute.attribute,
      `<span class="text-teal-800 font-bold">${attribute.attribute}</span>`,
      attribute.index
    )
    str = str.betterReplace(
      attribute.action,
      `<span class="text-teal-800">${attribute.action}</span>`,
      attribute.index
    )
  })
  return str
}

export function parseQueries(str) {
  const matches = getAllMatches(str, queriesRe)

  return matches.map(match => {
    const full = getGroupItem('full', match)
    const value = getGroupItem('value', match)

    return {
      full,
      value,
      match,
      queryURL: queryToURL(value),
      index: match.index,
    }
  })
}

export function parseTemplates(str) {
  const matches = getAllMatches(str, templateRe)

  return matches.map(match => {
    const full = getGroupItem('full', match)
    const value = getGroupItem('value', match)

    return {
      full,
      value,
      match,
      templateURL: templateToURL(value),
      index: match.index,
    }
  })
}

export function replaceQueriesWithEmbed(str, { store } = {}) {
  const queries = parseQueries(str)
  queries.forEach(match => {
    if (match.value) {
      const _node = store.addNode()

      if (_node) {
        store.updateString(
          _node.uid,
          string => `[${match.value}](${match.queryURL})`
        )
        str = str.betterReplace(match.full, `((${_node.uid}))`, match.index)
      }
    }
  })
  return str
}

export function replaceTemplatesWithEmbed(str, { store } = {}) {
  const queries = parseTemplates(str)
  queries.forEach(match => {
    if (match.value) {
      const _node = store.addNode()

      if (_node) {
        store.updateString(
          _node.uid,
          string => `[${match.value}](${match.templateURL})`
        )

        const child = store.addChildren(_node.uid)
        if (child) {
          store.updateString(child.uid, string => `template:: ${match.value}`)
          store.addChildren(child.uid)
        }
        str = str.betterReplace(match.full, `((${_node.uid}))`, match.index)
      }
    }
  })
  return str
}

export function getUIDFromLocation(loc) {
  const re = /\/?page\/(\w+)\/?/g

  if (loc && re.test(loc)) {
    return loc.replace(re, '$1')
  }

  return null
}

export function renderSearch(str, uid) {
  const matches = parseSearch(str)

  matches.forEach(match => {
    str = str.replace(
      match.full,
      `<span data-uid="${uid}" class="woandar-search text-purple-700 font-bold hover:text-purple-500"><span class="font-medium text-purple-600"></span>${match.value}</span>`
    )
  })

  return str
}

export function renderQueries(str, uid) {
  const matches = parseQueries(str)

  matches.forEach(match => {
    str = str.replace(
      match.full,
      `<span data-uid="${uid}" class="woandar-queries cursor-pointer hover:bg-base-200"><span class="font-medium text-teal-600"></span>${highlightAttributes(
        match.value
      )}</span>`
    )
  })

  return str
}

export function renderTemplates(str, uid) {
  const matches = parseTemplates(str)

  matches.forEach(match => {
    str = str.replace(
      match.full,
      `<span data-uid="${uid}" class="woandar-template cursor-pointer hover:bg-base-200"><span class="font-medium text-teal-600"></span>${highlightAttributes(
        match.value
      )}</span>`
    )
  })

  return str
}

export function timeToSeconds(time) {
  const _time = _.first(parseTime(time))
  return toSeconds(_time)
}

export function parseTime(string) {
  const matches = getAllMatches(string, timeMatch)

  return matches.map(match => {
    const full = getGroupItem('full', match)
    const seconds = getGroupItem('seconds', match)
    const hours = getGroupItem('hours', match)
    const minutes = getGroupItem('minutes', match)
    const endTime = getGroupItem('endTime', match)
    const endSeconds = getGroupItem('endSeconds', match)
    const endHours = getGroupItem('endHours', match)
    const endMinutes = getGroupItem('endMinutes', match)

    return {
      full,
      seconds: getNumberFromTime(seconds),
      minutes: getNumberFromTime(minutes),
      hours: getNumberFromTime(hours),
      end: endTime
        ? {
            full: endTime,
            seconds: getNumberFromTime(endSeconds),
            hours: getNumberFromTime(endHours),
            minutes: getNumberFromTime(endMinutes),
          }
        : null,
    }
  })
}

export function parseYoutubeURL(str) {
  const matches = getAllMatches(str, youtubeRe)
  return matches.map(match => {
    const full = getGroupItem('full', match)
    return full
  })
}

export function renderYoutubeURL(str, uid) {
  const urls = parseYoutubeURL(str)

  urls.forEach(url => {
    str = str.replace(
      url,
      `<div data-src="${url}" data-uid="${uid}" class="video-player-local px-2 py-2" style="width:350px"><video class="js-player w-full" controls src="${url}" /></div>`
    )
  })

  return str
}

export function renderTimeRange(string, id) {
  const timeRange = parseTimeRangeData(string)

  timeRange.forEach(item => {
    const href = `#/queries${qs.stringify(
      {
        query: `${item.full} on:calendar="true" sort:edit-time="desc"`,
      },
      true
    )}`
    const html = `<a style="display:inline-block; text-decoration: none;" href="${href}" class="id-${id} time-range-local font-bold cursor-pointer rounded hover:bg-base-100  text-gray-700 border-b hover:border-gray-400 hover:text-gray-600">${item.full}</a>`
    string = string.replace(item.full, html)
  })

  return string
}

export function renderSettingsPage(string) {
  const settingsPage = parseSettingsPage(string)

  if (settingsPage) {
    const html = `<a class="font-bold hover:bg-base-100 rounded" href="" style="color: #666; text-decoration: none;"><i class="cog icon"></i>Settings</a>`
    string = string.replace(settingsPage.full, html)
  }

  return string
}

export function queryToURL(str) {
  const BASE_QUERY_URL = `https://app.notebrowser.com/#query`
  return `${BASE_QUERY_URL}${qs.stringify(
    {
      query: str,
    },
    true
  )}`
}

export function templateToURL(str) {
  const BASE_QUERY_URL = `https://template.woandar.app/`
  return `${BASE_QUERY_URL}${qs.stringify(
    {
      type: 'woandar_template',
      template: '',
    },
    true
  )}`
}

export function parseQueryURL(str) {
  try {
    const URLRe = /^(?<full>((ftp|http|https):\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g
    if (URLRe.test(str) && str.includes('query.woandar.app')) {
      const url = shurley.parse(str)
      const _url = new URL(url)
      const parsed = qs.parse(_url.search)
      if (parsed && parsed.type === 'woandar_query' && parsed.query) {
        return parsed.query
      }
    }
  } catch (err) {
    return null
  }
}

export function parseMarkdownQueries(str) {
  const markdownLinks = getMarkdownLinks(str)

  return markdownLinks
    .map(match => {
      const query = parseQueryURL(match.url)
      return (
        query && {
          query,
          queryURL: match.url,
          match,
          index: match.index,
          full: match.full,
          name: match.name,
        }
      )
    })
    .filter(item => item)
}

export function updateMarkdownQuery(str) {
  const markdownLinks = getMarkdownLinks(str)

  markdownLinks.forEach(match => {
    const query = parseQueryURL(match.href)
    if (query && match.name !== query) {
      // queryToURL(query)
    }
  })
}

export function parseSettingsPage(string) {
  const metaData = _.first(parseMetaData(string))

  if (metaData && (metaData.name || '').toLowerCase().trim() === 'settings') {
    return metaData
  }
  return null
}

export function parseTimeRangeData(string) {
  const matches = getAllMatches(string, timeRangeMatch)

  return matches.map(match => {
    const full = getGroupItem('full', match)
    const startTime = getGroupItem('startTime', match)
    const endTime = getGroupItem('endTime', match)

    return {
      index: match.index,
      full: full,
      startTime: startTime,
      endTime: endTime,

      // replace: function(string, replace, group){
      //   if(!group) {
      //     group = 'full'
      //   }

      //   const matches = string.getAllMatches(string, timeRangeMatch)

      //   return string.replace(timeRangeMatch, (...args) => {
      //     const matchString = _.first(args)
      //     const index = args.find(item => _.isNumber(item))
      //     const groups = args.find(item => _.isObject(item))

      //     const groupItem =

      //     if(index < match.index) return  matchString
      //     else {

      //     }
      //   })
      // }
    }
  })
}

export function parseString(string) {
  const pages = getPages(string)
  // const cells =
}

export function isMagnetLink(string) {
  const re = /magnet:\?xt=urn:[a-z0-9]{20,50}/i
  return string.includes('magnet')
}

export function getNodeMarkdownAlias(str) {
  const matches = getAllMatches(str, nodeMarkdownAlias)
  return matches.map(match => {
    const uid = getGroupItem('uid', match)
    const full = getGroupItem('full', match)
    const alias = getGroupItem('alias', match)

    return {
      uid,
      full,
      alias,
    }
  })
}

export function parseUsername(str) {
  const matches = getAllMatches(str, usernameRe)
  return matches.map(match => {
    const username = getGroupItem('username', match)
    const full = getGroupItem('full', match)

    return {
      full,
      username,
    }
  })
}

export function renderUsername(str, uid, contactsStore) {
  const usernames = parseUsername(str)

  usernames.forEach(item => {
    let hasContact = contactsStore.searchContact(item.username, 'wid')

    hasContact = hasContact
      ? hasContact
      : contactsStore.searchContact(item.username, 'contact_id')

    hasContact = hasContact
      ? hasContact
      : contactsStore.hasContact(item.username)

    const route = !hasContact
      ? `#/contacts${qs.stringify(
          {
            username: item.username,
            createNew: true,
          },
          true
        )}`
      : `#/queries${qs.stringify(
          {
            query: `${item.username} is:expanded="true" get:contact-definition="${item.username}" on:contacts="true"`,
          },
          true
        )}`

    const html = `
      <a href="${route}" class="username--local hover:underline cursor-pointer" data-uid="${uid}">@${
      hasContact ? hasContact.name : item.username
    }</a>
    `.trim()

    str = str.replace(item.full, html)
  })

  return str
}

export function getFileName(fileURL) {
  const _fileRe = /^((file|http(s)?):\/\/.+\/)(.+)$/gi

  const fileName = fileURL.replace(_fileRe, '$4')

  return fileName
}

export function parseDeadlines(str) {
  const matches = getAllMatches(str, dateRe)
  return matches.map(match => {
    const day = getGroupItem('day', match)
    const month = getGroupItem('month', match)
    const year = getGroupItem('year', match)
    const full = getGroupItem('full', match)

    return {
      day,
      month,
      year,
      full,
    }
  })
}

export function renderDeadlines(str, uid) {
  const deadlines = parseDeadlines(str)
  deadlines.forEach(item => {
    const html = `
      <a title="${dayjs(item.full, 'D/M/YYYY').format(
        'dddd, ll'
      )}" href="#/queries${qs.stringify(
      { query: `${item.full} get:deadline="${item.full}"` },
      true
    )}" class="deadline--local hover:underline cursor-pointer" data-uid="${uid}">${
      item.full
    }</a>
    `.trim()

    str = str.replace(item.full, html)
  })

  return str
}

export function parsePerformSelection(string) {
  const performSelection = getPerformSelection(string)

  performSelection.forEach(match => {
    string = string.replace(
      match.full,
      `<span class="text-gray-500 border-b border-gray-400 border-dotted">${match.text}</span>`
    )
  })

  return string
}

export function getPerformSelection(str) {
  const matches = getAllMatches(str, performSelectionRe)
  return matches.map(match => {
    const full = getGroupItem('full', match)
    const text = getGroupItem('text', match)

    return {
      full,
      text,
      index: match.index,
    }
  })
}

export function getNextSubtag(maintag, subtag) {
  const states = metaTags.getStates(maintag) || []

  const index = states.findIndex(item => item.value === subtag)
  return states[index + 1 < states.length ? index + 1 : 0]
}

export function hasMetaTag(maintag) {
  const result = metaTags.findTag(maintag)
  return !!result
}

export function hasMetaTagState(maintag, subtag) {
  const states = metaTags.getStates(maintag) || []
  const result = states.find(item => item.value === subtag)
  return !!result
}

export function incrementSubtag(string, maintag, subtag) {
  const nextSubtag = getNextSubtag(maintag, subtag)
  return string.replace(
    `#${maintag}:${subtag}`,
    `#${maintag}:${nextSubtag.value}`
  )
}

export function parseQueryFeed(string) {
  let re = /(?<lhs>.+)(?<operand>->|<-)(?<rhs>.+)/gi
  const matches = getAllMatches(string, re)

  return matches.map(match => {
    const lhs = getGroupItem('lhs', match)
    const rhs = getGroupItem('rhs', match)
    const operand = getGroupItem('operand', match)

    return {
      operand,
      lhs,
      rhs,
    }
  })
}

export function parseMetaTags(string, getOnlySaved) {
  const re = /\B(?<full>#(?<maintag>\w+):(?<subtag>\w+)#)(?=($| ))/g

  const matches = getAllMatches(string, re) || []

  return matches
    .map(match => {
      const maintag = getGroupItem('maintag', match)
      const subtag = getGroupItem('subtag', match)
      const full = getGroupItem('full', match)

      if (getOnlySaved) {
        const tagData = metaTags.findTag(maintag)

        if (tagData) {
          const states = tagData.states
          const isStateValid = states.find(state => state.value === subtag)

          if (isStateValid) {
            return {
              maintag,
              full,
              subtag,
              index: match.index,
              tagData,
              states,
              state: isStateValid,
            }
          }
        }
      } else {
        return {
          maintag,
          full,
          subtag,
          index: match.index,
        }
      }
    })
    .filter(item => item)
}

export function doubleHastagParser(value, id) {

  const metaTags = parseMetaTags(value, true)
  for (let match of metaTags) {
    const _hideTags = get(hideTags)
    
    const { maintag, subtag, full, index, tagData, states, state } = match || {}
    if (tagData) {
      recentTags.addTag({
        value: full,
        label: full,
        tagData,
      })
      
      const tags = state.icon && parseMetaData(state.icon)
      const tag = tags && tags[0]
      
      if (tag && tag.name && tag.name.trim().toLowerCase() === 'icon') {
        state.icon = `<i class="icon ${tag.value}"></i>`
      }

      let html = `<a class="id-${id} statefull-metatags cursor-pointer doubletag-icon" data-uid="${id}" data-maintag="${maintag}" data-subtag="${subtag}">${
        state.icon
          ? `<i class="${state.icon} icon"></i>`
          : `<span class="metaTagsClass">${full}</span>`
      }</a>`

      if (state.type) {
        switch (state.type) {
          case 'Date':
            html = `<span class="date-picker-container"><button data-tag="${maintag}:${subtag}" data-replace-tag="${maintag}:${subtag}:__picked_date__" class="hover:text-gray-500 date-picker text-md"><i class="calendar alternate outline icon"></i></button></span>`
            break
        }
      }

      if(_hideTags){
        html = ``
      }
      value = value.betterReplace(full, html, index)
    }
  }
  return value
}
export function getNumberAtPos(str, pos) {
  // Perform type conversions.
  str = String(str)
  pos = Number(pos) >>> 0

  // Search for the word's beginning and end.
  var left = str.slice(0, pos + 1).search(/\d+$/),
    right = str.slice(pos).search(/\d/)

  // The last word in the string is a special case.
  if (right < 0) {
    return null
  }
  // Return the word, using the located bounds to extract it from the string.
  const number = str.slice(left, right + pos)

  return {
    number,
    start: left,
    end: right + pos,
  }
}

export function isRedditURL(string) {
  return string.includes('reddit')
}

export function isLinkNode(string) {
  let matches = tokenizeMarkdownLinks(string)
  if (matches.length) {
    let match = matches[0]

    if (match.name === '-') {
      return match
    }
  }
  return false
}

export function isElementInViewport(el) {
  // Special bonus for those using jQuery
  if (typeof jQuery === 'function' && el instanceof jQuery) {
    el = el[0]
  }

  var rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  )
}
export async function getInView(selector) {
  await tick()
  let activeNode = document.querySelector(selector)
  activeNode && activeNode.scrollIntoViewIfNeeded()
}

export function getJsonURL(url) {
  if (!/\.json$/.test(url)) {
    return urlJoin(url, '.json')
  }
  return url
}

export function isBodyInFocus() {
  return document.activeElement === document.body
}

export function tokenizeMarkdownLinks(string = "") {
  let matches = getAllMatches(string, markdownLink)

  return matches.map(match => {
    let url = getGroupItem('url', match)
    const cellRef = _.first(parseCellRefs(url))
    const seekTime = getGroupItem('seekTime', match)
    const time = seekTime && _.first(parseTime(seekTime))
    const endTime = time && time.end
    return {
      full: getGroupItem('full', match),
      name: getGroupItem('name', match),
      url,
      cellRef,
      seekTime: getGroupItem('seekTime', match),
      seekTimeFull: getGroupItem('seekTimeFull', match),
      seekValue: time,
      seekEndTime: endTime,
      index: match.index,
      parsedName: _.escape(getGroupItem('name', match)),
    }
  })
}

export function getCodeBlocks(string, filterOutput) {
  let matches = getAllMatches(string, codeBlocksRE)
  matches = matches.map((match, i) => {
    let code = getGroupItem('code', match)
    let full = getGroupItem('full', match)
    let begin = getGroupItem('begin', match)
    let end = getGroupItem('end', match)
    let language = getGroupItem('language', match)
    return {
      code,
      fullExpression: full,
      index: i,
      begin,
      end,
      language:
        (filterOutput && language && language.replace('\n', '')) || language,
    }
  })
  return matches
}

export function parseChat(string) {
  const codeBlocks = getCodeBlocks(string, true)
  return codeBlocks.filter(block => block.language === 'woandar-chat')
}

export function updateCodeBlock(string, updateValue, index) {
  let codeBlocks = getCodeBlocks(string)
  let codeBlock = codeBlocks ? codeBlocks[index] : {}
  return string.replace(
    codeBlock.fullExpression,
    codeBlock.begin + codeBlock.language + updateValue + codeBlock.end
  )
}

export function appendToString(mainString = '', appendString = '') {
  return `${mainString}${
    mainString && mainString.endsWith(' ')
      ? appendString
      : mainString
      ? ' ' + appendString
      : appendString
  }`
}

export function renderChats(string, id) {
  let chats = parseChat(string)

  chats.forEach(block => {
    string = string.replace(
      block.fullExpression,
      `<div data-index="${block.index}" data-uid="${id}" class="id-${id} woandar-chat-embed"></div>`
    )
  })
  return string
}

export function renderCodeBlocks(string, id) {
  let codeBlocks = getCodeBlocks(string)
  codeBlocks.forEach(block => {
    string = string.replace(
      block.fullExpression,
      `<div data-ischat="${block.language === 'woandar-chat'}" data-index="${
        block.index
      }" data-uid="${id}" class="id-${id} codeblock--local">${block.code}</div>`
    )
  })
  return string
}

export function handleLocalHyperLink(string, uid) {
  let matches = getAllMatches(string, localHyperLink)
  matches = matches.map((match, i) => {
    let full = getGroupItem('full', match)
    let url = getGroupItem('url', match)
    string = string.replace(
      full,
      `<a href="${url}" data-localhyperlink="true" data-uid="${uid}" class="page--local hover:underline cursor-pointer" style="color: rgb(3, 102, 214)">${full}</a>`
    )
  })
  return string
}

export function getPageFromLocation(location) {
  location = location.split('/')
  if (location.length === 0) return ''
  if (location.length === 2 && !location[0] && !location[1]) return '/'
  if (location.length === 2 && !location[0] && location[1]) return location[1]
  if (location.length === 3 && !location[0] && location[1] && location[2])
    return location[1]
  return ''
}
export function getMetaInformation(string) {
  let commands = parseCommands(string)
  let pages = getPages(string)
  pages = pages
    .map(page => {
      if (!commands.find(command => command.type === page.name.toLowerCase()))
        return `${page.fullExpression}`
    })
    .filter(item => item)
  commands = commands.map(command => `${command.fullExpression}`)

  return [...commands, ...pages].join(' ')
}
export function parseHighlightText(string) {
  let matches = getAllMatches(string, highlightTextRE)
  matches.forEach(match => {
    let text = getGroupItem('text', match)
    let full = getGroupItem('full', match)
    string = string.replace(
      full,
      `<span style="background:#f6e05e">${text}</span>`
    )
  })
  return string
}

export function getMarkdownLinks(string) {
  const matches = getAllMatches(string, markdownLink)

  return _.chain(matches)
    .map(match => ({
      full: getGroupItem('full', match),
      url: getGroupItem('url', match),
      name: getGroupItem('name', match),
      parsedName: _.escape(getGroupItem('name', match)),
      index: match.index,
    }))
    .value()
}

function perc2color(perc) {
  var r,
    g,
    b = 0
  if (perc < 50) {
    r = 255
    g = Math.round(5.1 * perc)
  } else {
    g = 255
    r = Math.round(510 - 5.1 * perc)
  }
  var h = r * 0x10000 + g * 0x100 + b * 0x1
  return hexToRgb('#' + ('000000' + h.toString(16)).slice(-6))
}

export function updateMarkdownLink(
  string,
  { title, url } = {},
  linkAccuranceIndex = 0
) {
  const markdownLinks = getMarkdownLinks(string) || []
  const target = markdownLinks[linkAccuranceIndex]

  if (target && target.full && (title || url)) {
    const currentTitle = target.name
    const currentURL = target.url
    const fullMarkdownLink = target.full

    let updatedMarkdownLink = fullMarkdownLink

    if (title) {
      updatedMarkdownLink = updatedMarkdownLink.replace(currentTitle, title)
    }

    if (url) {
      updatedMarkdownLink = updatedMarkdownLink.replace(currentURL, url)
    }

    string = string.replace(fullMarkdownLink, updatedMarkdownLink)
  }

  return string
}

export function addScore(string = '', score) {
  if (score) {
    return `${string} {{[[SCORE ${score}]]}}`
  }
  return string
}

export function updateScore(string = '', score) {
  if (score && getScore(string)) {
    return string.replace(scoreRE, `{{[[SCORE ${score}]]}}`)
  }
  return string
}

export function removeScore(string) {
  return string.replace(scoreRE, '')
}

export function getScore(string) {
  if (scoreRE.test(string)) {
    return +string.replace(scoreRE, '$2')
  }
  return null
}

export function getMarkdownLinkEmbed(string) {
  let matches = getAllMatches(string, markdownLinkEmbed)

  return matches.map(match => {
    let full = getGroupItem('full', match)
    let name = getGroupItem('name', match)
    let url = getGroupItem('url', match)

    return {
      full,
      name,
      url,
    }
  })
}

export function getMarkdownImages(string) {
  let matches = getAllMatches(string, markdownImage)

  return matches.map(match => {
    let full = getGroupItem('full', match)
    let name = getGroupItem('name', match)
    let url = getGroupItem('url', match)
    return {
      full,
      name,
      url,
    }
  })
}

export function getMarkdownVideos(string) {
  let matches = getAllMatches(string, markdownVideo)

  return matches.map(match => {
    let full = getGroupItem('full', match)
    let name = getGroupItem('name', match)
    let url = getGroupItem('url', match)
    let seekTime = getGroupItem('seekTime', match)
    // let mergelist = getGroupItem('mergelist', match)
    let seekTimeFull = getGroupItem('seekTimeFull', match)

    return {
      full,
      name,
      url,
      seekTime,
      seekTimeFull,
    }
  })
}

export function getMarkdownFileLink({ mimeType, fileName, fileUrl } = {}) {
  return `${getFilePrefixForLink(mimeType)}[${fileName}](${fileUrl})`
}

export function getMarkdownUrlLink(url, { name, mimeType } = {}) {
  try {
    const fileName = getFileName(url)
    return `${getFilePrefixForLink(mimeType || name)}[video](${url})`
  } catch (err) {
    return `${getFilePrefixForLink(name)}[untitled](${url})`
  }
}

export function getFilePrefixForLink(mimeType) {
  // const fileType = getFileType(mimeType)
  const fileType = undefined

  switch (fileType) {
    case 'image':
      return ''

    case 'video':
      return ''

    default:
      return ''
  }
}

function isVideo(link) {
  const name = getFileName(link)
  // return getFileType(name) === 'video'
}

export function toSeconds(time) {
  const { seconds = 0, minutes = 0, hours = 0 } = time || {}
  return seconds + minutes * 60 + hours * 60 * 60
}

export function parseMarkdownVideos(
  string,
  store,
  uid,
  isRemote,
  renderMedia,
  isEncrypted
) {
  let matches = getMarkdownVideos(string)

  matches.forEach(match => {
    let full = match.full
    let name = match.name
    let url = match.url
    let seekTime = match.seekTime
    let seekTimeFull = match.seekTimeFull

    const time = _.first(parseTime(seekTime || name))

    if (isRemote) {
      handleRemoteFiles({
        url,
        uid,
        store,
        isEncrypted,
        name,
      })
    } else {
      handleLocalFiles({
        url,
        uid,
        store,
      })
        .then(_url => {
          if (_url !== url) {
            store.updateString(uid, string => string.replace(url, _url))
          }
        })
        .catch(err => err)
    }

    if (renderMedia) {
      let fileURL

      if (!url.includes('youtube')) {
        fileURL = url
      }

      string = string.replace(
        full,
        `<span data-name="${name}" data-seektime="${seekTime}" data-src="${fileURL ||
          url}" data-uid="${uid}" ${
          time && time.end ? `data-seekend="${toSeconds(time.end)}"` : ''
        } data-seek="${toSeconds(time)}" class="video-player-local"><video ${
          time ? `data-seek="${toSeconds(time)}"` : ''
        } class="js-player w-full" controls src="${url}" /></span>`
      )
    } else {
      string = string.replace(full, `<a><i class="video icon"></i> ${name}</a>`)
    }
  })

  return string
}

function handleFileCaching({ store, url, isEncrypted, uid }) {
  let fileURL = url
  if (!url.includes('file://')) {
    if (isEncrypted && store && store.options && store.options.keys) {
      fileURL = fileStore.getFileURL(
        url,
        true,
        store.options.keys.privateKey,
        () => {
          store.refreshNode(uid)
          store.triggerChangeEvent()
        },
        store
      )
    } else {
      fileURL = fileStore.getFileURL(url, true)
    }
  }

  return fileURL
}

export async function handleLocalFiles({ url }) {
  if (!url.startsWith('blob')) return url
  try {
    const mediaId = randomUid.default()
    const newUrl = `cache-cdn/media/${mediaId}`
    const cache = await caches.open('file-store')
    const res = await fetch(url)
    await cache.put(newUrl, res)
    return `https://cdn.notebrowser.com/cache-cdn/media?id=${mediaId}`
  } catch (err) {
    console.error(err)
  }
}

async function handleRemoteFiles({
  url,
  uid,
  store,
  isEncrypted,
  name,
  isVideo,
}) {
  // const notifications = require('./../stores/notifications').default
  // if (url.startsWith('blob:')) {
  //   if (isEncrypted && store && store.options && store.options.keys) {
  //     const publicKey = store.options.keys.publicKey
  //     const context = null

  //     navbarMessage.set(`Encrypting video...`)

  //     _encryption
  //       .encryptFile(url, publicKey, {
  //         context,
  //       })
  //       .then(encText => {
  //         if(!encText) {
  //           throw new Error(`no_encryption_text`)
  //         }

  //         navbarMessage.set(`Uploading video...`)
  //         const remoteUpload = new RemoteUploadBackblaze()
          
  //         return remoteUpload.uploadFile(new Blob([encText]), {
  //           folder: `videos/${store.wid}`,
  //           fileName: randomUid.default(),
  //         })
  //       })
  //       .then(downloadUrl => {

  //         if(downloadUrl){
  //           downloadUrl = downloadUrl.replace(API_URL, "https://upload.notebrowser.com")
  
  //           isVideo &&
  //             videoLog.addVideo({
  //               url: downloadUrl,
  //               isEncrypted: true,
  //               name,
  //             })
  //           store.updateString(uid, string => string.replace(url, downloadUrl))
  //           navbarMessage.set(`Video uploaded!`)

  //           setTimeout(() => {
  //             navbarMessage.set(``)
  //           }, 1000)
  //         }else{
  //           throw new Error(`Remote upload failed for ${url}`)
  //         }
  //       })
  //       .catch(err => {

  //         navbarMessage.set(`Upload Failed`)

  //         setTimeout(() => {
  //           navbarMessage.set(``)
  //         }, 1000)

  //         // debugger

  //         if(err.message === "no_encryption_text") {
  //           return
  //         }
  //         questions.ask([
  //           {
  //             text: "Failed to upload file. Would you like to try again?",
  //             options: [
  //               "Yes",
  //               "No, save file locally",
  //               "Cancel"
  //             ],

  //             selected: "Yes",

  //             onSelect: async (item) => {
  //               const value = item.value || ""
  //               if(value.startsWith("No")){
  //                 await downloadFileLocally({
  //                   url, name
  //                 })

  //                 store.updateString(uid, string => string.replace(url, ``))

  //               }else if(value.startsWith("Yes")){
  //                 handleRemoteFiles({
  //                   url,
  //                   uid,
  //                   store,
  //                   isEncrypted,
  //                   name,
  //                   isVideo,
  //                 })
  //               }else if(value.startsWith("Cancel")){
  //                 store.updateString(uid, string => string.replace(url, ''))
  //               }

  //               questions.done()
  //             }
  //           }
  //         ])
  //       })
  //   } else {
  //     // remoteStorage.uploadFile(
  //     //   url,
  //     //   downloadURL => {
  //     //     store.updateString(uid, string => string.replace(url, downloadURL))
  //     //   },
  //     //   {
  //     //     showNotification: true,
  //     //   }
  //     // )
  //   }
  // }
}
async function downloadFileLocally({
  url, name
}){
  try{
    const blob = await fetch(url).then(res => res.blob())

    const fileName = name || randomUid.default()

    await saveAs(blob, fileName)
  }catch(err){
    console.error(err)
  }
}

function getGitos(string) {
  const markdownLinks = getMarkdownLinks(string)
  return markdownLinks.filter(item => {})
}

export function parseMarkdownGitos(
  string,
  store,
  uid,
  isRemote,
  renderMedia,
  isEncrypted
) {
  let matches = getAllMatches(string, markdownGitos)

  matches.forEach(match => {
    let full = getGroupItem('full', match)
    let name = getGroupItem('name', match)
    let url = getGroupItem('url', match)

    if (isRemote) {
      handleRemoteFiles({
        url,
        uid,
        store,
        isEncrypted,
        name,
      })
    } else {
      handleLocalFiles({
        url,
        uid,
        store,
      })
        .then(_url => {
          if (_url !== url) {
            store.updateString(uid, string => string.replace(url, _url))
          }
        })
        .catch(err => err)
    }

    let fileURL = url

    string = string.replace(
      full,
      `<a href="${fileURL}" class="local-gitos">${name}</a>`
    )
  })
  return string
}

export function parseMarkdownImages(
  string,
  store,
  uid,
  isRemote,
  renderMedia,
  isEncrypted
) {
  let matches = getAllMatches(string, markdownImage)

  matches.forEach(match => {
    let full = getGroupItem('full', match)
    let name = getGroupItem('name', match)
    let url = getGroupItem('url', match)
    // console.log(url, match)

    if (isRemote) {
      handleRemoteFiles({
        url,
        uid,
        store,
        isEncrypted,
        name,
      })
    } else {
      handleLocalFiles({
        url,
        uid,
        store,
      })
        .then(_url => {
          if (_url !== url) {
            store.updateString(uid, string => string.replace(url, _url))
          }
        })
        .catch(err => err)
    }

    let fileUrl = url
    string = string.replace(
      full,
      `<span class="id-${uid} image-markdown-local" data-name="${name}" data-uid="${uid}" data-encrypted="${isEncrypted}" data-src="${fileUrl ||
        url}"></span>`
    )
  })
  return string
}

export function isAppCdnUrl(url) {

  return url.includes('upload.notebrowser') && !url.includes('blob')
}

export const videoBlobData = {}

export async function handleDownloadEncFile({ url, privateKey }) {
  // try {

  //   if(videoBlobData[url]) {
  //     return videoBlobData[url]
  //   }
  //   const cache = await caches.open('file-store')
  //   const match = await cache.match(url)

  //   if (match) {
  //     const blob = await match.blob()
  //     const newUrl =  URL.createObjectURL(blob)

  //     videoBlobData[url] = newUrl

  //     return newUrl
  //   } else {
  //     const file = await _encryption.decryptFile(url, privateKey)

  //     const res = new Response(file, {
  //       headers: {
  //         'content-type': 'video/webm',
  //       },
  //     })
  //     const tempBlob = await res.blob()

  //     await cache.put(
  //       url,
  //       new Response(file, {
  //         headers: {
  //           'content-type': 'video/webm',
  //         },
  //       })
  //     )
  //     const newUrl =  URL.createObjectURL(tempBlob)
  //     videoBlobData[url] = newUrl
  //   }
  // } catch (err) {
  //   throw err
  // }
}

export function parseMarkdownLinkEmbed(str, uid) {
  const matches = getMarkdownLinkEmbed(str)

  matches.forEach(({ name, url, full, parsedName } = {}) => {
    const parsedURL = shurley.parse(url)
    const _html = `<span data-uid="${uid}" data-embed="true" data-name="${parsedName}" data-href="${parsedURL}" class="markdown-external-link"></span>`
    str = str.replace(full, _html)
  })
  return str
}

export function parseHyperlink(str) {
  const re = /^(woandar:\/\/)([\S]+)$/g

  if (re.test(str)) {
    const data = str.replace(re, '$2')
    return qs.parse(data)
  }
}

export function parseMarkdownLinks(string, store, uid, isRemote) {
  let matches = tokenizeMarkdownLinks(string)
  let nodeLink = isLinkNode(string)

  if (nodeLink) {
    string = string.replace(nodeLink.full, '')
  }
  matches.forEach(
    ({
      full,
      name,
      url,
      seekTime,
      seekValue,
      seekEndTime,
      parsedName,
    } = {}) => {
      let pages = getPages(url)
      let cellRefs = parseCellRefs(url)

      if (pages.length) {
        pages.forEach(page => {
          let uid = store.getPageId(page.name, true)
          string = string.replace(
            full,
            `<a title="page: ${page.name}" href="#/page/${uid}" data-uid="${uid}" class="page--local hover:underline cursor-pointer" style="color: rgb(3, 102, 214)">${name}</a>`
          )
        })
        return
      }
      if (cellRefs.length) {
        cellRefs.forEach(cell => {
          const uid = cell.ref
          const store = cell.store
          const workspace = cell.workspace

          const queryParams = qs.stringify(
            {
              store,
              workspace,
            },
            true
          )

          const href = `#/page/${uid}${queryParams}`
          string = string.replace(
            full,
            `<a data-isinternal="true" href="${href}" ${
              seekEndTime ? `data-seekend="${toSeconds(seekEndTime)}"` : ''
            } ${
              seekValue ? `data-seek="${toSeconds(seekValue)}"` : ''
            } data-store="${cell.store}" data-workspace="${
              cell.workspace
            }" title="cell: ${uid}" data-uid="${uid}" class="video-time-link cell--local--markdown inline-block text-blue-600 hover:underline cursor-pointer" style="color: rgb(3, 102, 214)">${
              seekValue ? `<i class="clock outline icon"></i>` : ''
            }${name}</a>`
          )
        })
        return
      }

      const parsedURL = isMagnetLink(url) ? url : shurley.parse(url)

      if (youtubeUrl.valid(parsedURL) || isVideo(url)) {
        string = string.replace(
          full,
          `<span data-name="${name}" data-minimized="true" data-seektime="${seekTime}" data-src="${url}" data-uid="${uid}" class="video-player-local"><video class="js-player w-full" controls src="${parsedURL}" /></span>`
        )
      } else {
        const _html = `<span data-uid="${uid}" data-name="${parsedName}" data-href="${parsedURL}" class="markdown-external-link text-blue-600"></span>`
        string = string.replace(full, _html)
      }
    }
  )

  return string
}

export function followCellRefs({ id, store, originalId = id }) {
  if (!store || !id) throw new Error('store and id is required')

  const string = store.getString(id)

  if (!string) return
  const cellRefs = parseCellRefs(string, id)
}

export function renderCellRefs(str, id) {
  let cellRefs = parseCellRefs(str, id)

  cellRefs.forEach(cell => {
    const uid = cell.ref
    const store = cell.store
    const workspace = cell.workspace

    const queryParams = qs.stringify(
      {
        store,
        workspace,
      },
      true
    )

    const href = `#/page/${uid}${queryParams}`

    str = str.replace(
      cell.fullExpression,
      `<span class="cursor-pointer" >${
        cell.isParentRef
          ? `<i class="venus double icon"></i>`
          : cell.isChildRef
          ? `<i class="venus icon"></i>`
          : ''
      }<a data-uid="${uid}" data-currentuid="${id}" data-store="${store}" data-workspace="${workspace}" title="cell: ${uid}" href="${href}" class="cell--local bg-base-100 hover:bg-base-100 hover:underline cursor-pointer">${`((${cell.ref}))`}</a></span>`
    )
  })

  return str
}

export function parseAttributes(str) {
  let attributesRe = /(?<full>\b(?<action>\w+?):(?<attribute>[\S]+?)(?<condition>=|>|<)\"(?<value>.*?)\")/gi

  const matches = getAllMatches(str, attributesRe)
  // console.log(str, matches, attributesRe)

  return matches.map(match => {
    const full = getGroupItem('full', match)
    const action = getGroupItem('action', match)
    const attribute = getGroupItem('attribute', match)
    const value = getGroupItem('value', match)
    const condition = getGroupItem('condition', match)
    return {
      full,
      attribute,
      action,
      value,
      condition,
      index: match.index,
    }
  })
}

export function handleNewCells(str, store) {
  try {
    if (!str || !store) return str
    const cells = parseCellRefs(str)

    cells.forEach(cell => {
      const ref = cell.ref.trim()
      const item = store.getItem(ref)

      if (cell.store || cell.workspace) return

      if (cell.index - 1 < 0 || str[cell.index - 1] !== ' ') return

      if (!item || _.isEmpty(item)) {
        const item = store.addNode()
        store.updateString(item.uid, string => `${ref}`)
        store.addChildren(item.uid)
        if (item) {
          str = str.replace(cell.fullExpression, `[${ref}](((${item.uid})))`)
        }
      }
    })
    return str
  } catch (err) {
    console.error(err)
    return str
  }
}

export function parseCellRefs(str, id) {
  const allMatches = getAllMatches(str, cellRefRE)
  const parsedCellRefs = allMatches.map(match => {
    const ref = getGroupItem('id', match)
    const direction = getGroupItem('direction', match)
    const store = getGroupItem('store', match)
    const workspace = getGroupItem('workspace', match)

    const fullExpression = getGroupItem('full', match)

    return {
      index: match.index,
      ref,
      fullExpression,
      store,
      workspace,
      isChildRef: direction === '>' ? true : false,
      isParentRef: direction === '<' ? true : false,
    }
  })

  return parsedCellRefs
}

export function handleTodo(str) {
  let isTodo = hasCommand(str, commandTypes.todo)
  let isDone = hasCommand(str, commandTypes.done)
  let isPaused = hasCommand(str, commandTypes.paused)
  if (!isTodo && !isDone && !isPaused) {
    str = addTodo(str)
  }
  if (isTodo) {
    str = replaceCommand(str, commandTypes.todo, '[[PAUSED]]')
  }

  if (isPaused) {
    str = replaceCommand(str, commandTypes.paused, '[[DONE]]')
  }
  if (isDone) {
    str = str.replace('{{[[DONE]]}}', '').trim()
  }
  return str
}

export function toggleTodo(str) {
  let isTodo = hasCommand(str, commandTypes.todo)
  if (isTodo) {
    str = replaceCommand(str, commandTypes.todo, '[[DONE]]')
  } else {
    str = replaceCommand(str, commandTypes.done, '[[TODO]]')
  }
  return str
}

export function addTodo(str) {
  return `{{[[TODO]]}} ${str}`
}

export function hexToRgb(hex) {
  const [r, g, b] = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16))

  return `rgb(${r},${g},${b})`
}

export function hasPage(string, pageName) {
  const pages = getPages(string)
  return pages.some(page => page.name.toLowerCase() === pageName.toLowerCase())
}

export function renderCommands(string, id, store) {
  const parsedCommands = parseCommands(string)

  const commandHTML = {
    [commandTypes.todo]: `<input type="checkbox" class="command-todo todo" data-uid="${id}"/>`,
    [commandTypes.done]: `<input type="checkbox" checked class="command-done todo" data-uid="${id}"/>`,
    [commandTypes.paused]: `<span class="command-paused text-gray-600 cursor-pointer" data-uid="${id}"><i class="pause circle icon"></i></span>`,
    [commandTypes.date]: `<button class="datepicker p-1 bg-base-100 hover:bg-gray-400  rounded-lg"><i class="calendar alternate outline icon mx-auto"></i></button>`,
    [commandTypes.bug]: `<button class="datepicker p-1 bg-gray-600 hover:bg-gray-400 text-base-content rounded-lg"><i class="calendar alternate outline icon mx-auto"></i></button>`,
  }

  parsedCommands.forEach(command => {
    if (!command) return

    if (command.type === commandTypes.score) {
      const score = command.commandStr.replace(/\[\[.+?([0-9]+)\]\]/g, '$1')
      string = string.replace(
        command.fullExpression,
        `<a href="#/page/${id}" data-uid="${id}" class="page--local bg-gray-400 px-2 py-1 text-sm rounded no-underline" style="background: ${perc2color(
          (score / 10) * 100
        )}; color: rgb(0,0,0)" >${score}<a>`
      )
    }
    if (command.type === commandTypes.search) {
      let commandRE = /\[\[.+?\]\]: (?<search>.+)/g
      let {
        groups: { search },
      } = commandRE.exec(command.commandStr) || {
        groups: {
          search: '',
        },
      }
      command.commandStr.match(/\[\[.+?\]\]: (?<search>.+)/)
      let s = new URLSearchParams({
        q: search,
      })
      string = string.replace(
        command.fullExpression,
        `[<i class="search icon"></i> ${search}](https://www.google.com/search?${s.toString()})`
      )

      return
    }

    if (command.type === commandTypes.goal && store) {
      const score = store.getTasksScore(id)

      if (score) {
        string = string.replace(
          command.fullExpression,
          `<span class="bg-gray-600 text-base-content text-xs px-1 rounded font-bold">${score.completed}/${score.total}</span>`
        )
      }
    }
    const html = commandHTML[command.type]
    if (html) string = string.replace(command.fullExpression, html)
  })

  return string
}

export function hasCommand(string, type) {
  let parsedCommands = parseCommands(string)
  return parsedCommands.find(command => command.type === type)
}

export function replaceCommand(string = '', type = '', replaceStr = '') {
  let todo = hasCommand(string, type)
  if (todo) {
    string = string.replace(todo.commandStr, replaceStr)
  }
  return string
}

export function parseCommands(string) {
  const allMatches = getAllMatches(string, commandsRE)

  const parsedCommands = allMatches.map(match => {
    const command = getGroupItem('command', match)
    const commandFull = getGroupItem('commandFull', match)

    let pages = getPages(command)
    let page = pages.length && pages[0]

    const name = page && page.name && page.name.toLowerCase()

    if (page && page.name && commandTypes[name]) {
      return {
        type: commandTypes[name],
        fullExpression: commandFull,
        commandStr: command,
      }
    }

    if (page && page.name.includes('SCORE')) {
      return {
        type: commandTypes.score,
        fullExpression: commandFull,
        commandStr: command,
      }
    }
  })

  return _.compact(parsedCommands)
}

export function parseHashtags(str, id, store) {
  const matches = getAllMatches(str, new RegExp(hashtagRe))
  return matches.map(match => {
    const full = getGroupItem('full', match)
    const value = getGroupItem('value', match)

    return {
      full,
      value,
    }
  })
}

export function renderHashtash(str) {
  const matches = parseHashtags(str)

  matches.forEach(match => {
    str = str.replace(
      match.full,
      `<a href="#" data-value="${match.value}" class="hashtag--local text-gray-600 font-medium hover:bg-base-100 cursor-pointer">#${match.value}</a>`
    )
  })

  return str
}

export function renderInternalLinks(str, id, store) {
  if (!store) return str

  str = doubleHastagParser(str, id)
  const pages = getPages(str, id)

  pages.forEach(page => {
    let uid = store.getPageId(page.name, true)
    if (!uid) return
    switch (page.type) {
      case pageTypes.bracket:
        str = str.replace(
          page.fullExpression,
          `<span class="text-gray-500">[[</span><a title="page: ${page.name}" href="#/page/${uid}" data-uid="${uid}" class="page--local hover:underline cursor-pointer" style="color: #0366d6">${page.name}</a><span class="text-gray-500">]]</span>`
        )
        break

      // case pageTypes.hash:
      //   str = str.replace(
      //     page.fullExpression,
      //     `<a href="#/page/${uid}" title="page: ${page.name}" data-uid="${uid}" class="page--local text-gray-600 font-medium hover:bg-base-100 cursor-pointer">#${page.name}</a>`
      //   )
      //   break

      // case pageTypes.hashBracket:
      //   str = str.replace(
      //     page.fullExpression,
      //     `<a href="#/page/${uid}" title="page: ${page.name}" data-uid="${uid}" class="page--local text-gray-600 font-medium hover:bg-base-100 cursor-pointer">#${page.name}</a>`
      //   )
      //   break

      case pageTypes.metaData:
        const href = `#/queries${qs.stringify(
          {
            query: `${page.name}:: on:all="true"`,
          },
          true
        )}`

        str = str.replace(
          page.fullExpression,
          `<a href="${href}" title="page: ${page.name}" data-uid="${uid}" class="page--local text-gray-600 font-bold hover:bg-base-100 cursor-pointer " style="color: #444; font-weight: 800">${page.name}:</a>`
        )
        break
    }
  })

  str = renderCellRefs(str, id)
  str = renderPageRefs(str, id)
  return str
}

export function parsePageRefs(str, id) {
  const allMatches = getAllMatches(str, pageRefRE)
  const parsedCellRefs = allMatches.map(match => {
    const ref = getGroupItem('id', match)
    const workspace = getGroupItem('workspace', match)
    const store = getGroupItem('store', match)
    const fullExpression = getGroupItem('full', match)

    return {
      ref,
      fullExpression,
      workspace,
      store,
    }
  })

  return parsedCellRefs
}

export function renderPageRefs(str, id) {
  let cellRefs = parsePageRefs(str, id)
  cellRefs.forEach(cell => {
    const uid = cell.ref
    const store = cell.store
    const workspace = cell.workspace
    str = str.replace(
      cell.fullExpression,
      `<div data-uid="${uid}" data-store="${store}" data-workspace="${workspace}" title="page: ${uid}" href="#/page/${uid}" class="pageref--local"></div>`
    )
  })
  return str
}

export function parseMetaData(str) {
  const metaDataParseRE = /^(?<full>(?<name>.+?)::(?<value>.+)?)/g
  let metaData = getAllMatches(str, metaDataParseRE)

  return metaData.map(match => {
    let name = getGroupItem('name', match)
    let full = getGroupItem('full', match)
    let value = getGroupItem('value', match)

    return {
      name,
      full,
      value,
      index: match.index,
    }
  })
}

export function updateMetaData(
  string,
  { value: _value, name: _name, full: _full }
) {
  const metaBlocks = parseMetaData(string)

  metaBlocks.forEach(({ value, name, full, index } = {}) => {
    string = `${_name || name}:: ${_value || value}`
  })

  return string
}

export function getPages(str) {
  let allMatches = getAllMatches(str, pageRE)
  let metaData = getAllMatches(str, metaDataRE)

  metaData = metaData.map(match => {
    let name = getGroupItem('name', match)
    let full = getGroupItem('full', match)

    if (name) {
      return {
        index: match.index,
        type: pageTypes.metaData,
        name: name,
        fullExpression: full,
      }
    }
  })

  allMatches = allMatches.map(match => {
    let bracketPage = getGroupItem('bracketPage', match)
    let bracketFull = getGroupItem('bracketFull', match)

    let hashBracketPage = getGroupItem('hashBracketPage', match)
    let hashBracketFull = getGroupItem('hashBracketFull', match)

    let hashPage = getGroupItem('hashPage', match)
    let hashFull = getGroupItem('hashFull', match)

    if (bracketPage) {
      return {
        index: match.index,
        type: pageTypes.bracket,
        name: bracketPage,
        fullExpression: bracketFull,
      }
    }

    if (hashBracketPage) {
      return {
        index: match.index,
        type: pageTypes.hashBracket,
        name: hashBracketPage,
        fullExpression: hashBracketFull,
      }
    }

    if (hashPage) {
      return {
        index: match.index,
        type: pageTypes.hash,
        name: hashPage,
        fullExpression: hashFull,
      }
    }
  })
  return [...metaData, ...allMatches]
}

export function getLinkedReferences(item, nodes) {
  let string = item.title || item.string
  let uid = item.uid

  if (!_.isString(string)) {
    console.error('passed parameter is not a string: findLinkedReferences')
    return
  }

  if (_.isObject(nodes)) {
    nodes = _.valuesIn(nodes)
  }

  string = string.trim().toLowerCase()

  const linkedReferences = _.chain(nodes)
    .filter(node => node.string)
    .map((node = {}) => {
      const nodeString = node.string
      const pagesInString = getPages(nodeString)
      const cellRefsInString = parseCellRefs(nodeString)

      const hasPage = pagesInString.find(page => {
        try {
          return page.name.trim().toLowerCase() === string
        } catch (err) {
          console.error(err)
        }
      })

      const hasCellRef = cellRefsInString.find(cell => cell.ref === uid)

      return {
        ...node,
        targetPage: hasPage,
        targetCellRef: hasCellRef,
        pagesFound: pagesInString,
        cellRefsFound: cellRefsInString,
      }
    })
    .filter(item => item.targetPage || item.targetCellRef)
    .value()
  return linkedReferences
}

export function getAllMatches(str, regex) {
  if (str && regex) {
    return [...(str?.matchAll?.(regex) || [])]
  }
  return []
}

export function getGroupItem(groupName, match) {
  return _.get(match, ['groups', groupName])
}

function parse(str = '') {
  // parse string
  str = str.replace(pausedRE, '<span class="hidden bg-black">$1</span>')
  // console.log(str, str.match(doneRE))
  if (str.match(todoRE)) {
    str = str.replace(
      todoRE,
      `<input type="checkbox" class="todo" data-uid="${id}" />`
    )
  }

  if (str.match(doneRE)) {
    str = str.replace(
      doneRE,
      `<input type="checkbox" checked class="todo" data-uid="${id}" />`
    )
  }

  if (str.match(dateRE)) {
    str = str.replace(
      dateRE,
      `<button class="datepicker py-1 px-1 bg-base-100 hover:bg-base-100  rounded-lg"><i class="calendar alternate outline icon"></i></button>`
    )
  }

  ;[...str.matchAll(aliasLinksRE)].forEach(item => {
    let url = _.get(item, ['groups', 'url'])
    let alias = _.get(item, ['groups', 'alias'])

    if (url) {
      // url = parseLocalReferences(url)
      url = url.replace(
        pageBracketsRE,
        `<a  data-pageName="$<page>" class="pageLink text-blue-600 hover:underline cursor-pointer">${alias}</a>`
      )
      url = url.replace(
        cellParenthesisRE,
        `<a  data-id="$<cell>" class="cellLink text-blue-600 hover:underline cursor-pointer">${alias}</a>`
      )
      str = str.replace(item[0], url)
    }
  })

  str = parseLocalReferences(str)

  str = str.replace(
    todoStartedRE,
    `<span class="bg-base-100 text-green-600">$1</span>`
  )
  str = str.replace(
    todoPausedRE,
    `<span class="bg-base-100 text-teal-600">$1</span>`
  )

  str = str.replace(
    todoDoneRE,
    `<span class="bg-base-100 text-yellow-600">$1</span>`
  )
  str = str.replace(
    /(\+[\d]+m)/,
    '<span class="bg-base-100 font-bold">$1</span>'
  )
  // recognise pages

  // add them to references

  // recognise other attributes and parse them

  return md.render(str)
}

function parseLocalReferences(str) {
  ;[...str.matchAll(pageBracketsHashRE)].forEach(item => {
    str = str.replace(
      item[0],
      `<a data-pageName="${item[1]}" class="pageLink text-gray-600 font-medium hover:bg-base-100 cursor-pointer">#${item[1]}</a>`
    )
  })
  ;[...str.matchAll(pageHashtagsRE)].forEach(item => {
    str = str.replace(
      item[0],
      `<a data-pagename="${item[1]}" class="pageLink text-gray-600 font-medium hover:bg-base-100 cursor-pointer">#${item[1]}</a>`
    )
  })
  ;[...str.matchAll(pageBracketsRE)].forEach(item => {
    str = str.replace(
      item[0],
      `[[<a  data-pageName="${item[2]}" class="pageLink text-blue-600 hover:underline cursor-pointer">${item[2]}</a>]]`
    )
  })
  ;[...str.matchAll(cellParenthesisRE)].forEach(item => {
    str = str.replace(
      item[0],
      `((<a  data-id="${item[1]}" class="cellLink text-blue-600 hover:underline cursor-pointer">${item[1]}</a>))`
    )
  })
  return str
}
