import type { ClassValue } from "clsx";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const generateAcceptCode = () => {
	return Math.floor(1000 + Math.random() * 9000);
}

export const getAllIndexedDbDatabases = async (): Promise<string[]> => {
	const databases = await indexedDB.databases();
	return databases.map(database => database.name || "").filter(el => el !== "");
}


export const deleteDatabase = async (name: string): Promise<void> => {
	await indexedDB.deleteDatabase(name);
}



export const removeAllDatabasesWithChunks = async (): Promise<void> => {
	const databases = await indexedDB.databases();
	for (const database of databases) {
		if (database.name) {
			const re = /(.+)\.\w+ - \w{6,12}/gi;
			const match = re.exec(database.name);
			if (match) {
				await deleteDatabase(database.name);
			}
		}
	}
}


export const numberToArrayBuffer = (value: number): ArrayBuffer => {
	const view = new DataView(new ArrayBuffer(16))
	for (var index = 15; index >= 0; --index) {
		view.setUint8(index, value % 256)
		value = value >> 8;
	}
	return view.buffer
}


export const padValueToArrayBuffer = (value: number, arrayBuffer: ArrayBuffer): ArrayBuffer => {
	// append 4 bytes to the array buffer
	const view = new DataView(arrayBuffer)
	view.setUint32(arrayBuffer.byteLength, value, true)
	return arrayBuffer
}


export const appendNumberToArrayBuffer = (value: number, arrayBuffer: ArrayBuffer): ArrayBuffer => {
	// append 4 bytes to the array buffer
	const view = new DataView(arrayBuffer)
	view.setUint32(arrayBuffer.byteLength, value, true)
	return arrayBuffer
}


export const getNumberFromArrayBuffer = (arrayBuffer: ArrayBuffer): number => {
	const view = new DataView(arrayBuffer)
	return view.getUint32(
		arrayBuffer.byteLength - 4,
		true
	)
}



export const divideFileIntoEvenParts = (file: File, parts: number): File[] => {
	const size = file.size;
	let partSize = Math.floor(size / parts);
	const remainder = size % parts;
	const files: File[] = [];
	let offset = 0;
	for (let i = 0; i < parts; i++) {
		const chunk = file.slice(offset, offset + partSize);
		const filePart = new File([chunk], file.name, { type: file.type });
		files.push(filePart);
		offset += partSize;
		if (i === parts - 2) {
			partSize += remainder;
		}
	}
	return files;
}



export const append32BitNumberToArrayBuffer = (value: number, arrayBuffer: ArrayBuffer): ArrayBuffer => {
	const uint8Array = new Uint8Array(arrayBuffer.byteLength + 4);
	uint8Array.set(new Uint8Array(arrayBuffer), 0);
	const numberArray = Uint32Array.from([value]);
	uint8Array.set(new Uint8Array(numberArray.buffer), arrayBuffer.byteLength);
	return uint8Array.buffer
}

export const get32BitNumberFromArrayBuffer = (arrayBuffer: ArrayBuffer): number => {
	const slice = arrayBuffer.slice(arrayBuffer.byteLength - 4, arrayBuffer.byteLength);
	const numberArray = new Uint32Array(slice);
	return numberArray[0]
}



export const autoFocus = (element: HTMLElement): void => {
	element.focus();
}


export const copyToClipboard = async (text: string): Promise<void> => {
	try {
		if (navigator.clipboard) {
			await navigator.clipboard.writeText(text);
		} else {
			const textArea = document.createElement("textarea");
			textArea.value = text;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			document.execCommand("copy");
			document.body.removeChild(textArea);
		}

	} catch (err) {
		console.error(err);
	}
}


export const triggerWhatsappShare = (options: {
	url?: string;
}): void => {
	const url = options.url || window.location.href;
	const text = `${url}`;
	const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}`;
	window.open(whatsappUrl, "_blank");
}