import PouchStore from './classes/PouchDBStore'
// import { appIsReady } from '../components/Layout/store'
// import EncryptionUtils from './../workers/exportEncyptionUtils'

let encryption

function waitUntilAppReady() {
  return new Promise((res, rej) => {
    const timeout = setTimeout(() => {
      res()
    }, 12000)
    const unsub = appIsReady.subscribe(isReady => {
      if (isReady) {
        clearTimeout(timeout)
        res()
        unsub()
      }
    })
  })
}

async function initializeEncyption() {
  console.log(`Initialized encryption`)
  // await waitUntilAppReady()
  if (encryption) return
  encryption = await new EncryptionUtils()
}

class EncryptionStore extends PouchStore {
  constructor(options = {}, key, { db } = {}) {
    options = {
      wid: null,
      privateKey: null,
      publicKey: null,

      keys: [],
      ...options,
    }
    super(options, { key, db })
  }

  findKey(id, dontGenerateNew) {
    // const key = this.keys.find(item => item.id === id)

    // if (key || dontGenerateNew) {
    //   return key
    // } else {
    //   const _keys = this._generateKeys()
    //   this.addKey({ id, ..._keys })
    // }
  }

  get _wid() {
    return this.wid.replace(/\./g, '_')
  }

  addKey(item) {
    // this.keys = [...this.keys, item]
  }
  async _generateKeys() {
    // return await EncryptionStore.generateKeys()
  }

  _generateWID() {
    return EncryptionStore.generateWID()
  }

  async setUserData({ publicKey, privateKey, wid } = {}) {
    // const keys = await this._generateKeys()
    // this.wid = wid ? wid : this.generateWID()
    // this.publicKey = publicKey || keys.publicKey
    // this.privateKey = privateKey || keys.privateKey
  }

  static async generateKeys() {
    // await initializeEncyption()
    // return await encryption.generateKeys()
  }

  static generateWID() {
    // return [1, 2, 3, 4].map(_ => Math.ceil(Math.random() * 256)).join('.')
  }

  async encryptText(text, publicKey) {
    // await initializeEncyption()
    // const res = await encryption.encryptText(text, publicKey)
    // return res
  }

  async handleTempDecrypt(privateKey) {
    // const text = await fetch(
    //   'file:///C:/Users/acer/Documents/yck18i77vgd.txt'
    // ).then(res => res.text())

    // await initializeEncyption()
    // const dec = await encryption.decryptText(text, privateKey)
    // return JSON.parse(dec)
  }

  async decryptText(text, privateKey) {
    // await initializeEncyption()
    // return await encryption.decryptText(text, privateKey)
  }

  async encryptFile(fileURL, publicKey, { context } = {}) {
    // try {
    //   await initializeEncyption()
    //   const blob = await fetch(fileURL).then(res => res.blob())

    //   console.log({blob})
    //   const encBlob = await encryption.encryptFile(blob, publicKey, { context })
    //   return encBlob
    // } catch (err) {
    //   console.error(err)
    // }
  }

  async decryptFile(url, privateKey) {
    // try {
    //   const encText = await fetch(url).then(res => res.text())
    //   await initializeEncyption()
    //   const decBlob = await encryption.decryptFile(encText, privateKey)
    //   return decBlob
    // } catch (err) {
    //   throw err
    // }
  }
}

const _encryption = new EncryptionStore({}, '_local/key-store')
const backupEncryption = new EncryptionStore({}, '_local/key-store-backup', {})

export default _encryption
export { backupEncryption }
