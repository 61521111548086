import { writable, get } from 'svelte/store'
import { node, types } from './../helpers/helperClasses'
import { Subject } from 'rxjs'
import _, {
  cloneDeep,
  isNumber, 
} from 'lodash'
// import { utils } from './../workers/index'

import {
  getLinkedReferences,
  getPages,
  hasPage,
  todoDoneRE,
  hasCommand,
  metaDataRE,
  parseMetaData,
  parseDeadlines,
  getNodeMarkdownAlias,
  parseTimeRangeData,
  getMarkdownVideos,
  parseHashtags,
  hashtagRe,
  parseCellRefs,
  parseQueries,
  parseMarkdownQueries,
  metaDataParseRE,
  getMarkdownLinks,
  parseMetaTags,
} from './../helpers/utils'
import { push } from './../../common/svelteSpaRouterMock'
import { parseList, isList } from './../helpers/mdListParser.js'
import dayjs from 'dayjs'
import {arrayMoveImmutable as arrayMove} from 'array-move'
import Emitter from 'tiny-emitter'
import Writable from './advancedStore'
import { diff, applyChange } from 'deep-diff'
import { tick } from 'svelte'
import PouchStore from './classes/PouchDBStore'
import {uid} from 'uid'
import metaTags from './metaTags'

import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import WordIndex from './classes/WordIndex'

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)



const _encryption = {
  wid: "encryption",
}


let flexSearchInstance



class Changes extends Writable {
  constructor(options = {}) {
    options = {
      items: [],
      timestamp: null,
      ...options,
    }

    super(options)

    this.timeout = null
    this.toRemove = []

  }




  hasChange(uid) {
    const change =
      this.items &&
      this.items.find(item => {
        if (item && item.path.join('_').includes(uid)) return true
      })

    return change
  }

  removeChangeWithDelay(id, timeout = 2000) {
    if (!this.timeout) {
      this.timeout = setTimeout(() => {
        this.timeout = null

        this.items = this.items.filter(item => {
          const string = item.path.join('_')
          const shouldRemove = this.toRemove.find(id => string.includes(id))

          return !shouldRemove
        })
      }, timeout)

      this.toRemove = [id]
    } else {
      this.toRemove.push(id)
    }
  }

  removeChange(uid) {
    if (this.hasChange(uid)) {
      this.items = this.items.filter(item => {
        if (item && item.path.join('_').includes(uid)) {
          return false
        }
        return true
      })
    }
  }
}

class PlaceMarks extends PouchStore {
  constructor(options) {
    options = {
      items: [],
      ...options,
    }

    const { id } = options
    super(options, {
      key: `_local/placemarks_${id}`,
    })
  }

  addMark(uid) {
    this.items = [
      ...this.items,
      {
        uid,
      },
    ]
  }

  getMark(uid) {
    return this.items.find(item => item.uid === uid)
  }

  removeMark(uid) {
    this.items = this.items.filter(item => item.uid !== uid)
  }

  toggleMark(uid) {
    if (this.getMark(uid)) {
      this.removeMark(uid)
    } else {
      this.addMark(uid)
    }
  }
}

class Store {
  constructor(options = {}) {
    this.__storeID = uid()

    let tree = {
      pages: [],
      nodes: {},
      shortcuts: [],
      otherFocus: '',
    }

    if (options.placeholderData) {
      const placeholderData = this.generatePlaceholderData()
      tree.pages = placeholderData.pages
      tree.nodes = placeholderData.nodes
    }

    const { subscribe, set, update } = writable({
      tree,
      storeID: this.__storeID,
      references: {},
    })

    this.options = options

    this.subscribe = subscribe
    this._set = set
    this._update = update

    this.pages = new Subject([])
    this.emitter = writable(null)
    this.dataReady = writable(false)
    const emitter = new Emitter()

    this.on = emitter.on
    this.off = emitter.off
    this.once = emitter.once
    this.emit = emitter.emit
    this.changes = new Changes()

    this.currentCursor = null
    this.referencesData = null
    this._referencesDataTimeout = null

    this.setChangeEventDelay(this.options.changeEventDelay || 0)

    this.getChangedDataThrottled = () => { }

    this.wordIndex = new WordIndex()

    this.placeMarks = this.options.id
      ? new PlaceMarks({
        id: this.options.id,
      })
      : null

    this.beforeInitNotes = []
    this.changedNodes = writable([])
    this.flexSearch;
    this.cursor = writable(null);

    this.updateFlexSearchIndexDebounced = _.debounce(this.updateFlexSearchIndex, 500)
  }


  async enableFlexSearch() {
    // this.flexSearchReady = new Promise(async (resolve, reject) => {
    //   try {
    //     if (!flexSearchInstance) {
    //       flexSearchInstance = await flexSearchWorker()
    //     }

    //     if (!this.flexSearch) {
    //       this.flexSearch = await new flexSearchInstance.NodesFlexSearchHandler({
    //         id: this.storeID
    //       })
    //     }
    //     await this.flexSearch.bulkLoadNodes(this.nodes)
    //     resolve()

    //   } catch (err) {
    //     reject(err)
    //   }
    // })
  }


  async waitForFlexSearch() {
    await this.flexSearchReady
  }

  setChangeEventDelay(ms) {
    this._handleDataChange = _.throttle(this.handleDataChange.bind(this), ms, {
      leading: false,
    })
  }

  get() {
    return get(this)
  }

  set storeID(value) {
    this.update(state => {
      state.storeID = value
      return state
    })

    this.__storeID = value
  }

  get storeID() {
    return this.__storeID
  }

  generateStoreID() {
    const id = uid()
    this.storeID = id
    return this.store
  }

  getReferences(uid, data) {
    const references = data || this.getReferencesDataThrottled()

    if (uid && references && references[uid]) {
      return references[uid]
    } else {
      return references
    }
  }

  generatePlaceholderData({ count = 10 } = {}) {
    let nodes = {}

    const todayPage = new node({
      type: types.dailyNotes,
      title: dayjs().format('LL'),
    })

    for (let i = 0; i < count; i++) {
      const child = new node({
        type: types.node,
        parent: todayPage.uid,
        string: `{{PLACEHOLDER}}`,
      })

      todayPage.children.push(child.uid)
      nodes[child.uid] = child
    }

    nodes[todayPage.uid] = todayPage

    return {
      nodes,
      targetUid: todayPage.uid,
      pages: [todayPage],
    }
  }

  hasReference(targetUID, uid) {
    const references = this.getReferencesDataThrottled()
    if (references && references[uid]) {
      const _references = references[uid].references
      return _references.find(
        ref => ref && ref.item && ref.item.uid === targetUID
      )
    }
  }

  async updateReferencesData() {
    const data = await this.getReferencesData()
    this.referencesData = data
  }

  getReferencesDataThrottled() {
    if (!this._referencesDataTimeout) {
      this.getReferencesData()
        .then(data => {
          this.referencesData = data

          this._referencesDataTimeout = setTimeout(() => {
            this._referencesDataTimeout = null
          }, 5000)
        })
        .catch(err => {
          console.error(err)
          this._referencesDataTimeout = null
        })
    }

    return this.referencesData
  }

  set(data) {
    const id = this.storeID

    if (data?.tree?.nodes) {

      if (!id && !data.storeID) {
        const _id = this.generateStoreID()
        data.storeID = _id
        console.log('generated store ID', _id)
      }

      if (data.storeID === id) {
        this._set(data)
      } else {
        console.error(new Error('Different store id encountered', id))
      }
    }
  }

  update(callback, ...args) {

    const _callback = (state) => {
      let newState = callback(state)

      if (newState?.tree?.nodes) {
        return newState
      } else {
        return state
      }
    }
    this._update(_callback, ...args)
  }

  isInTree(targetUID, searchUID) {
    return this.getInTree(targetUID, searchUID) && true
  }

  getInTree(targetUID, searchUID) {
    const children = this.getNodesArr(searchUID)
    return children.find(item => item.uid == targetUID)
  }

  getChangedData(prevObj, currentObj) {
    return []
    // let changes = diff(prevObj, currentObj)

    // if (!changes) return []

    // changes = changes.filter(change => {
    //   const changedKey = _.last(change.path)

    //   if (_.isString(changedKey)) {
    //     return !/edit-time|create-time|edit-email|create-email|expanded|lastRendered|lastFocused|timestamp|wid/.test(
    //       changedKey
    //     )
    //   }
    //   return true
    // })

    // changes.__proto__.applyChange = function(obj, clone) {
    //   const changes = this

    //   if (clone) {
    //     obj = _.cloneDeep(obj)
    //   }

    //   if (changes.length) {
    //     changes.forEach(change => applyChange(obj, true, change))
    //   }

    //   return obj
    // }

    // return changes
  }

  applyChangeToData(obj, changes, clone) {
    if (clone) {
      obj = _.cloneDeep(obj)
    }

    if (changes.length) {
      changes.forEach(change => applyChange(obj, true, change))
    }

    return obj
  }

  updateNodes(changes, clone) {
    let obj = this.nodes
    if (clone) {
      obj = _.cloneDeep(obj)
    }

    this.changes.items = changes

    if (changes.length) {
      changes.forEach(change => applyChange(obj, true, change))
    }
    this.nodes = obj
    return obj
  }

  handleDataChange() {
    const nodes = this.nodes

    if (this.prevNodes) {
      const changes = this.getChangedData(this.prevNodes, nodes)

      if (changes.length) {
        this.emit('change', nodes, changes, this.prevNodes)
        this.prevNodes = cloneDeep(nodes)
      }
    } else {
      this.emit('change', nodes)
      this.prevNodes = cloneDeep(nodes)
    }
  }

  triggerChangeEvent() {
    this.handleDataChange()
  }

  async refreshNode(uid) {
    const string = this.getString(uid)
    this.updateString(uid, string => '')
    await tick()
    this.updateString(uid, _ => string)
  }

  getFirstChildren(uid) {
    try {
      const {
        tree: { nodes },
      } = get(this)
      const children = this.getChildren(uid)
      return nodes[children[0]]
    } catch (err) {
      console.error(err)
      return null
    }
  }

  isChildren(target, parent, nodes) {
    try {
      const item = nodes[parent]
      const children = (item && nodes.children) || []

      for (let child of children) {
        if (target === child) return true
        return this.isChildren(target, child, nodes)
      }

      return false
    } catch (err) {
      return false
    }
  }

  hasContact(name) {
    let contacts = this.getContacts()
    return contacts.find(
      item => item.name.toLowerCase().trim() === name.toLowerCase().trim()
    )
  }

  getContact(uid) {
    const contacts = this.getContacts()
    return contacts.find(contact => contact.item.uid === uid)
  }

  searchContact(value = 'me', identifier = 'name') {
    const contacts = this.getContacts()
    return contacts.find(contact => contact.data[identifier] === value)
  }

  getHastags(uid) {
    const str = this.getString(uid)
    return parseHashtags(str)
  }

  getHastagDefinitions(tag) {
    const results = this.search(
      tag ? new RegExp(`(?<=^|\s)\#${tag}\#(?=$|\s)`) : hashtagRe
    )

    if (results.length) {
      return results
    }
  }

  getHashtagDefinition(uid) {
    const hastags = this.getHastags(uid)

    if (!_.isEmpty(hastags)) {
      const defs = hastags
        .map(({ value, full } = {}) => {
          if (!value) return
          return {
            definitions: this.getHastagDefinitions(value.trim()),
            value: value.trim(),
            full,
          }
        })
        .filter(item => item)

      return defs
    }
  }

  addContact(data = {}) {
    const page = this.getContactsPage()

    if (data && data.name) {
      if (!this.hasContact(data.name)) {
        const contactNode = this.addChildren(page.uid)

        this.updateString(contactNode.uid, string => `${data.name}::`)
        for (let key in data) {
          if (!key.includes('http') || !key.startsWith('file://')) {
            const childNode = this.addChildren(contactNode.uid)
            this.updateString(childNode.uid, string => `${key}:: ${data[key]}`)
          }
        }
      }
    }
  }

  getContactsPage() {
    return this.getInternalPage('Contacts', {
      type: 'page',
    })
  }

  setCurrentCursor({ uid, page } = {}) {
    if (!uid) {
      this.currentCursor = null
    } else {
      this.currentCursor = {
        uid,
        page,
        spaceId: this.options.id,
        isRemote: this.options.isRemote,
      }
    }
  }

  async findHashtagConfig(tag) {
    // const nodes = await utils.getHastagConfig(tag, this.nodes)
    // const data = _.first(nodes)

    // if (data && data.links && data.links.length) {
    //   const _temp = _.first(data.links)
    //   // debugger
    //   return _temp && _temp.data
    // }
  }

  getVideo(uid) {
    const string = this.getString(uid)

    if (string) {
      const videos = getMarkdownVideos(string)
      return _.first(videos)
    }
  }

  getNextVideo(uid) {
    const parent = this.getParent(uid)
    if (parent) {
      const nodesArr = this.getChildren(parent)
      const nodesArr2 = this.getChildren(uid)
      const hasVideo = nodesArr.find(
        item => this.getVideo(item) && item !== uid
      )
      const hasVideo2 = nodesArr2.find(item => this.getVideo(item))

      if (hasVideo2) {
        return hasVideo2
      } else {
        return hasVideo
      }
    }
  }

  getVideosQueue(uid) {
    const nodesArr = this.getNodesArr(uid)
    const videos = nodesArr
      .map(item => item.uid !== uid && this.getVideo(item.uid))
      .filter(item => item)
    return videos
  }

  handleOwnerContact(contacts = []) {
    const hasMeContact = contacts.find(item => item.name === 'me')
    if (!hasMeContact) {
      this.addOwnerContact()
    } else {
      for (let contact of contacts) {
        // console.log(contact)
        if (contact.name === 'me') {
          if (!this.isAtTop(contact.item.uid)) {
            this.takeToTop(contact.item.uid)
          }

          if (!contact.data || !contact.data.wid) {
            const child = this.addChildren(contact.item.uid)
            this.updateString(child.uid, string => `wid:: ${_encryption.wid}`)
          }
        }
      }
    }
  }

  loadContacts(contacts = []) {
    contacts.forEach((contact = {}) => {
      if (contact.Name && !this.hasContact(contact.Name)) {
        const { Name, ...contactInfo } = contact
        let _temp = {
          name: Name,
        }

        for (let key in contactInfo) {
          if (contact[key]) {
            const _key = key.toLowerCase()

            _temp[_key] = contact[key]
          }
        }
        this.addContact({
          contact_id: uid(),
          ..._temp,
        })
      }
    })
  }

  addOwnerContact() {
    const page = this.getContactsPage()

    if (page && page.uid) {
      const child = this.addChildren(page.uid)
      this.updateString(child.uid, string => `me::`)

      const subChild = this.addChildren(child.uid)
      this.updateString(subChild.uid, string => `wid:: ${_encryption._wid}`)
    }
  }

  findAndReplace(match, replace) {
    const arr = this.search(match)

    const _replace = () => {
      arr.forEach(({ item, store } = {}) => {
        if (item && store && replace) {
          this.updateString(item.uid, string => string.replace(match, replace))
        }
      })
    }

    return {
      data: arr,
      replace: _replace,
    }
  }

  isAtTop(uid) {
    const parent = this.getParent(uid)
    const children = this.getChildren(parent) || []

    return uid === children[0]
  }

  takeToTop(uid) {
    if (!uid) return
    const parent = this.getParent(uid)
    if (parent) {
      this.updateItem(parent, item => {
        let children = item.children || []
        const hasChildren = children.find(_uid => _uid === uid)

        if (children[0] === uid) return item

        if (hasChildren) {
          children = children.filter(_uid => _uid !== uid)
          children = [uid, ...children]
        }

        item.children = children
        return item
      })
    }
  }

  getContacts() {
    const contactsPage = this.getContactsPage()
    const rootNodes = this.getChildrenData(contactsPage.uid)
    let contacts = []
    let re = /([a-zA-Z0-9]+)(::.*)/g

    for (let key in rootNodes) {
      const item = rootNodes[key]
      const string = (item && item.string) || ''
      if (re.test(string)) {
        contacts.push({
          name: string.replace(re, '$1'),
          data: this.getNodeProperties(item.uid),
          item,
        })
      }
    }

    return contacts
  }

  getContactsAndDeadlines() {
    const nodes = this.nodes || {}

    let re = /([a-zA-Z0-9]+)(::.*)/g

    let deadlines = []
    let markdownAliases = []
    let contacts = this.getContacts()

    for (let key in nodes) {
      const item = nodes[key]
      const string = (item && item.string) || ''

      const _deadlines = parseDeadlines(string) || []
      if (_deadlines && _deadlines.length) {
        _deadlines.forEach(item => {
          deadlines.push(item.full)
        })
      }

      const nodeMarkdownAlias = getNodeMarkdownAlias(string) || []
      markdownAliases = [...markdownAliases, ...nodeMarkdownAlias]
    }
    this.handleOwnerContact(contacts)
    return {
      contacts: _.uniq(contacts),
      deadlines: _.uniq(deadlines),
      markdownAliases: _.uniqBy(markdownAliases, item => item.alias),
    }
  }

  getString(uid) {
    const item = this.getItem(uid)

    return (item && (item.string || item.title)) || ''
  }

  getTimeRange(uid) {
    const string = this.getString(uid)
    const m = _.first(parseTimeRangeData(string))
    return m
  }

  deleteNode(uid) {
    const item = this.getItem(uid)

    if (!_.isEmpty(item)) {
      const nodes = this.nodes

      const parent = item.parent

      if (parent) {
        this.updateItem(parent, item => ({
          ...item,
          children:
            (item.children && item.children.filter(child => child !== uid)) ||
            [],
        }))
      }

      delete nodes[uid]
      this.nodes = nodes
      return item
    }
  }

  filterSimilarTimeRanges(uid) {
    const childrenData = this.getChildrenData(uid)

    const children = _.chain(childrenData)
      .filter(item => {
        const timeRange = this.getTimeRange(item.uid)
        if (timeRange) {
          const _result = this.findTimeRange(uid, timeRange.full)
          if (_result) {
            return _result && _result.uid === item.uid
          } else {
            return true
          }
        } else {
          return true
        }
      })
      .map(item => item.uid)
      .value()

    this.updateItem(uid, item => ({
      ...item,
      children: children,
    }))
  }

  getChildrenData(uid) {
    return this.getChildren(uid).map(uid => this.getItem(uid))
  }

  findTimeRange(uid, timeRange) {
    const children = this.getChildrenData(uid)

    return children.find(item => {
      const _timeRange = this.getTimeRange(item.uid)

      return _timeRange && _timeRange.full === timeRange
    })
  }
  getTimeRangeDetails(uid) {
    // const string = this.getString(uid)

    // const [startTime, endTime] = parseTimeRange(string)

    // if (startTime && endTime) {
    //   return {
    //     startTime,
    //     endTime,
    //   }
    // }
    return null
  }

  handleCloningAndAdding(uid, cloneUID, cloneRoot) {
    const result = this.cloneChildrenNodes(cloneUID, uid)

    const cloned = result.data || {}
    const children = result.children || []

    this.nodes = {
      ...this.nodes,
      ...cloned,
    }


    if (cloneRoot) {
      const item = this.getItem(cloneUID)
      this.updateString(uid, string => item.string || '')
    }

    this.updateItem(uid, item => {
      item.children = [...children, ...item.children]
      return item
    })
  }

  addNodesFromList({ uid, callback = () => { }, list = '', index = 0 } = {}) {
    try {
      if (isList(list) && uid) {
        let item = this.nodes[uid]
        let listParsed = parseList(list)
        const parent = item.parent
        let nodes = listToNodes(listParsed, parent)
        // const currentIndex = this.getCurrentIndex(uid)
        this.nodes = this.addMultipleChildren(uid, nodes, index)
        callback(nodes)
      }
    } catch (err) {
      console.error(err)
    }
  }

  updateParent(uidList = [], parentUID = '') {
    for (let uid of uidList) {
      this.updateItem(uid, item => {
        item.parent = parentUID
        return item
      })
    }
  }

  cloneChildrenNodes(uid, parent) {
    const item = this.getItem(uid)

    let children = []

    let data = {}
    let items = []

    for (let child of item.children) {
      const { cloned, uid, item } = this.cloneNodes(child, parent)
      data = {
        ...data,
        ...cloned,
      }
      children.push(uid)
      items.push(item)
    }

    return {
      data,
      children,
      items,
    }
  }
  cloneNodes(uid, parent, cloned = {}) {
    const item = this.getItem(uid)

    let _item = this.cloneNode(item.uid, {
      parent,
    })

    let children = []

    for (let child of _item.children) {
      children.push(this.cloneNodes(child, _item.uid, cloned).uid)
    }

    _item.children = children
    cloned[_item.uid] = _item

    return {
      cloned,
      uid: _item.uid,
      item: _item,
      prevItem: item,
    }
  }

  cloneNode(uid, options = {}, updateStore) {
    const item = this.getItem(uid)
    if (item) {
      const _temp = new node({
        ...item,
        uid: undefined,
        createEmail: this.wid,
        editEmail: this.wid,
        ...options,
      })
      if (updateStore) {
        this.addItem(_temp)
      }
      return _temp
    }
  }

  addItem(item) {
    if (item && item.uid) {
      const data = {
        ...this.nodes,
      }
      data[item.uid] = item
      return item
    }
  }

  listToNodes(list = [], targetNode, data = {}) {
    return list.map(item => {
      const tempItem = new node({
        parent: targetNode,
        type: 'node',
        string: item.text || '',
        createEmail: this.wid,
        editEmail: this.wid,
      })
      data[tempItem.uid] = tempItem
      tempItem.children = listToNodes(item.children, tempItem.uid, data)
      return tempItem.uid
    })
  }

  addMultipleChildren(id, nodes, index = 0) {
    const children = this.getChildren(id)
    const data = {
      ...this.nodes,
    }

    if (parent && children) {
      let temp = [...children]
      temp.splice(index, 0, nodes)
      temp = temp.flat()
      data[id].children = temp
    }
    return data
  }

  onChangeNode(
    cb = _.noop,
    interval = 3000,
    {
      changesNotRequired,
      throttleOptions: { leading = false, trailing = true } = {},
    } = {}
  ) {
    let prevNodes = null
    let unsubscribe = _.noop
    const handler = state => {
      const trackChanges = this.trackChanges || []

      this.trackChangesCache = this.trackChangesCache || {}

      for (let track of trackChanges) {
        if (track.uid) {
          const prev = this.trackChangesCache[track.uid]
          const current = this.getNodesArr(track.uid)

          const changes = prev ? this.getChangedData(prev, current) : null

          if (!prev || changes.length) {
            this.emit('node_changed', {
              current,
              prev,
              changes,
              ...track,
            })
          }
        }
      }
    }

    const _handler = _.throttle(handler, interval, {
      leading,
      trailing,
    })

    unsubscribe = this.subscribe(_handler)

    return () => unsubscribe()
  }

  onChange(
    cb = _.noop,
    interval = 3000,
    {
      changesNotRequired,
      throttleOptions: { leading = false, trailing = true } = {},
    } = {}
  ) {
    let prevNodes = null
    let unsubscribe = _.noop

    let inProcess

    const handler = state => {
      if (state && state.storeID !== this.storeID) {
        console.error(new Error('ID_MISMATCH'))
        return
      } else {
        if (inProcess) return

        try {
          inProcess = true

          if (state && state.tree && state.tree.nodes) {
            const nodes = state.tree.nodes

            if (!prevNodes || changesNotRequired) {
              cb(null, nodes, unsubscribe)
            } else {
              const changes = this.getChangedData(prevNodes, nodes)
              cb(changes, nodes, unsubscribe)
            }

            prevNodes = _.cloneDeep(nodes)
          }

          inProcess = false
        } catch (err) {
          console.error(err)
          inProcess = false
        }
      }
    }

    const _handler = _.throttle(handler, interval, {
      leading,
      trailing,
    })

    unsubscribe = this.subscribe(_handler)

    return () => unsubscribe()
  }

  getParent(id) {
    const item = this.getItem(id)
    return item && item.parent
  }
  getCurrentIndex(id) {
    const parent = this.getParent(id)
    const children = this.getChildren(parent)
    return _.findIndex(children, child => child === id)
  }

  searchTemplates(uid) {
    const results = this.search(/^template::(.+)/gi, uid)

    const templates = results
      .map(({ item } = {}) => {
        const uid = item.uid

        if (this.isTemplate(uid)) {
          const templateData = parseMetaData(item.string || '')
          return {
            item,
            data: this.getAllChildren(uid),
            value: templateData.value,
            match: templateData,
            store: this,
          }
        }
      })
      .filter(item => item)

    return templates
  }

  resultsToNestedData(items) {
    if (items && items.length) {
      let data = {}
      let nodes = this.nodes

      items = items.splice(0, 20)

      const parent = new node({
        type: 'page',
        title: 'Queries',
      })

      items.map(({ item }) => {
        const _node = new node({
          type: 'node',
          string: `((${item.uid}))`,
          parent: parent.uid,
        })
        data[_node.uid] = _node
        parent.children.push(_node.uid)
      })

      data[parent.uid] = parent
      return {
        root: parent,
        data,
      }
    } else {
      return null
    }
  }

  getParentMarkdown(uid) {
    const parent = this.getParent(uid)

    if (parent) {
      const string = this.getString(parent)
      const links = getMarkdownLinks(string)
      const link = (links || [])[0]

      if (link)
        return {
          ...link,
          item: this.getItem(parent),
        }
    }
  }

  hasChildren(uid) {
    const children = this.getChildren(uid)
    return children && children.length
  }

  isContactDefination() {
    const item = this.getItem(uid)
    if (item && item.string && /^contact::(.+)/gi.test(item.string)) {
      const hasChildren = true || !_.isEmpty(item.children)

      if (hasChildren) {
        const hasAnyAttribute = store.search(metaDataRE, uid)
        if (hasAnyAttribute && hasAnyAttribute.length) {
          return true
        }
      }
    }

    return false
  }
  isTemplate(uid) {
    const item = this.getItem(uid)
    if (item && item.string && /^template::(.+)/gi.test(item.string)) {
      const hasChildren = true || !_.isEmpty(item.children)

      if (hasChildren) {
        return true
      }
    }

    return false
  }

  isChildOf(targetUID, targetParent, nodes = this.nodes) {
    try {
      const item = nodes[targetUID]
      const parent = item.parent

      if (!parent) return false

      if (parent === targetParent) {
        return true
      }

      return this.isChildOf(parent, targetParent, nodes)
    } catch (err) {
      console.error(err)
      return false
    }
  }

  getAllMetaBlocks(uid) {
    let results = this.search(/^(?<full>(?<name>.+?)::(?<value>.+)?)/, uid)
    return results
      .map(({ item }) => {
        const metaBlocks = parseMetaData((item && item.string) || '')
        let metaBlock = _.first(metaBlocks)

        if (metaBlock && metaBlock.value && metaBlock.value.trim()) {
          return {
            metaBlock,
            item,
          }
        } else {
          return false
        }
      })
      .filter(item => item)
  }

  parseMetaTags(uid) {
    const string = this.getString(uid)
    const tags = parseMetaTags(string || '')

    if (_.isEmpty(tags)) {
      const tags = metaTags.tags
      return tags.map(item => {
        const states = item.states
        const firstState = states && states[0] && states[0].value
        return {
          label: item.value,
          value: `#${item.value}:${firstState}#`,
          tag: item,
        }
      })
    } else {
      const states = tags
        .map(tag =>
          metaTags.getStates(tag.maintag).map(state => ({
            ...state,
            maintag: tag.maintag,
            currentTag: tag,
          }))
        )
        .flat()
        .map(item => ({
          value: `#${item.maintag}:${item.value}#`,
          label: `#${item.maintag}:${item.value}#`,
          currentTag: item.currentTag,
        }))
      return states
    }
  }

  parseMetaInformation(uid, data = {}) {
    const item = this.getItem(uid)

    if (item && item.string) {
      const string = item.string
      const metaData = parseMetaData(string)

      const data = _.first(metaData)

      if (data) {
        return {
          identifier: data.name,
          value: data.value,
          item: item,
        }
      }
    }
  }

  addMetaInformation(uid, { identifier, value } = {}) {
    this.updateString(uid, string => `${identifier}:: ${value}`)
  }

  getSettingsNode(uid) {
    const _nodes = this.getNodesArr(uid)

    return _nodes.find(node => {
      const data = this.parseMetaInformation(node.uid)
      return data && data.identifier === 'settings'
    })
  }

  getMetaNodeInChildren(uid, identifier) {
    const children = this.getChildren(uid) || []

    const result = children.find(childUID => {
      const data = this.parseMetaInformation(childUID)
      return data && data.identifier === identifier
    })

    if (result) {
      return this.getItem(result)
    }
  }

  parseSettings(uid, settings = []) {
    const settingsNode = this.getSettingsNode(uid)

    if (settingsNode) {
      const data = this.parseNodeInformation(settingsNode.uid)
      return (data && data.metaInformation) || []
    }

    return settings
  }

  renderNodeProperties(uid, properties = {}, shouldBeUnique) {
    const item = this.getItem(uid)
    if (uid && !_.isEmpty(item)) {
      if (properties && properties.__properties) {
        this.updateItem(uid, item => {
          item = {
            ...item,
            ...properties.__properties,
          }
          return item
        })
      }
      for (let key in properties) {
        const value = properties[key]
        const identifier = key

        if (
          !identifier ||
          identifier === '__properties' ||
          identifier === '__text'
        )
          continue

        let child

        if (shouldBeUnique) {
          const node = this.getMetaNodeInChildren(uid, identifier)

          if (node && node.uid) {
            this.updateItem(node.uid, item => ({
              ...item,
              children: [],
            }))
          }

          child = _.isEmpty(node) ? this.addChildren(uid) : node
        } else {
          child = this.addChildren(uid)
        }
        if (_.isObject(value)) {
          if (value && value.__properties) {
            this.updateItem(uid, item => {
              item = {
                ...item,
                expanded: value.__properties.expanded,
              }
              return item
            })
          }

          if (value.__text) {
            this.updateString(child.uid, string => `${value.__text}`)
            this.renderNodeProperties(child.uid, value)
          } else {
            this.updateString(child.uid, string => `${identifier}::`)
            this.renderNodeProperties(child.uid, value)
          }
        } else if (_.isString(value) && value) {
          this.updateString(
            child.uid,
            string => `${identifier}:: ${value || ''}`
          )
        }
      }
    }
  }

  setItem(uid, data) {
    this.update(state => {
      try {
        state.tree.nodes[uid] = data
        return state
      } catch (err) {
        console.error(err)
        return state
      }
    })
  }

  getNodeProperties(uid) {
    const item = this.getItem(uid)

    if (!_.isEmpty(item)) {
      let properties = {}

      const parsedInformation = this.parseNodeInformation(uid)
      const metaInformation =
        (parsedInformation && parsedInformation.metaInformation) || []

      metaInformation.forEach(({ identifier, value, item } = {}) => {
        const children = this.getChildren(item.uid)

        if (children.length) {
          const _temp = this.getNodeProperties(item.uid)

          if (!_.isEmpty(_temp)) {
            properties[identifier] = _temp
          } else {
            properties[identifier] = _.isString(value) ? value.trim() : value
          }
        } else {
          properties[identifier] = _.isString(value) ? value.trim() : value
        }
      })

      return properties
    }

    return null
  }

  async searchRootNodes(query, options) {
    await this.waitForFlexSearch()
    if (!this.flexSearch) return [];
    const nodes = this.nodes
    const results = await this.flexSearch.searchNodesWithNoParent(query, options);
    return results.map(uid => nodes[uid])
      .filter(node => node)
      .sort((a, b) => {
        if (a['edit-time'] < b['edit-time']) return 1
        if (a['edit-time'] > b['edit-time']) return -1
        return 0
      })
  }

  async searchNodes(query, options) {
    await this.waitForFlexSearch()
    const nodes = this.nodes
    const results = await this.flexSearch?.searchNodes(query, options);
    return results.map(uid => nodes[uid])
      .filter(node => node)
      .sort((a, b) => {
        if (a['edit-time'] < b['edit-time']) return 1
        if (a['edit-time'] > b['edit-time']) return -1
        return 0
      })
  }

  parseNodeInformation(uid) {
    const currentItem = this.getItem(uid)

    const data = {
      ...currentItem,
    }

    const metaInformation = []

    if (currentItem && currentItem.uid) {
      for (let child of currentItem.children) {
        const metaData = this.parseMetaInformation(child)
        if (metaData && metaData.identifier) {
          metaInformation.push(metaData)
        }
      }
    }

    data.metaInformation = metaInformation

    return data
  }

  renderSettings(uid, settings = []) {
    if (!uid) return

    let settingsNode = this.getSettingsNode(uid)

    if (!settingsNode) {
      const child = this.addChildren(uid)
      this.updateString(child.uid, string => `settings::`)
      settingsNode = child
    } else {
      this.updateItem(settingsNode.uid, item => ({
        ...item,
        children: [],
      }))
    }

    settings.forEach(item => {
      const child = this.addChildren(settingsNode.uid)
      this.addMetaInformation(child.uid, item)
    })
  }

  getChildren(uid) {
    try {
      const {
        tree: { nodes },
      } = get(this)
      const item = nodes[uid]

      return item?.children || []
    } catch (err) {
      console.error(err)
      return null
    }
  }

  getCellEmbeds(uid) {
    const string = this.getString(uid) || ''
    const embeds = parseCellRefs(string) || []
    return embeds
  }


  getAllFeedNodes() {
    const nodes = this.nodes
    const feedNode = this.getItem("feeds");

    if (!feedNode) return [];

    const feedNodeDirectChildren = feedNode.children?.map(uid => {
      return nodes[uid]
    }) || [];

    return feedNodeDirectChildren.filter(item => item && item.string);
  }

  async getFeedResults(feedUid) {
    const feed = this.getItem(feedUid);
    if (!feed) return [];

    const feedChildren = this.getChildren(feedUid);
    const feedChildrenNodes = feedChildren.map(uid => {
      return this.getItem(uid)
    }) || [];

    const resultPromises = feedChildrenNodes
      .filter(item => item && item.string)
      .map(item => {
        return this.searchNodes(item.string)
      })


    const results = await Promise.all(resultPromises);

    // console.log({resul: results})

    // perform intersection of results
    const intersection = _.intersectionBy(...results, 'uid');

    return intersection;
  }

  hasBrowserHistory(uid) {
    const embeds = this.getCellEmbeds(uid)
    const embed = embeds.find(item => {
      const ref = item.ref
      if (ref) {
        const str = this.getString(ref)
        if (str.includes('browser history')) {
          return true
        }
      }
    })

    return embed && this.getItem(embed.ref)
  }

  addBrowserHistory(uid) {
    const history = this.hasBrowserHistory(uid)
    if (!history) {
      const item = this.addNode(uid)

      this.updateString(item.uid, string => 'browser history')
      return item
    } else {
      return history
    }
  }

  addNodeWithCustomId(uid, string) {
    let item
    this.update(state => {
      if (!state) return state
      try {
        let data = state.tree.nodes
        item =
          item ||
          new node({
            type: types.node,
            createEmail: this.wid,
            editEmail: this.wid,
            id: uid,
            string: string
          })
        data[item.uid] = item
        state.tree.nodes = data
        this.flexSearch?.addNode(item)
        return state
      } catch (err) {
        console.error(err)
        return state
      }
    })
    return item
  }


  addFeedsPage() {
    const node = this.addNodeWithCustomId('feeds', 'feeds')
    return node
  }


  addChatPage() {
    const node = this.addNodeWithCustomId('chat', 'chat')
    return node
  }

  addCalendarPage() {
    const node = this.addNodeWithCustomId('calendar', 'calendar')
    return node
  }

  addSubscriptionsPage() {
    const node = this.addNodeWithCustomId('subscriptions', 'subscriptions')
    return node
  }

  getSubscriptionsPage() {
    const node = this.getItem('subscriptions')
    return node
  }

  getSubscriptionNode(wid) {

    const page = this.getSubscriptionsPage();
    if (!page) return null;

    const children = this.getChildrenData(page.uid);

    const node = children.find(child => {
      const string = child?.string
      if (string?.includes(wid)) {
        return true
      }
    });
    return node;
  }


  getSubscribedItemsNodes(wid) {
    const node = this.getSubscriptionNode(wid);
    if (!node) return [];

    const children = this.getChildrenData(node.uid);
    return children
  }


  hasSubscribed(wid, uid) {
    const nodes = this.getSubscribedItemsNodes(wid);
    const node = nodes?.find(item => item?.string?.includes(uid));
    return node;
  }


  addSubscription(wid, uid) {
    const subscriptionsPage = this.getSubscriptionsPage();
    if (wid && uid) {
      const node = this.getSubscriptionNode(wid);
      if (!node) {
        const item = this.addChildren(subscriptionsPage.uid);
        this.updateString(item.uid, string => `${wid}`)

        const item2 = this.addChildren(item.uid);
        this.updateString(item2.uid, string => `${uid}`)
      }else{
        const hasSubscribed = this.hasSubscribed(wid, uid);
        if (!hasSubscribed) {
          const item = this.addChildren(node.uid);
          this.updateString(item.uid, string => `${uid}`)
        }
      }
    }
  }

  removeSubscription(wid, uid) {
    if (wid && uid) {
      const node = this.getSubscriptionNode(wid);
      if (node) {
        const node2 = this.hasSubscribed(wid, uid);
        if (node2) {
          this.deleteNode(node2.uid)
        }
      }
    }
  }

  addTagsPage() {
    let item
    this.update(state => {
      if (!state) return state
      try {
        let data = state.tree.nodes
        item =
          item ||
          new node({
            type: types.node,
            createEmail: this.wid,
            editEmail: this.wid,
            id: "tags",
            string: "tags"
          })
        data[item.uid] = item
        state.tree.nodes = data
        this.flexSearch?.addNode(item)
        return state
      } catch (err) {
        console.error(err)
        return state
      }
    })
    return item
  }

  addNode() {
    let item
    this.update(state => {
      if (!state) return state
      try {
        let data = state.tree.nodes
        item =
          item ||
          new node({
            type: types.node,
            createEmail: this.wid,
            editEmail: this.wid,
          })
        data[item.uid] = item
        state.tree.nodes = data
        this.flexSearch?.addNode(item)
        return state
      } catch (err) {
        console.error(err)
        return state
      }
    })
    return item
  }

  get wid() {
    return _encryption.wid && _encryption.wid.replace(/\./g, '_')
  }

  getQueryDetails(uid) {
    const string = this.getString(uid)
    const queries = parseMarkdownQueries(string)

    const query = _.first(queries)

    if (query) {
      return query
    }
  }

  addChildren(id, index, item) {
    this.update(state => {
      if (!state) return state
      try {
        let data = state.tree.nodes

        if (!data[id]) return state

        let children = data[id].children || []

        item =
          item ||
          new node({
            parent,
            parentPage: this.getPageFromNode(id),
            type: types.node,
            createEmail: this.wid,
            editEmail: this.wid,
          })

        item.parent = id
        index = index || 0
        children.splice(index, 0, item.uid)
        data[id].children = children
        data[id].expanded = true
        data[item.uid] = item
        state.tree.nodes = data
        return state
      } catch (err) {
        console.error(err)
        return state
      }
    })
    return item
  }

  getTodayPage() {
    let todayNote = dayjs().format('LL')
    return this.getInternalPage(todayNote, {
      type: 'dailyNotes',
    })
  }

  getCurrentTimeNode(uid = '') {
    const item = uid ? this.getItem(uid) : this.getTodayPage()

    if (!_.isEmpty(item)) {
      const _nodes = this.getNodesArr(item.uid)
      const currentTime = dayjs()
      const timeNode = _nodes.find(item => {
        const { startTime, endTime } = this.getTimeRangeDetails(item.uid) || {}

        if (
          startTime &&
          endTime &&
          currentTime.isBefore(endTime) &&
          currentTime.isAfter(startTime)
        ) {
          return true
        }
        return false
      })

      return timeNode
    }
  }

  updateNodeData(id, data = {}) {
    this.update(state => {
      let item = _.get(state, ['tree', 'nodes', id])
      if (item) {
        state.tree.nodes[id] = {
          ...item,
          ...data,
        }
      }
      return state
    })
  }

  setValue(uid, data) {
    if (uid) {
      this.update(state => {
        try {
          let item = state.tree.nodes[uid]
          if (item.title) {
            state.tree.nodes[uid].title = data.string
          } else {
            state.tree.nodes[uid].string = data.string
          }
          return state
        } catch (err) {
          return state
        }
      })
    }
  }

  getItem(id) {
    let state = get(this)
    let item = _.get(state, ['tree', 'nodes', id]) || {}
    // console.log(id, item)
    if (id === "tags" && !item.uid) {
      // debugger
      item = this.addTagsPage()
    }

    if (id === "feeds" && !item.uid) {
      // debugger
      item = this.addFeedsPage()
    }


    if (id === "chat" && !item.uid) {
      item = this.addChatPage()
    }
    if (id === "calendar" && !item.uid) {
      item = this.addCalendarPage()
    }


    if (id === "subscriptions" && !item.uid) {
      item = this.addSubscriptionsPage()
    }
    return item
  }

  getNodeDetails(uid) {
    const item = this.getItem(uid)

    if (item && item.uid) {
      return {
        ...item,
        totalSubnodes: this.getTotalSubnodes(item.uid),
        totalWords: this.getTotalWords(item.uid),
        totalChildren: this.getTotalChildren(item.uid),
        linkedReferences: this.findAllReferences(item.uid),
      }
    }
  }

  getTotalSubnodes(uid) {
    return _.size(this.getAllChildren(uid))
  }

  getTotalChildren(uid) {
    return _.size(this.getChildren(uid))
  }

  getTotalWords(uid) {
    const allChildren = this.getAllChildren(uid)

    return _.chain(allChildren)
      .values()
      .reduce(
        (prev, curr) => `${prev.trim()} ${(curr && curr.string) || ''}`,
        ''
      )
      .split(' ')
      .size()
      .value()
  }

  updateItem(id, cb) {
    const item = this.getItem(id)

    if (_.isFunction(cb)) {
      const updatedItem = cb(item)

      if (updatedItem) {
        this.update(state => {
          try {
            state.tree.nodes[updatedItem.uid] = updatedItem
            return state
          } catch (err) {
            console.err(err)
            return state
          }
        })
        this.updateFlexSearchIndexDebounced(updatedItem)
      }
    }
  }

  async updateFlexSearchIndex(nodes) {
    await this.waitForFlexSearch();

    if (Array.isArray(nodes)) {
      this.flexSearch?.updateNodes(nodes)
    } else {
      this.flexSearch?.updateNode(nodes)
    }
  }

  updateString(uid, cb) {

    this.updateItem(uid, item => {
      try {
        item.string = cb(item.string || '')

        if (this.onUpdateString) {
          this.onUpdateString(item, item.string)
        }

        return item
      } catch (err) {
        console.error(err)
        return item
      }
    })
  }

  getParentPage(uid) {
    if (uid) {
      let parent = this.getParent(uid)
      if (!parent) return this.getItem(uid)

      if (parent !== uid) {
        return this.getParentPage.call(this, parent)
      }
    }
  }

  isQueryPageItem(uid) {
    const parentPage = this.getParentPage(uid)
    if (parentPage && parentPage.uid) {
      return this.isQueryPage(parentPage.uid)
    }
  }

  isQueryPage(uid) {
    const item = this.getItem(uid)
    return item && item.title && item.title.includes('__queries')
  }

  getQueryPageName(wid) {
    return `__queries ${wid}`
  }

  getAllQueries(uid) {
    const nodes = this.getNodesArr(uid)

    const result = nodes
      .map(item => {
        if (item && item.string) {
          const queriesMatch = parseQueries(item.string)
          const markdownQueries = parseMarkdownQueries(item.string)
          let _temp = []

          if (queriesMatch && queriesMatch.length) {
            queriesMatch.forEach(queryMatch => {
              _temp.push({
                item,
                query: queryMatch.value,
                queryURL: queryMatch.queryURL,
                match: queryMatch,
              })
            })
          }

          if (markdownQueries && markdownQueries.length) {
            markdownQueries.forEach(queryMatch => {
              _temp.push({
                item,
                query: queryMatch.query,
                queryURL: queryMatch.queryURL,
                match: queryMatch,
                name: markdownQueries.name,
                ...queryMatch,
              })
            })
          }

          return _temp
        }
      })
      .flat()
      .filter(item => item)

    return _.orderBy(result, ['item.edit-time'], ['desc'])
  }

  get allReferences() {
    if (!this.hasReferencesUpdated) {
      this.updateReferencesData()
      this.hasReferencesUpdated = true

      setTimeout(() => {
        this.hasReferencesUpdated = false
      }, 1000 * 60 * 2)
    }
    return this.referencesData
  }

  async getReferencesData() {
    // this.referencesData = await utils.generateReferencesData(this.nodes)
    // return this.referencesData
  }

  getQueryFeed(wid = this.wid) {
    const pageName = this.getQueryPageName(wid)
    let page = this.getInternalPage(pageName)

    return page
  }

  linkedReferencesCount(uid) {
    if (!uid) return 0
    const references = this.findAllReferences(uid) || []
    return references.length
  }

  findAllReferences(id) {
    let state = get(this)
    let page = state.tree.nodes[id]

    if (!page) return
    // return references
    return getLinkedReferences(page, state.tree.nodes)
  }

  getTasksScore(uid) {
    try {
      const nodes = get(this).tree.nodes
      let filteredNodes = this.getAllChildren(uid, nodes)
      const mainItem = nodes[uid]
      const linkedReferences =
        this.filterUID(getLinkedReferences(mainItem, nodes)) || []

      linkedReferences.forEach(uid => {
        filteredNodes[uid] = nodes[uid]
      })

      let total = 0
      let completed = 0

      const tasks = _.mapValues(filteredNodes, item => {
        const string = item.title || item.string

        const isTodo = hasCommand(string, 'todo')
        const isDone = hasCommand(string, 'done')
        const isPaused = hasCommand(string, 'paused')

        if (isTodo || isPaused) {
          total++
        }
        if (isDone) {
          total++
          completed++
        }
        return [isTodo, isDone]
      })

      return {
        total,
        completed,
      }
    } catch (err) {
      console.error(err)
      return null
    }
  }

  get nodes() {
    const state = get(this)
    return state && state.tree && state.tree.nodes ? state.tree.nodes : {}
  }

  set nodes(data) {
    if (!_.isEmpty(data) && _.isObject(data)) {
      this.update(state => {
        state.tree.nodes = data
        return state
      })
    }
  }

  addEmptyChildrenNodes(uid, count = 1) {
    let children = []

    for (let i = 0; i <= count; i++) {
      const child = this.addChildren(uid)
      children.push(child)
    }

    return children
  }

  async getReferencesAsync(itemUID, filterUID) {
    const item = this.getItem(itemUID)

    if (item && item.uid) {
      const string = item.type === 'page' ? (item.title || '').trim() : item.uid

      const results = await this.searchNodesAsync(string, filterUID)

      return getLinkedReferences(item, results)
    }
  }

  async searchNodesAsync(searchString, uid, caseSensitive = false) {
    // const nodesArr = await this.getNodesArrayAsync(uid)
    // return await this.webworker.exec(
    //   (nodesArr = [], searchString = '', caseSensitive) => {
    //     return nodesArr.filter(item => {
    //       const string = item.string || item.title || ''
    //       return !caseSensitive
    //         ? string.toLowerCase().includes(searchString.toLowerCase().trim())
    //         : string.includes(searchString)
    //     })
    //   },
    //   [nodesArr, searchString, caseSensitive]
    // )
  }

  async getNodesArrayAsync(uid) {
    // return await this.webworker.exec(
    //   (uid, nodes = {}) => {
    //     if (!uid) return values(nodes)
    //     else {
    //       const filteredData = getFilteredData(uid, nodes)
    //       return values(filteredData)
    //     }
    //     function values(obj = {}) {
    //       return Object.keys(obj).map(key => obj[key])
    //     }
    //     function getFilteredData(uid, data = {}, temp = {}) {
    //       if (!uid) return data
    //       const item = data[uid]
    //       if (item) {
    //         temp[item.uid] = item
    //         const children = (item && item.children) || []
    //         for (let child of children) {
    //           getFilteredData(child, data, temp)
    //         }
    //       }
    //       return data
    //     }
    //   },
    //   [uid, this.nodes]
    // )
  }

  /**
   * @param {String} uid
   * @returns {Array}
   */
  getNodesArr(uid) {
    if (uid) return _.values(this.getAllChildren(uid))
    return _.values(this.nodes)
  }

  getQueries() {
    const page = this.getQueryFeed()

    if (page && page.uid) {
      const children = this.getChildrenData(page.uid)
      return children
    }
  }

  getNotificationQueries(wid = [this.wid]) {
    if (_.isString(wid)) {
      wid = [wid]
    }

    if (this.options.remoteMembers) {
      wid = this.options.remoteMembers
    }

    if (_.isArray(wid)) {
      return wid
        .map(_wid => {
          if (!_wid) return
          const page = this.getQueryFeed(_wid)
          const results = this.search('get:notifications', page.uid, true) || []
          return {
            wid: _wid,
            timestamp: Date.now(),
            results,
            isCurrent: _wid === this.wid,
          }
        })
        .filter(item => item)
    }
  }

  async searchAsync(searchTerm, uid) {
    // try {
    //   if (!searchTerm) return []

    //   const nodes = this.getNodesArr(uid)

    //   const _nodes = await utils.nodesSearch(searchTerm, nodes)

    //   return _.chain(_nodes)
    //     .filter(item => {
    //       if (!includeQueryNodes && this.isQueryPageItem(item.uid)) {
    //         return false
    //       }
    //       return false
    //     })
    //     .map(item => ({
    //       item,
    //       store: this,
    //     }))
    //     .value()
    // } catch (err) {
    //   console.error('Error accured while searching', err)
    //   return []
    // }
  }

  search(searchTerm, uid, includeQueryNodes) {
    try {
      if (!searchTerm) return []

      const nodes = this.getNodesArr(uid)

      return _.chain(nodes)
        .filter(item => {
          const string = item.string || item.title || ''
          if (!includeQueryNodes && this.isQueryPageItem(item.uid)) {
            return false
          }

          if (_.isRegExp(searchTerm)) {
            return searchTerm.test(string)
          }

          if (_.isString(searchTerm)) {
            return string.includes(searchTerm.trim())
          }

          if (_.isObject(searchTerm) && searchTerm.match) {
            return searchTerm.match(item)
          }

          return false
        })
        .map(item => ({
          item,
          store: this,
        }))
        .value()
    } catch (err) {
      console.error('Error accured while searching', err)
      return []
    }
  }

  getAllChildren(uid = '', nodes = this.nodes || {}, obj = {}) {
    try {
      const item = nodes[uid]
      obj[item.uid] = item
      const children = item.children || []
      for (let child of children) {
        this.getAllChildren(child, nodes, obj)
      }
      return obj
    } catch (err) {
      return obj
    }
  }

  filterUID(arr) {
    if (_.isArray(arr)) {
      return arr.map(e => e.uid)
    }
    return []
  }

  getTasks(pageName, parent) {
    try {
      let nodes = get(this).tree.nodes
      let page = this.findPage(pageName)

      if (!page) {
        page = this.addPage(pageName)
      }

      const references = page ? filterUID(getLinkedReferences(page, nodes)) : []

      function filterUID(arr) {
        if (_.isArray(arr)) {
          return arr.map(e => e.uid)
        }
        return []
      }

      return references.filter(task => {
        return references.every(e => !this.isChildOf(task, e, nodes))
      })
    } catch (err) {
      console.error(err)
      return []
    }
  }

  async findPageAsync(title) {
    try {
      const results = await this.flexSearch?.search(title, {
        index: "title",
        limit: 2
      })

      if (results && results.length) {
        const result = results?.[0]?.result;
        const nodes = this.nodes;

        const uid = result?.find(uid => nodes[uid]?.title === title);

        if (uid) {
          return nodes[uid]
        }
        return null
      }
      return null
    } catch (err) {
      console.error(err)
      return null
    }
  }

  getItems(uids, nodes = this.nodes) {
    try {
      return uids.map(uid => nodes[uid])
    } catch (err) {
      console.error(err)
      return []
    }
  }

  async addPageAsync(title, {
    type
  }) {
    await this.waitForFlexSearch();
    const node = this.addPage(title, {
      type
    })
    await this.flexSearch?.addNode(node)

    return node
  }


  async getInternalPageAsync(
    title,
    pageConfig = {
      type: 'page',
    }
  ) {
    try {
      await this.waitForFlexSearch();
      return (
        await this.findPageAsync(title) ||
        await this.addPageAsync(title, {
          type: pageConfig.type,
        })
      )
    } catch (err) {
      console.error(err)
      return null
    }

  }

  getInternalPage(
    title,
    pageConfig = {
      type: 'page',
    }
  ) {
    try {
      return (
        this.findPage(title) ||
        this.addPage(title, {
          type: pageConfig.type,
        })
      )
    } catch (err) {
      console.error(err)
      return null
    }
  }
  /**
   * @param  {Array<String>} pages - array of pageNames
   */
  getInternalPages(
    pages = [],
    {
      addNewIfNotFound = true,
      pageConfig = {
        type: 'page',
      },
    } = {}
  ) {
    try {
      if (_.isArray(pages)) {
        return pages.map(
          pageName =>
            this.findPage(pageName) ||
            (addNewIfNotFound && this.addPage(pageName, pageConfig))
        )
      } else {
        throw new Error('passed param not an array')
      }
    } catch (err) {
      console.error(err)
      return []
    }
  }

  getPageId(pageName = '', createNewIfPageNotExists = false) {
    pageName = pageName.trim()
    if (!pageName.length) return

    let page = this.findPage(pageName)

    if (!page && createNewIfPageNotExists) {
      page = this.addPage(pageName)
    }
    return page && page.uid
  }

  handleDailyNotes() {
    try {
      let state = get(this)
      let todayNote = dayjs().format('LL')
      let note = state.tree.pages.find(o => o.title === todayNote)
      if (!note) {
        note = this.addDailyNotes()
      }

      return note
    } catch (err) {
      console.error(err)
      return null
    }
  }

  isPage(uid) {
    let state = get(this)
    return state.tree.nodes[uid]
  }

  delete(uid) {
    if (uid) {
      this.update(state => {
        try {
          delete state.tree.nodes[uid]
          return state
        } catch (err) {
          return state
        }
      })
    }
  }

  renamePage(uid, newName) {
    if (uid && newName) {
      this.update(state => {
        try {
          let nodes = state.tree.nodes

          if (nodes[uid] && nodes[uid].title) {
            const oldName = nodes[uid].title

            const allReferences = getLinkedReferences(nodes[uid], nodes) || []
            nodes[uid].title = newName
            allReferences.map(item => {
              item.pagesFound.forEach(page => {
                try {
                  if (
                    page.name === oldName &&
                    nodes[item.uid] &&
                    nodes[item.uid].string
                  ) {
                    nodes[item.uid].string = nodes[item.uid].string.replace(
                      page.name,
                      newName
                    )
                  }
                } catch (err) {
                  console.error(err)
                }
              })
            })
            state.tree.nodes = nodes
          } else {
            if (nodes[uid]) {
              nodes[uid].string = newName
              state.tree.nodes = nodes
            } else {
              throw new Error('passed node is not a page neither node')
            }
          }
          return state
        } catch (err) {
          console.error(err)
          return state
        }
      })
    }
  }

  getDailyNotes(count = 3, { includeEmptyPages } = {}) {
    let state = get(this)

    let i = _.chain(state.tree.nodes)
      .cloneDeep()
      .valuesIn()
      .value()
      .filter(item => {
        if (item.type === 'dailyNotes') {
          if (item.title === dayjs().format('LL')) {
            return true
          }
          if (!includeEmptyPages) {
            return !this.isEmptyNode(item.uid)
          }
          return true
        }
      })
      .sort((a, b) => {
        let dateA = dayjs(a['title'], 'LL').unix()
        let dateB = dayjs(b['title'], 'LL').unix()
        return dateB - dateA
      })
      .slice(0, count)

    return i
  }

  isEmptyNode(uid) {
    try {
      const item = this.getItem(uid)
      const children = item.children
      if (_.isEmpty(children)) {
        return true
      } else if (_.size(children) === 1) {
        const firstChildUID = _.first(children)
        const firstChildNode = this.getItem(firstChildUID)

        return (
          _.isEmpty(firstChildNode.children) && _.isEmpty(firstChildNode.string)
        )
      } else {
        return false
      }
    } catch (err) {
      console.error(err)
      return false
    }
  }

  getBreadcrumb(id, nodes = this.nodes, breadcrumb = []) {
    if (id && nodes[id]) {
      return this.getBreadcrumb(nodes[id].parent, nodes, [
        nodes[id],
        ...breadcrumb,
      ])
    }
    return breadcrumb
  }

  addDailyNotes() {
    let temp = new node({
      type: types.dailyNotes,
      createEmail: this.wid,
      editEmail: this.wid,
    })
    let child = new node({
      type: types.node,
      parentPage: temp.uid,
      parent: temp.uid,
      createEmail: this.wid,
      editEmail: this.wid,
    })
    temp.children = [child.uid]
    this.update(state => {
      state.tree.pages.push(temp)
      state.tree.nodes[temp.uid] = temp
      state.tree.nodes[child.uid] = child
      return state
    })
    return temp
  }


  getParentDistance(child, targetParent, distance = 0, nodes = this.nodes) {
    try {
      const item = nodes[child]

      let parent = item && item.parent
      if (!parent) return -1
      if (parent === targetParent) {
        return distance
      }
      return isParentOf(parent, targetParent, distance + 1, nodes)
    } catch (err) {
      return false
    }
  }


  isParentOf(child, targetParent, nodes = this.nodes) {
    try {
      const item = nodes[child]

      let parent = item && item.parent
      if (!parent) return false
      if (parent === targetParent) return true
      return isParentOf(parent, targetParent, nodes)
    } catch (err) {
      return false
    }
  }

  isToday(uid) {
    if (uid) {
      const string = this.getValue(uid)
      const date = dayjs(string, 'LL')
      return date.isValid() && date.format('LL') === dayjs().format('LL')
    }
  }

  getReferencesInPage(id) {
    try {
      let state = get(this)
      let page = state.tree.nodes[id]

      if (!page) return
      let references = _.chain(state.tree.nodes)
        .values()
        .filter(o => this.isParentOf(o.uid, id, state.tree.nodes))
        .map(o => {
          let pages = getPages(o.string || o.title)
          return pages.map(page => {
            return {
              uid: o.uid,
              root: this.getRoot(o.uid, state.tree.nodes),
              page: o.parentPage,
              reference: page.name,
            }
          })
        })
        .flatten()
        .compact()
        .value()
      return references
    } catch (err) {
      console.error(err)
      return []
    }
  }

  getRoot(id, nodes = this.nodes) {
    let item = nodes[id]
    if (!item) return null
    let parent = item.parent
    let ancestor = parent && nodes[parent]?.parent

    if (!ancestor) {
      return id
    }
    return this.getRoot(parent, nodes)
  }

  getPageFromNode(id) {
    let state = get(this)
    let nodes = state.tree.nodes
    if (id && nodes[id] && !nodes[id].parent) {
      return nodes[id]
    }
    if (!id) {
      console.error('id is not valid getpagefromnode')
      return null;
    }
    return this.getPageFromNode(nodes[id].parent)
  }


  getChangedFromEvent(detail) {
    if (!detail.uid) return
    const data2 = this.nodes

    if (detail && detail.targets && detail.targets.length) {
      return [
        { uid: detail.uid, item: data2[detail.uid] },
        ...detail.targets.map((uid) => ({ uid, item: data2[uid] })),
      ]
    }
    return [{ uid: detail.uid, item: data2[detail.uid], value: detail.value }]
  }


  handleUpdateEvents(eventName, data) {
    // const updateEvents = ["add_children", "add_sibling", "delete", "remove"];

    switch (eventName) {

      case "add_children":
      case "add_sibling":
        const changed = this.getChangedFromEvent(data);
        if (changed && changed.length) {
          const items = changed.map(item => item.item)
          this.updateFlexSearchIndex(items)
        };
        break;
      case "delete":
      case "remove":
        if (data.uid) {
          this.flexSearch?.deleteNode(data.uid)
        };
        break;
    }
  }

  dispatch(eventName, data = {}) {
    this.emit(eventName, data)
    this.handleUpdateEvents(eventName, data)
  }

  getAllNodes() {
    let state = this.getState()
    return _.get(state, ['tree', 'nodes'])
  }

  getState() {
    let state = get(this)
    return state
  }

  getAllPages(nodes) {
    nodes = nodes || this.getAllNodes()

    let nodesArr = _.valuesIn(nodes)

    if (_.isArray(nodesArr) && nodesArr.length) {
      return nodesArr
        .filter(
          ({ type = '' } = {}) =>
            type === types.dailyNotes || type === types.page
        )
        .sort((a, b) => b['edit-time'] - a['edit-time'])
    }

    return []
  }

  get isReady() {
    return get(this.dataReady)
  }

  isReadyAsync(timeout) {
    return new Promise((res, rej) => {
      const unsubscribe = this.dataReady.subscribe(val => {
        if (val) res()
      })

      if (timeout)
        setTimeout(() => {
          rej()
        }, timeout)
    })
  }



  async getReferencesUsingFlexSearch(uid, spacePath) {

    const nodes = this.nodes
    const url = `${window.location.origin}${spacePath}/${uid}`;
    const references = await this.flexSearch?.searchNodes(url);
    const _temp = references.map(uid => nodes[uid]).filter(node => node);

    return _temp;
  }



  async handleUploadMedia({
    url,
    id,
    callback,
    onError,
    onCompleted,
  }) {
    // try {

    //   if (!id) throw new Error("Id is required");

    //   const publicKey = this.options?.keys?.publicKey;
    //   const isEncrypted = !!publicKey;

    //   if (isEncrypted) {
    //     callback?.("started_encryption");

    //     const encText = await _encryption.encryptFile(url, publicKey, {
    //       context: null
    //     })

    //     if (!encText) throw new Error("Encryption failed");

    //     callback?.("finished_encryption", { value: encText });
    //     callback?.("started_upload");

    //     const remoteUpload = undefined

    //     let downloadUrl = await remoteUpload.uploadFile(new Blob([encText]), {
    //       folder: `videos/${store.wid}`,
    //       fileName: uid(),
    //     })

    //     callback?.("finished_upload", { value: downloadUrl });
    //     onCompleted?.({ value: downloadUrl });

    //     if (downloadUrl) {
    //       downloadUrl = downloadUrl.replace(API_URL, "https://upload.notebrowser.com");
    //       // const _downloadUrl = new URL(downloadUrl);
    //       // _downloadUrl.hash = _downloadUrl.pathname;
    //       // _downloadUrl.pathname = "";

    //       const contentType = await this.getBlobType(url);

    //       const isImage = contentType.includes("image");

    //       if (isImage) {
    //         downloadUrl = downloadUrl.replace("videos", "images")
    //       }


    //       this.updateString(id, string => string.replace(url, downloadUrl))
    //     }
    //   }
    // } catch (err) {
    //   console.error(err)
    //   onError?.(err)
    // }
  }


  async getBlobType(blobUrl) {
    try {
      const response = await fetch(blobUrl);
      const type = response.headers.get("Content-Type");
      return type;
    } catch (err) {
      console.error(err)
      return "none"
    }
  }


  getNodesWithDifferentStrings(nodesArr1, nodeArr2) {
    const nodeObj2 = _.keyBy(nodeArr2, 'uid')

    return nodesArr1.filter(node => {
      const node2 = nodeObj2[node.uid]

      if (!node2) return true

      if (node2 && node.string !== node2.string) {
        return true
      }

      return false
    })
  }
  getLastDayChanges() {
    const beforeInitNotes = this.beforeInitNotes[this.beforeInitNotes.length - 1];

    const nodesArrBefore = Object.values(beforeInitNotes.nodes)
    const nodesArrCurrent = Object.values(this.nodes)

    const currentTimestamp = dayjs().unix();
    const yesterdayTimestamp = dayjs().subtract(1, 'day').unix();



    const nodesArrBeforeFiltered = nodesArrBefore.filter(node => {
      return node["edit-time"] > yesterdayTimestamp
    })

    const nodesArrCurrentFiltered = nodesArrCurrent.filter(node => {
      return node["edit-time"] > yesterdayTimestamp
    })

    const changedNodes = this.getNodesWithDifferentStrings(nodesArrCurrentFiltered, nodesArrBeforeFiltered)

    this.changedNodes.set(changedNodes)
  }

  init(data) {
    const pages = this.getAllPages(data.nodes)
    const state = this.get()

    this.beforeInitNotes.push({
      nodes: state.tree.nodes,
      timestamp: Date.now()
    })


    this.set({
      ...state,
      tree: {
        nodes: data.nodes,
        pages: pages,
      },
    })

    this.pages.next(pages)
    const notes = this.getDailyNotes()

    // this.enableFlexSearch()

    setTimeout(() => {
      if (notes && notes.length) {
        const notesWithData = notes
          .map(node => this.getNodesArr(node.uid))
          .flat()
          .map(node => node.string)

        this.wordIndex.generateIndex(notesWithData)
      }
      this.getLastDayChanges()
    }, 2000)
  }

  moveShortcut(from, to) {
    if (_.isNumber(from) && isNumber(to)) {
      this.update(state => {
        let shortcuts = [...state.tree.shortcuts]
        let updatedShortcuts = arrayMove(shortcuts, from, to)
        state.tree.shortcuts = updatedShortcuts
        return state
      })
    }
  }

  getValue(uid) {
    try {
      const state = get(this)
      const node = state.tree.nodes[uid]
      return node && (node.title || node.string)
    } catch (err) {
      console.error(err)
      return null
    }
  }

  toggleShortcut(uid) {
    this.update(state => {
      let shortcuts = state.tree.shortcuts || []
      if (this.hasShortcut(uid, shortcuts)) {
        state.tree.shortcuts = shortcuts.filter(item => item !== uid)
      } else {
        state.tree.shortcuts = [uid, ...shortcuts]
      }
      return state
    })
  }

  hasShortcut(uid, shortcuts) {
    return shortcuts && shortcuts.find(item => item === uid)
  }

  getDaysBuffer(
    targetDay,
    { buffer = 2, createToday = false, prevBuffer = [] } = {}
  ) {
    try {
      targetDay = targetDay.trim()
      const target = dayjs(targetDay, 'LL')

      if (target.isValid()) {
        const targetPage = createToday
          ? this.getInternalPage(targetDay, {
            type: 'dailyNotes',
          })
          : this.findPage(targetDay)

        const left = this.getDaysBefore(targetDay, buffer, prevBuffer)
        const right = this.getDaysAfter(targetDay, buffer, prevBuffer)

        const center = targetPage && targetPage.uid ? [targetPage] : []

        return [...left, ...center, ...right]
      }
    } catch (err) {
      console.error(err)
      return []
    }
  }

  getDaysBefore(targetDay, count = 2) {
    return this.getDaysInDirection(targetDay, count, -1)
  }

  getDaysAfter(targetDay, count = 2) {
    return this.getDaysInDirection(targetDay, count, 1)
  }

  getDaysInDirection(targetDay, count, direction = 1) {
    let days = []

    const target = dayjs(targetDay, 'LL')
    if (target.isValid()) {
      let i = 0
      while (i < count) {
        target.add(direction, 'd')

        const _day = target.format('LL')
        const page = this.findPage(_day)

        if (page && page.uid) days.push(page)
        i++
      }
    }
    return days
  }

  getDaysFromDateRange(start, end) {
    const startDate = dayjs(start)
    const endDate = dayjs(end)

    try {
      if (startDate.isBefore(endDate, 'day')) {
        let i = 0
        let pages = []
        while (!startDate.isSame(endDate, 'day') && i < 200) {
          const pageName = startDate.add(1, 'd').format('LL')
          pages.push(
            this.findPage(pageName) ||
            this.addPage(pageName, {
              type: 'dailyNotes',
            })
          )
          i++
        }

        pages = (pages || []).sort((a, b) => {
          let dateA = dayjs(a['title'], 'LL').unix()
          let dateB = dayjs(b['title'], 'LL').unix()
          return dateA - dateB
        })
        return pages
      } else {
        throw new Error('error with date')
      }
    } catch (err) {
      console.error(err)
      return []
    }
  }

  getToday() {
    const today = dayjs().format('LL')
    return (
      this.findPage(today) ||
      this.addPage(today, {
        type: 'dailyNotes',
      })
    )
  }

  getNextDay(date) {
    const currentDay = dayjs(date, 'LL')

    if (currentDay.isValid()) {
      const targetDay = currentDay
        .clone()
        .add(1, 'd')
        .format('LL')
      return (
        this.findPage(targetDay) ||
        this.addPage(targetDay, {
          type: 'dailyNotes',
        })
      )
    }

    return null
  }

  getPrevDay(date) {
    const currentDay = dayjs(date, 'LL')

    if (currentDay.isValid()) {
      const targetDay = currentDay
        .clone()
        .add(-1, 'd')
        .format('LL')
      return (
        this.findPage(targetDay) ||
        this.addPage(targetDay, {
          type: 'dailyNotes',
        })
      )
    }

    return null
  }

  getDailyDays(start, end, dontCreateNew) {
    try {
      let todayPage = dayjs()
      let pages = []
      for (let i = start; i < end; i++) {
        const pageName = todayPage
          .clone()
          .add(-i, 'd')
          .format('LL')
        pages.push(
          this.findPage(pageName) ||
          (!dontCreateNew &&
            this.addPage(pageName, {
              type: 'dailyNotes',
            }))
        )
      }
      pages = pages.filter(page => page)

      pages = (pages || []).sort((a, b) => {
        let dateA = dayjs(a['title'], 'LL').unix()
        let dateB = dayjs(b['title'], 'LL').unix()
        return dateB - dateA
      })
      return pages
    } catch (err) {
      console.error(err)
      return []
    }
  }

  getCalendarDays(start, end, dontCreateNew) {
    try {
      let todayPage = dayjs()
      let pages = []

      for (let i = start; i < end; i++) {
        const pageName = todayPage
          .clone()
          .add(i, 'd')
          .format('LL')
        pages.push(
          this.findPage(pageName) ||
          (!dontCreateNew &&
            this.addPage(pageName, {
              type: 'dailyNotes',
            }))
        )
      }
      pages = pages.filter(page => page)

      pages = (pages || []).sort((a, b) => {
        let dateA = dayjs(a['title'], 'LL').unix()
        let dateB = dayjs(b['title'], 'LL').unix()
        return dateA - dateB
      })
      return pages
    } catch (err) {
      console.error(err)
      return []
    }
  }

  addPage(title, { type = 'page' } = {}) {
    let state = get(this)
    let pageCount = state.tree.pages.length
    let temp = new node({
      title: title || `Untitled${pageCount}`,
      type: types[type],
      createEmail: this.wid,
      editEmail: this.wid,
    })
    let child = new node({
      type: types.node,
      parentPage: temp.uid,
      parent: temp.uid,
      createEmail: this.wid,
      editEmail: this.wid,
    })
    temp.children = [child.uid]
    this.update(state => {
      let pageIndex = state.tree.pages.findIndex(e => e.title === title)
      if (pageIndex === -1) {
        state.tree.pages.push(temp)
        state.tree.nodes[temp.uid] = temp
        state.tree.nodes[child.uid] = child

        // updating pages observable
        this.pages.next(this.getAllPages(state.tree.nodes))
        setTimeout(
          () => this.emit('new-page', { uid: temp.uid, targets: [child.uid] }),
          1000
        )
      } else {
        temp = state.tree.pages[pageIndex]
      }
      return state
    })
    return temp
  }

  findPage(title = '') {
    let state = get(this)
    return state.tree.pages.find(
      o => o.title.toLowerCase() === title.toLowerCase()
    )
  }

  openPage(uid) {
    push(`#/page/${uid}`)
  }
}

let store = new Store({ placeholderData: true })

const activateCursor = writable(null)

export default store

export { Store, activateCursor }
